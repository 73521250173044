import React, { useState, useEffect, createRef } from 'react'
import { Grid } from '@material-ui/core'
import { StatementDetails } from '../step_three/step_three'
import { DocumentDropzone } from '../step_two/step_two'
import './_step_five.scss'
export default function StepFive(props) {
  const { token, stepFiveData, projectId, signedDeclarationFile, projectServiceId } = props
  const [firstDropZoneFiles, setFirstDropZoneFiles] = useState([])
  const [secondDropZoneFiles, setSecondDropZoneFiles] = useState([])
  const [dropZoneRef, setDropZoneRef] = useState([])
  const [firstFormData, setFirstFormData] = useState(new FormData())
  const [secondFormData, setSecondFormData] = useState(new FormData()) 

  useEffect(() => {
    const tempArray = []
    Array(2)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setDropZoneRef(tempArray)
  }, [])
  useEffect(() => {
    if (JSON.stringify(stepFiveData) !== '{}') {
      let tempFormData = new FormData()
      let serviceDocumentCategoryId =
        stepFiveData.service_document_categories.length > 0 
            ? stepFiveData.service_document_categories[0].id
          : 0
      let serviceDocumentId =
        stepFiveData.service_document_categories.length > 0
          ? stepFiveData.service_document_categories[0].service_documents
              .length > 0
            ? stepFiveData.service_document_categories[0].service_documents[0].id
            : 0
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', serviceDocumentId)
      setFirstFormData(tempFormData)
      if (stepFiveData.service_document_categories.length > 0) {
        setFirstDropZoneFiles(
          stepFiveData.service_document_categories[0].service_documents,
        )
      }
      tempFormData = new FormData()
      serviceDocumentCategoryId =
        stepFiveData.service_document_categories.length > 0 
            ? stepFiveData.service_document_categories[1].id
          : 0
      serviceDocumentId =
        stepFiveData.service_document_categories.length > 0
          ? stepFiveData.service_document_categories[1].service_documents
              .length > 0
            ? stepFiveData.service_document_categories[1].service_documents[0].id
            : 0
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', serviceDocumentId)
      setSecondFormData(tempFormData)
      if (stepFiveData.service_document_categories.length > 0) {
        setSecondDropZoneFiles(
          stepFiveData.service_document_categories[1].service_documents,
        )
      }
    }
  }, [stepFiveData])
  return (
    <Grid container className="fade-in-translate animatable-100">
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Voorlopige eindaangifte
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <div className="flex-row width-auto">
        <DocumentDropzone
          ref={dropZoneRef[0]}
          fileNames={firstDropZoneFiles}
          setFileNames={setFirstDropZoneFiles}
          path={process.env.REACT_APP_API_URL+"/service_document/upload_document"}
          formData={firstFormData}
          token={token}
          projectId={projectId}
          serviceId={2}
        />
      </div>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>

      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Definitieve eindaangifte{' '}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <div className="flex-row width-auto">
        <DocumentDropzone
          ref={dropZoneRef[1]}
          fileNames={secondDropZoneFiles}
          setFileNames={setSecondDropZoneFiles}
          path={process.env.REACT_APP_API_URL+"/service_document/upload_document"}
          formData={secondFormData}
          token={token}
          projectId={projectId}
          serviceId={2}
        />
      </div>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            {/* <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            /> */}
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Ondertekende aangifte
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11}>
        <StatementDetails
          backendFileName={signedDeclarationFile}
        />
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
    </Grid>
  )
}
