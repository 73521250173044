import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import './_step_four.scss'
import axios from "axios";
import { SafetyPDF } from "../safety_pdf/safety_pdf";
import Popup from '../../../shared/popup/custom_popup'

export default function StepFour(props) {
  const { token, safetyFormId, stepOneData, stepTwoData, stepThreeData, stepFourData, safetyPdfFile, setSafetyPdfFile, buildingData, projectServiceId, generatedPdfFile, safetyPdfFileBackendName, setSafetyPdfBackendFileName, forceUpdateState, setForceUpdateState } = props
  const [uploadedPdf,setUploadedPdf]=useState('')
  const [isUploadSuccess, setUploadSuccessState]=useState(false)
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})
  const [isFirstTime, setFirstTimeState] = useState(true)
  const [safetyFileUploadProgress, setSafetyUploadProgress] = useState(0)
  const [generatedFileUploadProgress, setGeneratedFileUploadProgress] = useState(0)

  useEffect(()=>{
    if(uploadedPdf!==null && uploadedPdf!==''){
      setSafetyPdfFile(uploadedPdf)
    }
  },[uploadedPdf])

  useEffect(()=>{
    if(!isFirstTime){
      if(isUploadSuccess){
        let responseMessage = {
          isSuccess: true,
          heading: 'bestand succesvol geüpload',
          message: '',
          popupState: setShowPopupState
        }
        setShowPopupState(true)
        setPopupMessageState(responseMessage)
      }
      else{
        let responseMessage = {
          isSuccess: false,
          heading: 'Bestand kon niet worden opgeslagen',
          message: 'Er is iets misgegaan',
          popupState: setShowPopupState
        }
        setShowPopupState(true)
        setPopupMessageState(responseMessage)
      }
    }
    else{
      setFirstTimeState(false)
    }
  },[isUploadSuccess])

const uploadPdf = (event) => {
  if(safetyFormId && safetyFormId > 0){
    const file = event.target.files[0]
    let newFormData = new FormData();
    if(file.name.includes('.pdf')){
      newFormData.append('safetyId', safetyFormId);
      newFormData.append('docsInput', event.target.files[0])
      axios.post(process.env.REACT_APP_API_URL+'/safety_service_form/upload_safty_pdf', newFormData, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        onUploadProgress: progress => {
          const { total, loaded } = progress;
          const totalSize = total/1000000;
          const totalLoaded = loaded/1000000;
          const percentage = (totalLoaded/totalSize) * 100;
          setSafetyUploadProgress(percentage.toFixed(2));
        },
      }).then(response => {
        setSafetyUploadProgress(0)
        console.log(response.data)
        setSafetyPdfFile(response.data.safety_pdf)
        setSafetyPdfBackendFileName(response.data.safety_pdf)
        setUploadedPdf(response.data.safety_pdf)
        setForceUpdateState(!forceUpdateState)
      }).catch(error => {
        let responseMessage = {
          isSuccess: false,
          heading: 'Bestand kon niet worden opgeslagen',
          message: 'Er is iets misgegaan',
          popupState: setShowPopupState
        }
        setShowPopupState(true)
        setPopupMessageState(responseMessage)
      })
    }
    else{
      let responseMessage = {
        isSuccess: false,
        heading: 'Bestand kon niet worden opgeslagen',
        message: 'alleen pdf-bestanden toegestaan',
        popupState: setShowPopupState
      }
      setShowPopupState(true)
      setPopupMessageState(responseMessage)
    }
  }
  else {
    let responseMessage = {
      isSuccess: false,
      heading: "Het document kan nog niet worden opgeslagen. Gelieven eerst enkele 'Startgegevens' in te vullen.",
      message: '',
      popupState: setShowPopupState
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)
  }
}

const handleSwitchClick = () => {
  axios.post(process.env.REACT_APP_API_URL+'/safety_service_form/generate_safty_pdf', {
    projectServiceId: projectServiceId,
    safetyId: safetyFormId
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    onUploadProgress: progress => {
      const { total, loaded } = progress;
      const totalSize = total/1000000;
      const totalLoaded = loaded/1000000;
      const percentage = (totalLoaded/totalSize) * 100;
      setGeneratedFileUploadProgress(percentage.toFixed(2));
    },
  }).then(response => {
    setGeneratedFileUploadProgress(0)
    if(response.data.success){
      setForceUpdateState(!forceUpdateState)
    }
    else {
      let responseMessage = {
        isSuccess: false,
        heading: 'Bestand kon niet worden opgeslagen',
        message: 'Er is iets misgegaan',
        popupState: setShowPopupState
      }
      setShowPopupState(true)
      setPopupMessageState(responseMessage)
    }
  }).catch(error => {
    let responseMessage = {
      isSuccess: false,
      heading: 'Er zijn geen bestanden beschikbaar voor deze beveiligingsservice.',
      message: '',
      popupState: setShowPopupState
    }
    setShowPopupState(true)
    setPopupMessageState(responseMessage)
  })
}

  return (
    <Grid container className="animatable-400 fade-in-translate ">
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            />
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Postinterventiedossier
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid container xs={1} sm={1} md={1} />
        <Grid container xs={11} sm={11} md={11} alignItems = 'center'>
          <Grid justify="center" alignItems="center">
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <label
              for = "step-four-file-upload"
              className={
                'step-three-delete-image center-content cursor-pointer margin-right-10'
              }
              //   onClick={() => handleChange('info')}
            >
              <img
                alt="upload"
                src={require('../../../../assets/svg/icon/upload.svg')}
                className="safety-step-four-add-image"
              />
            </label>
            <input type = "file" accept = '.pdf' onChange = {uploadPdf} id = "step-four-file-upload" className = "safety-step-four-hide-input" />
          </Grid>
          <Grid>
            <StatementDetails backendFileName={safetyPdfFileBackendName} originalName = {safetyPdfFile} uploadProgress = {safetyFileUploadProgress} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className = "margin-top-15" />
      </Grid>
      {safetyPdfFile && (
        <Grid container>
          <Grid container xs={1} sm={1} md={1} />
          <Grid container xs={11} sm={11} md={11} alignItems = 'center'>
            <Grid justify="center" alignItems="center">
              <Grid container>
                <div className="margin-top-10" />
              </Grid>
                <label 
                  className={
                    'step-three-delete-image center-content cursor-pointer margin-right-10'
                  }
                  //   onClick={() => handleChange('info')}
                >
                  <img
                    alt="upload"
                    src={require('../../../../assets/svg/button_switch.svg')}
                    className="safety-step-four-add-image"
                    onClick = {handleSwitchClick}
                  />
                </label>
            </Grid>
            <Grid>
              <StatementDetails isGeneratedPdf = {true} backendFileName={generatedPdfFile} originalName = {generatedPdfFile && generatedPdfFile} uploadProgress = {generatedFileUploadProgress} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {
        showPopupState === true ?
            <Popup data={popupMessageState}/>
        : null
      }
    </Grid>
  )
}

export const StatementDetails =   (props,ref) => {
  const { backendFileName, originalName, uploadProgress, isGeneratedPdf } = props
  const [fileName,setFileName]=useState('')
   
  useEffect(()=>{
    if(backendFileName){
      setFileName(backendFileName)
    }
  },[backendFileName])

  return (
    uploadProgress !== 0 ? (
      <div className = 'flex-row vertical-center'>
        <p className = "main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">Upload</p>
        <div className = 'slider'>
          <div className = 'slider-thumb' style = {{width: uploadProgress*2}} />
        </div>
        <p className = "main-font-semi-bold font-size-12 font-color-sky-blue">{uploadProgress}%</p>
      </div>
      )
    :
    <Grid container >
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
    { backendFileName!==undefined && backendFileName!==null && backendFileName!=='' ?  
        <div className='flex-row'>
          <Grid className="flex" justify="space-evenly" alignItems="center">
            <img
              alt=""
              className="step-three-file-icon"
              src={
                fileName.split('.').includes('pdf')
                  ? require('../../../../assets/svg/doc/pdf.svg')
                  : fileName.split('.').includes('doc')
                  ? require('../../../../assets/svg/doc/doc.svg')
                  : fileName.split('.').includes('xls')
                  ? require('../../../../assets/svg/doc/xls.svg')
                  : fileName.split('.').includes('jpg') 
                  ? require('../../../../assets/svg/doc/jpg.svg')
                  : require('../../../../assets/svg/doc/other.svg')
              }
            />
            <p className="step-three-file-name margin-left-10 vietnam-regular">
              {originalName && originalName}
            </p>
          </Grid>
          <Grid>
            <div className="margin-left-20" />
          </Grid>
          <Grid>
            <a
            href={fileName && isGeneratedPdf ? process.env.REACT_APP_API_URL+`/uploads/${fileName}` : process.env.REACT_APP_API_URL+`/safety_pdf/${fileName}`}
            rel="noopener noreferrer"
            target="_blank"
              className={'step-three-delete-image cursor-pointer'}
              //   onClick={() => handleChange('info')}
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/view.svg')}
                className=""
              />
            </a>
          </Grid>
          <Grid>
            <div className="margin-left-20" />
          </Grid>
          <Grid>
            <a
            download
            className={'step-three-delete-image cursor-pointer'}
            href={fileName && (isGeneratedPdf ? process.env.REACT_APP_API_URL+`/documents/download_file/${fileName}` : process.env.REACT_APP_API_URL+`/documents/safety_pdf/download_file/${fileName}`)}
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/download.svg')}
                className=""
              />
            </a>
          </Grid>
          </div>   
          :
          <Grid container xs={11} sm={11}>
            <p className='font-color-black font-size-16 main-font-bold'>geen bestanden geüpload</p>
          </Grid>  
    }
    </Grid>
  )
}