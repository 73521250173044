import React,{useState,useRef,useEffect} from 'react'
import { Grid } from '@material-ui/core'
import {DocumentDropzone} from '../../../epb_forms/epb_form_admin/step_two/step_two'
import {StatementDetails} from '../../../epb_forms/epb_form_admin/step_three/step_three'
import './_step_three.scss'
export default function StepThree(props) {
  const { token, stepThreeData ,setPostInterventionFile, postInterventionFile, projectServiceId} = props
  const [werfverslagenFile1, setWerfverslagenFile1] = useState([])
  const [werfverslagenFile2, setWerfverslagenFile2] = useState([])
  const [werfverslagenFile3, setWerfverslagenFile3] = useState([])
  const [formData1, setFormData1] = useState(new FormData())
  const [formData2, setFormData2] = useState(new FormData())
  const [formData3, setFormData3] = useState(new FormData())

  useEffect(() => {
    if ( stepThreeData!==undefined && JSON.stringify(stepThreeData) !== '{}') {
      let tempFormData = new FormData()
      let serviceDocumentCategoryId =
        stepThreeData.service_document_categories.length > 0
          ? stepThreeData.service_document_categories[0].id
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', 0)
      setFormData1(tempFormData)
      tempFormData = new FormData()
       serviceDocumentCategoryId =
        stepThreeData.service_document_categories.length > 0
          ? stepThreeData.service_document_categories[0].id
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', 0)
      setFormData2(tempFormData)

      tempFormData = new FormData()
       serviceDocumentCategoryId =
        stepThreeData.service_document_categories.length > 0
          ? stepThreeData.service_document_categories[0].id
          : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', 0)
      setFormData3(tempFormData)
      if(stepThreeData.service_document_categories.length>0){
        if(stepThreeData.service_document_categories[0].service_documents.length>0){
          setWerfverslagenFile1([{id:stepThreeData.service_document_categories[0].service_documents[0].id,name:stepThreeData.service_document_categories[0].service_documents[0].documentTitle, viewName:stepThreeData.service_document_categories[0].service_documents[0].fileName}])
        }
        if(stepThreeData.service_document_categories[0].service_documents.length>1){
          setWerfverslagenFile2([{id:stepThreeData.service_document_categories[0].service_documents[1].id,viewName:stepThreeData.service_document_categories[0].service_documents[1].fileName, name:stepThreeData.service_document_categories[0].service_documents[1].documentTitle}])
        }
        if(stepThreeData.service_document_categories[0].service_documents.length>2){
          setWerfverslagenFile3([{id:stepThreeData.service_document_categories[0].service_documents[2].id,viewName:stepThreeData.service_document_categories[0].service_documents[2].fileName, name:stepThreeData.service_document_categories[0].service_documents[2].documentTitle}])
        }
        if(stepThreeData.service_document_categories[1].service_documents.length>0){
          let tempUploadFiles = []
            stepThreeData.service_document_categories[1].service_documents.forEach(item => {
              tempUploadFiles.push({id: item.id, name: item.documentTitle, viewName: item.fileName})
            })
          setPostInterventionFile(tempUploadFiles)
        }
      }
    }
  }, [stepThreeData])

  return (
    <Grid container className="animatable-400 fade-in-translate ">
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center"></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Werfverslagen
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <div className="flex-column width-auto">
        <DocumentDropzone
          fileNames={werfverslagenFile1}
          setFileNames={setWerfverslagenFile1}
          path={process.env.REACT_APP_API_URL+"/service_document/upload_document"}
          formData={formData1}
          token={token}
          buttonLabel='Upload werfverslagen1'
          serviceId={4}
        />
        <div className='margin-top-10'/>
        <DocumentDropzone
          fileNames={werfverslagenFile2}
          setFileNames={setWerfverslagenFile2}
          path={process.env.REACT_APP_API_URL+"/service_document/upload_document"}
          formData={formData2}
          token={token}
          buttonLabel='Upload werfverslagen2'
          serviceId={4}
        />
        <div className='margin-top-10'/>
        <DocumentDropzone
          fileNames={werfverslagenFile3}
          setFileNames={setWerfverslagenFile3}
          path={process.env.REACT_APP_API_URL+"/service_document/upload_document"}
          formData={formData3}
          token={token}
          buttonLabel='Upload werfverslagen3'
          serviceId={4}
        />
      </div>
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center"></Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Postinterventiedossier
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {
        postInterventionFile !== undefined && postInterventionFile !== null && postInterventionFile.length > 0 && (
          postInterventionFile.map(file => (
            <Grid container>
              <Grid xs={1} sm={1} md={1} />
              <Grid container xs={11} sm={11} md={11}>
                <StatementDetails backendFileName={file}/>
                <Grid container>
                  <div className="margin-top-10" />
                </Grid>
              </Grid>
            </Grid>
          ))
        )
      }
      <Grid container>
        <div className="margin-top-20" />
      </Grid>
    </Grid>
  )
}