import React,{useState,useRef,useEffect} from 'react'
import { Grid } from '@material-ui/core'
import {DocumentDropzone} from '../../../epb_forms/epb_form_admin/step_two/step_two'
import './_step_two.scss'
export default function StepTwo(props) {
  const { token, stepTwoData, projectId, projectServiceId } = props
  const [fileNames, setFileNames] = useState([])
  const [formData, setFormData] = useState(new FormData())

  useEffect(() => {
    const tempFormData = new FormData()
    if (stepTwoData!==undefined && JSON.stringify(stepTwoData) !== '{}') {
      let serviceDocumentCategoryId =
        stepTwoData.service_document_categories.length > 0
          ? stepTwoData.service_document_categories[0].id
          : 0
      let serviceDocumentId = 
      stepTwoData.service_document_categories.length > 0
        ? stepTwoData.service_document_categories[0].service_documents.length>0
          ? stepTwoData.service_document_categories[0].service_documents[0].id
          : 0
        : 0
      tempFormData.append(
        'serviceDocumentCategoryId',
        serviceDocumentCategoryId,
      )
      tempFormData.append('projectServiceId', projectServiceId)
      tempFormData.append('serviceDocumentId', serviceDocumentId)
      setFormData(tempFormData)
      setFileNames(stepTwoData.service_document_categories[0].service_documents)
    }
    else {
      tempFormData.append('projectServiceId', projectServiceId)
      setFormData(tempFormData)
    }
  }, [stepTwoData])

  return (
    <Grid container className="fade-in-translate animatable-400">
      <Grid
        container
        className="epb-service-Category-heading"
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Veiligheids-en gezondheidsplan
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid> 
      <div className="flex-row width-auto">
        <DocumentDropzone
          fileNames={fileNames}
          setFileNames={setFileNames}
          path={process.env.REACT_APP_API_URL+"/service_document/upload_document"}
          projectId={projectId}
          formData={formData}
          token={token}
          serviceId={4}
        />
      </div>
      </Grid>
  )
}
