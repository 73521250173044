import React, { useState, forwardRef, useCallback, useEffect } from "react";
import { Grid, Hidden, Container } from "@material-ui/core";
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import LabelSelect from '../../residence/components/actions_information/document_details/label_select/label_select'
import DeleteConfirm from '../../shared/delete_confirm/delete_confirm';

import "./_documents.scss";
import Header from '../header/header';
import RowDetails from "../../residence/components/actions_information/document_details/row_details/row_details";

const DocumentsDropzone = forwardRef((props, ref) => {
    const { sendUploadedFile } = props;

    const onDrop = useCallback((files) => {
        sendUploadedFile(files)
    }, [])

    const accept = 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, image/jpeg, image/png, video/webm, video/x-misvideo, video/mpeg, video/mp4, .dwg'
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept,
        noClick: true,
    })

    return (
        <div className="height-auto width-auto relative"
            {...getRootProps({ style: { outline: 'none' } })}
        >
            <input className="height-auto width-auto" {...getInputProps()} />
            {props.children}
        </div>
    )
})

export default function Documents(props) {
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [serviceId, setServiceId] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [selectedBuilding, setSelectedBuilding] = useState(0);
    const [selectedService, setSelectedService] = useState(0);
    const [searchType, setSearchType] = useState(false); // since there are only two search options we use a bool since it only has two states
    const [showMenu, setShowMenu] = useState(0);
    const [originalData, setOriginalData] = useState([]) // at component launch will contain same data as the state data and prop data, but overtime will not change
    const [update, setUpdate] = useState(false);
    const [ serviceList, setServiceList ] = useState([])
    const [ originalServiceList, setOriginalServiceList ] = useState([])
    const [ buildingList, setBuildingList ] = useState([])
    const [ documentsList, setDocumentsList ] = useState([])
    const [ fileUploadProgress, setFileUploadProgress ] = useState(0)

    const { file } = useParams();
    const { token } = props;


    const [fileNames, setFileNames] = useState([])
    const [selectedFiles, setFiles] = useState([])
    const [modalState, setModalState] = useState(false)
    const [selectedValue, setSelectedValue] = useState(0)
    const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))

    const [showDeletePopupState, setShowDeletePopupState] = useState(false)
    const [deleteIdState, setDeleteIdState] = useState(0)

    const labelData = [
        { id: 1, label: 'Intentieverklaringen', info: 'Alle ondertekende documenten van bouwheer en aannemers' },
        { id: 2, label: 'Bouwvergunning', info: ''  },
        { id: 3, label: 'Plannen', info: 'As-built plannen, stabiliteitsplannen, leidingsplannen, …'  },
        { id: 4, label: 'Verslagen', info: 'Werfverslagen of andere meldingen.'  },
        { id: 5, label: 'Nutsleidingen – huisaansluitingen – verborgen elementen', info: 'Werfverslagen of andere meldingen.'  },
        { id: 6, label: 'Plaatsbeschrijvingen', info: ''  },
        { id: 7, label: 'Offertes/facturen', info: ''  },
        { id: 8, label: 'Foto’s', info: 'Bouwmaterialen'  },
        { id: 9, label: 'Gebruikte materialen', info: 'Bouwmaterialen, technieken + technische fiches.'  },
        { id: 10, label: 'Handleidingen', info: ''  },
        { id: 11, label: 'Keuringen', info: 'Keuringsattesten of metingen.'  },
        { id: 12, label: 'Oplevering', info: 'PV voorlopige oplevering, PV definitieve oplevering'  },
        { id: 13, label: 'Overige', info: ''  },
    
      ]
    

    useEffect(() => {
        setSearch("")
        setSelectedBuilding(0)
        setSelectedService(0)
        if(searchType) {
            getAllServices();
        }
        else {
            setData(JSON.parse(JSON.stringify(originalData)));
        }
    }, [searchType])

    useEffect(() => {
        if(data.length > 0) {
            setSearchType(false)
            let selectedBuildingIndex = null;
            let selectedServiceIndex = null;
            if(file) {
                data.forEach((building, buildingIndex) => {
                    building.services.forEach((service, serviceIndex) => {
                        service.servicesDocuments.forEach((document, documentIndex) => {
                            if(document.id == file) {
                                selectedBuildingIndex = buildingIndex + 1;
                                selectedServiceIndex = serviceIndex + 1;
                            }
                        })
                    })
                })
            }

            if(selectedBuildingIndex && selectedServiceIndex) {
                setSelectedBuilding(selectedBuildingIndex);
                setSelectedService(selectedServiceIndex);
                readDocumentApi(data[selectedBuildingIndex - 1].services[selectedServiceIndex - 1].id)
            }
        }
    }, [file])

    useEffect(() => { 
        axios
        .post(
          process.env.REACT_APP_API_URL+'/dashboard/adminData',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
            rebuildData(response.data)
        })
        .catch((error) => {
        })
    }, [update])

    const callApi = () => {
        const formData = new FormData()
        formData.append('serviceId', serviceId)
        formData.append('projectId', projectId)
        formData.append('role', "admin")
        formData.append('person', `${userDetails.firstName} ${userDetails.lastName}`)
        formData.append('label',labelData[selectedValue-1].label)
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('docsInput', selectedFiles[i])
        }
        axios
            .post(
                process.env.REACT_APP_API_URL+`/documents/uploadDocument`,
                formData,
                {
                    headers: {
                        authorization: `bearer ${token}`,
                    },
                    onUploadProgress: progress => {
                        const { total, loaded } = progress;
                        const totalSize = total/1000000;
                        const totalLoaded = loaded/1000000;
                        const percentage = (totalLoaded/totalSize) * 100;
                        setFileUploadProgress(percentage.toFixed(2));
                    },
                },
            )
            .then((response) => {
                setFileUploadProgress(0)
                if (response.data.success === true) {
                    let uploadedDocuments = response.data.uploadedDocuments
                    data[selectedBuilding - 1].services[selectedService - 1].servicesDocuments = uploadedDocuments
                    setData(data)              
                    setUpdate(!update)
                }
            })
            .catch((error) => { })
    }

    const handleSearch = (event) => {
        let dataPool = JSON.parse(JSON.stringify(originalData));
        const originalServices = JSON.parse( JSON.stringify(originalServiceList) );
        let newData = [];
        if(event.target.value !== "") {
            if(searchType) {
                originalServices.forEach(item => {
                    if(item.title.toLowerCase().includes(event.target.value.toLowerCase())) {
                        newData.push(item)
                    }
                })
                setServiceList(newData)
            }
            else {
                dataPool.forEach(item => {
                    if(item.title.toLowerCase().includes(event.target.value.toLowerCase()) || getFileNumber(item.id).includes(event.target.value.toLowerCase())) {
                        newData.push(item);
                    }
                })
                setData(newData);
            }
        }
        else {
            if(searchType) {
                setServiceList(originalServices)
            }
            else {
                setData(dataPool)
            }
        }
        setSearch(event.target.value);
        setSelectedBuilding(0)
        setSelectedService(0);
        setBuildingList([])
    }

    const handleFilesLabel = (value) => {
        const date = new Date()
        selectedFiles.forEach((item) => {
            item.label = labelData[value - 1].label
            item.date = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
            item.person = `${userDetails.firstName} ${userDetails.lastName}`
            fileNames.push(item)
        })
        setFileNames(fileNames)
        if (serviceId != 0 && projectId != 0){
            callApi()
        }
    }

    function readDocumentApi(id) {
        const token = localStorage.getItem('authToken');
        axios.post(process.env.REACT_APP_API_URL+"/documents/document_read", {
            projectServiceId:id,
            role:"admin"
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {

        }).catch(error => {
        })
    }

    const getUploadedFile = (files) => {
        setModalState(true)
        setFiles(files)
        console.log(files)
    }


    const deleteFile = (data) => {
        setDeleteIdState(data.documentId)
         setShowDeletePopupState(true)
    }

    const handleDeleteClick = () => {
        axios
          .post(
            process.env.REACT_APP_API_URL+`/documents/delete`,
            { documentId: deleteIdState },
            { headers: { authorization: `bearer ${token}` } },
          )
          .then((response) => {
            if (response.data.success) {
                let uploadedDocuments = response.data.uploadedDocuments
                data[selectedBuilding - 1].services[selectedService - 1].servicesDocuments = uploadedDocuments
                setData(data)              
                setUpdate(!update)
            }
          })
          .catch((error) => {})
      }

    function rebuildData(data) {
        let newStructure = JSON.parse(JSON.stringify(data));
        // first we delete all instances of projects within each building object
        newStructure.forEach(item => {
            delete item.projects;
        })

        // now for each building we push in the required services regardless of the project
        data.forEach((item, index) => {
            let services = []
            item.projects.forEach(projectItem => {
                projectItem.projectServices.forEach(serviceItem => {
                    services.push(serviceItem);
                })
            })
            newStructure[index]["services"] = [...services];
        })
        
        let selectedBuildingIndex = null;
        let selectedServiceIndex = null;
        if(file) {
            newStructure.forEach((building, buildingIndex) => {
                building.services.forEach((service, serviceIndex) => {
                    service.servicesDocuments.forEach((document, documentIndex) => {
                        if(document.id == file) {
                            selectedBuildingIndex = buildingIndex + 1;
                            selectedServiceIndex = serviceIndex + 1;
                        }
                    })
                })
            })
        }
        // the dependency now follows building -> services -> files
        setData(newStructure)
        setOriginalData(newStructure);
        if(selectedBuildingIndex && selectedServiceIndex) {
            setSelectedBuilding(selectedBuildingIndex);
            setSelectedService(selectedServiceIndex);
            readDocumentApi(newStructure[selectedBuildingIndex - 1].services[selectedServiceIndex - 1].id)
        }
    }

    function getAllServices() {
        const token = localStorage.getItem('authToken');
        axios.post(process.env.REACT_APP_API_URL+"/service/all", {}, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setOriginalServiceList(response.data)
            setServiceList(response.data)
        }).catch(error => {
        })
    }

    function getBuildingsByService(serviceId) {
        const token = localStorage.getItem('authToken')
        axios.post(process.env.REACT_APP_API_URL+"/service/get/buildings", {
            serviceId,
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            setBuildingList(response.data.data)
        }).catch(error => {
        })
    }

    function findData(id) {
        const newOriginalData = JSON.parse( JSON.stringify(originalData) );
        let serviceData = newOriginalData.filter(building => building.id === id); // return the building we selected in the ui
        serviceData = serviceData.length > 0 ? serviceData[0].services.filter(service => service.service.id === serviceList[selectedBuilding - 1].id) : []
        setDocumentsList(serviceData);
    }

    function getFileNumber(id) {
        let stringId = id.toString();
        let fileNumber = ""
        Array(4 - stringId.length).fill().forEach(_=> {
          fileNumber = fileNumber + "0";
        })
        fileNumber = fileNumber + stringId;
        return fileNumber;
    }

    return (
        <Grid container alignItems="flex-start">
            <Grid container>
                <Hidden smDown>
                    <Header />
                </Hidden>
                <Hidden smUp>
                    <Header toShowHamburger />
                </Hidden>
            </Grid>
            <Hidden smDown>
                <div className="documents-panel-container vertical-start">
                    {originalData.length > 0 && originalData ? (
                        <div className="flex-row width-auto horizontal-center fade-in-right">
                            <Grid container item sm={11} alignItems="flex-start">
                                <Grid container item sm={12}>
                                    <div className="margin-top-25" />
                                </Grid>
                                <Grid container item sm={12}>
                                    <div className="flex-row width-75-p vertical-center">
                                        <div className="message-panel-search-container vertical-center">
                                            <img className="message-panel-search-icon margin-left-15" alt="search" src={require('../../../assets/svg/icon/glass.svg')} />
                                            <input className="message-panel-search main-font-regular font-size-14 font-color-standard-gray margin-left-15" placeholder="Zoeken" value={search} onChange={handleSearch} />
                                        </div>
                                        <div className="documents-panel-divider" />
                                        <button type="button" className="documents-panel-search-building center-content animatable-400 ease-in-out-quart cursor-pointer" style={{ border: !searchType ? 'solid 1px #7DA5C2' : 'none', background: !searchType ? 'white' : 'none' }} onClick={() => { setSelectedBuilding(0); setSelectedService(0); setSearchType(false); }}>
                                            <p className="main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart" style={{ color: !searchType ? 'black' : '#6F6F6F' }}>Zoek per gebouw</p>
                                        </button>
                                        <button type="button" className="documents-panel-search-building center-content animatable-400 ease-in-out-quart cursor-pointer" style={{ border: searchType ? 'solid 1px #7DA5C2' : 'none', background: searchType ? 'white' : 'none' }} onClick={() => { setSelectedBuilding(0); setSelectedService(0); setSearchType(true); }}>
                                            <p className="main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart" style={{ color: searchType ? 'black' : '#6F6F6F' }}>Zoek per dienst</p>
                                        </button>
                                    </div>
                                    <div className="flex-row width-25-p horizontal-end vertical-center">
                                        <p className="main-font-regular font-size-12 font-color-grey text-direction-rtl">Drag & drop een document om het<br></br>te oploaden</p>
                                        <img src={require('../../../assets/svg/arrow_curve.svg')} alt="arrow curve" className="documents-panel-arrow-curve" />
                                    </div>
                                </Grid>
                                <Grid container item sm={12}>
                                    <div className="margin-top-25" />
                                </Grid>
                                <Grid container item sm={12}>
                                    <div className="documents-panel-panel">
                                        <Grid container item sm={12} className="height-auto">
                                            <Grid container item sm={3} className="height-auto">
                                                <div className="height-auto width-auto overflow-y-auto documents-panel-panel-divider">
                                                    {!searchType && data.map((item, index) => (
                                                        <div className = "documents-panel-tab flex-row vertical-center animatable-400 ease-in-out-quart" style = {{background: selectedBuilding === index + 1 ? '#E5EDF3' : 'rgba(0,0,0,0)'}} onClick = {() => { setSelectedBuilding(index + 1); setSelectedService(0); setShowMenu(0); setServiceId(0); setProjectId(0); }}>
                                                            <p className = "main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart margin-left-35" style = {{fontWeight: selectedBuilding === index + 1 ? '700' : '400', color: selectedBuilding === index + 1 ? '#193E5D' : 'black'}}>{`${getFileNumber(item.id)}-${item.title}`}</p>
                                                            <img src = {require('../../../assets/svg/icon/arrow_right_standard.svg')} alt = "arrow right" className = "documents-panel-tab-arrow animatable-400 ease-in-out-quart" style = {{opacity: selectedBuilding === index + 1 ? 1 : 0 }} />
                                                        </div>
                                                    ))}
                                                    {searchType && serviceList.map((item, index) => (
                                                        <div className = "documents-panel-tab flex-row vertical-center animatable-400 ease-in-out-quart" style = {{background: selectedBuilding === index + 1 ? '#E5EDF3' : 'rgba(0,0,0,0)'}} onClick = {() => { getBuildingsByService(serviceList[index].id); setSelectedBuilding(index + 1); setSelectedService(0); setShowMenu(0); }}>
                                                            <p className = "main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart margin-left-35" style = {{fontWeight: selectedBuilding === index + 1 ? '700' : '400', color: selectedBuilding === index + 1 ? '#193E5D' : 'black'}}>{item.title}</p>
                                                            <img src = {require('../../../assets/svg/icon/arrow_right_standard.svg')} alt = "arrow right" className = "documents-panel-tab-arrow animatable-400 ease-in-out-quart" style = {{opacity: selectedBuilding === index + 1 ? 1 : 0 }} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>
                                            <Grid container item sm={3} className="height-auto">
                                                <div className="height-auto width-auto overflow-y-auto documents-panel-panel-divider">
                                                    {!searchType && selectedBuilding !== 0 && data[selectedBuilding - 1].services.length > 0 && data[selectedBuilding - 1].services.map((item, index) => (
                                                        <div className = "documents-panel-tab flex-row vertical-center animatable-400 ease-in-out-quart" style = {{background: selectedService === index + 1 ? '#E5EDF3' : 'rgba(0,0,0,0)'}} onClick = {() => { setSelectedService(index + 1); setShowMenu(0); readDocumentApi(item.id); setServiceId(item.serviceId); setProjectId(item.projectId); }}>
                                                            <p className = "main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart margin-left-35" style = {{fontWeight: selectedService === index + 1 ? '700' : '400', color: selectedService === index + 1 ? '#193E5D' : 'black'}}>{item.service.title}</p>
                                                            <img src = {require('../../../assets/svg/icon/arrow_right_standard.svg')} alt = "arrow right" className = "documents-panel-tab-arrow animatable-400 ease-in-out-quart" style = {{opacity: selectedService === index + 1 ? 1 : 0 }} />
                                                        </div>
                                                    ))}
                                                    {searchType && selectedBuilding !== 0 && buildingList.map((item, index) => (
                                                        <div className = "documents-panel-tab flex-row vertical-center animatable-400 ease-in-out-quart" style = {{background: selectedService === index + 1 ? '#E5EDF3' : 'rgba(0,0,0,0)'}} onClick = {() => { findData(item.id); setSelectedService(index + 1); setShowMenu(0); readDocumentApi(item.id); }}>
                                                            <p className = "main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart margin-left-35" style = {{fontWeight: selectedService === index + 1 ? '700' : '400', color: selectedService === index + 1 ? '#193E5D' : 'black'}}>{`${getFileNumber(item.id)}-${item.title}`}</p>
                                                            <img src = {require('../../../assets/svg/icon/arrow_right_standard.svg')} alt = "arrow right" className = "documents-panel-tab-arrow animatable-400 ease-in-out-quart" style = {{opacity: selectedService === index + 1 ? 1 : 0 }} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>
                                            <Grid container item sm={6} className="height-auto" >
                                                <div className="height-auto width-auto overflow-y-auto documents-panel-panel-divider">
                                                    <DocumentsDropzone sendUploadedFile={getUploadedFile} >
                                                        <div className="flex-column width-auto height-95-p">
                                                            <div className="flex-row width-auto">
                                                                <div className="margin-top-25" />
                                                            </div>
                                                            {/* <div className="flex-row width-auto">
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    justify="center"
                                                                    spacing={2}
                                                                >
                                                                    <Grid container item md={1} xs={1} sm={1} />
                                                                    <Grid container item xs={4} sm={4} md={4}>
                                                                        <p className="actions-information-document-headers vietnam-regular margin-left-25">
                                                                        Naam
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid container item xs={2} sm={2} md={2}>
                                                                        <p className="actions-information-document-headers vietnam-regular">
                                                                        Persoon
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid container item xs={2} sm={2} md={2}>
                                                                        <p className="actions-information-document-headers vietnam-regular">
                                                                        label
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid container item xs={2} sm={2} md={2}>
                                                                        <div className="flex-row">
                                                                        <p className="actions-information-document-headers vietnam-regular">
                                                                            Gepubliceerd
                                                                        </p>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid md={1} />
                                                                </Grid>
                                                            </div> */}
                                                            <div className="flex-row width-auto">
                                                                <div className="margin-top-25" />
                                                            </div>
                                                            {!searchType && selectedBuilding !== 0 && data[selectedBuilding - 1].services.length > 0 && selectedService !== 0 && data[selectedBuilding - 1].services[selectedService - 1].servicesDocuments && data[selectedBuilding - 1].services[selectedService - 1].servicesDocuments.length > 0 && data[selectedBuilding - 1].services[selectedService - 1].servicesDocuments.map((item, index) => {
                                                                if(item.file) {
                                                                    return(
                                                                        <div className="flex-row vertical-center width-auto margin-top-10">
                                                                            <RowDetails
                                                                                file={item}
                                                                                userDetails={userDetails}
                                                                                index={index}
                                                                                deleteFile={deleteFile}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    return(
                                                                        <>
                                                                            <div className="flex-row vertical-center width-auto margin-top-10">
                                                                                <RowDetails
                                                                                    file={item}
                                                                                    userDetails={userDetails}
                                                                                    index={index}
                                                                                    deleteFile={deleteFile}
                                                                                />
                                                                            </div>
                                                                            <Grid container justify = "center">
                                                                                <Grid container item xs = {11} justify = "flex-end">
                                                                                    <p className = "main-font-regular font-size-14 font-color-red">dit bestand is beschadigd</p>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                }
                                                            })}
                                                            {searchType && selectedBuilding !== 0 && documentsList.length > 0 && documentsList[0].servicesDocuments.map((item, index) => {
                                                                if(item.file) {
                                                                    return(
                                                                        <div className="flex-row vertical-center width-auto margin-top-10">
                                                                            <RowDetails
                                                                                file={item}
                                                                                userDetails={userDetails}
                                                                                index={index}
                                                                                deleteFile={deleteFile}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    return(
                                                                        <>
                                                                            <div className="flex-row vertical-center width-auto margin-top-10">
                                                                                <RowDetails
                                                                                    file={item}
                                                                                    userDetails={userDetails}
                                                                                    index={index}
                                                                                    deleteFile={deleteFile}
                                                                                />
                                                                            </div>
                                                                            <Grid container justify = "center">
                                                                                <Grid container item xs = {11} justify = "flex-end">
                                                                                    <p className = "main-font-regular font-size-14 font-color-red">dit bestand is beschadigd</p>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                        {fileUploadProgress !== 0 && (
                                                            <div className = 'width-auto center-content'>
                                                                <p className = "main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">Upload</p>
                                                                <div className = 'slider'>
                                                                    <div className = 'animatable-400 slider-thumb' style = {{width: fileUploadProgress*2}} />
                                                                </div>
                                                                <p className = "main-font-semi-bold font-size-12 font-color-sky-blue">{fileUploadProgress}%</p>
                                                            </div>
                                                        )}     
                                                    </DocumentsDropzone>
                                                </div>
                                                <LabelSelect
                                                    modalState={modalState}
                                                    setModalState={setModalState}
                                                    data={labelData}
                                                    setSelectedValue={setSelectedValue}
                                                    selectedValue={selectedValue}
                                                    handleFilesLabel={handleFilesLabel}
                                                />
                                                {
                                                    showDeletePopupState === true ?
                                                    <DeleteConfirm id={deleteIdState} setIdState={setDeleteIdState} setOpenState={setShowDeletePopupState} handleDeleteClick={handleDeleteClick}/>
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <div className = "height-auto width-auto center-content">
                            <img src = {require('../../../assets/svg/icon/loading.gif')} alt = "loader" className = "services-loader" />
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden smUp>
                <Grid container items sm = {12} >
                    <div className = "width-auto background-color-white horizontal-center">
                        <Container>
                            <Grid container item sm = {12}>
                                <div className="message-panel-search-container vertical-center margin-top-25">
                                    <img className="message-panel-search-icon margin-left-15" alt="search" src={require('../../../assets/svg/icon/glass.svg')} />
                                    <input className="message-panel-search main-font-reguldata[selectedBuilding - 1].services[selectedService - 1].servicesDocumentsar font-size-14 font-color-standard-gray margin-left-15" placeholder="Zoek ieder informatie…" value={search} onChange={handleSearch} />
                                </div>
                                <div className = "flex-row width-auto horizontal-between margin-top-25">
                                    <button type="button" className="documents-panel-search-building center-content animatable-400 ease-in-out-quart cursor-pointer" style={{ border: !searchType ? 'solid 1px #7DA5C2' : 'none', background: !searchType ? 'white' : 'none' }} onClick={() => { setSelectedBuilding(0); setSelectedService(0); setSearchType(false); }}>
                                        <p className="main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart" style={{ color: !searchType ? 'black' : '#6F6F6F' }}>Zoek per gebouw</p>
                                    </button>
                                    <button type="button" className="documents-panel-search-building center-content animatable-400 ease-in-out-quart cursor-pointer" style={{ border: searchType ? 'solid 1px #7DA5C2' : 'none', background: searchType ? 'white' : 'none' }} onClick={() => { setSelectedBuilding(0); setSelectedService(0); setSearchType(true); }}>
                                        <p className="main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart" style={{ color: searchType ? 'black' : '#6F6F6F' }}>Zoek per dienst</p>
                                    </button>
                                </div>
                                <div className = "documents-panel-panel margin-top-25 margin-bottom-25">
                                    <div className = "documents-panel-panel-mobile-nav flex-row vertical-center">
                                        {!searchType ? (
                                            <>
                                            {selectedBuilding !== 0 && (
                                                <div className = "flex-row" onClick = {() => { setSelectedBuilding(0); setSelectedService(0); setShowMenu(0); setServiceId(0); setProjectId(0); }}>
                                                    <p className = "main-font-regular font-size-12 font-color-black">{`${getFileNumber(data[selectedBuilding - 1].id)}-${data[selectedBuilding - 1].title}`}</p>
                                                    <img src = {require('../../../assets/svg/icon/arrow_right_standard.svg')} alt=  "arrow left" className = "documents-panel-panel-mobile-arrow margin-left-5 margin-right-5" />
                                                </div>
                                            )}
                                            {selectedService !== 0 && (
                                                <p className = "main-font-regular font-size-12 font-color-black" onClick = {() => { setSelectedService(0); setShowMenu(0); setServiceId(0); setProjectId(0); }} >{data[selectedBuilding - 1].services[selectedService - 1].service.title}</p>
                                            )}
                                            </>
                                        ) : (
                                            <>
                                            {selectedBuilding !== 0 && (
                                                <div className = "flex-row" onClick = {() => { setSelectedBuilding(0); setSelectedService(0); setShowMenu(0); setServiceId(0); setProjectId(0); }}>
                                                    <p className = "main-font-regular font-size-12 font-color-black">{serviceList[selectedBuilding - 1].title}</p>
                                                    <img src = {require('../../../assets/svg/icon/arrow_right_standard.svg')} alt=  "arrow left" className = "documents-panel-panel-mobile-arrow margin-left-5 margin-right-5" />
                                                </div>
                                            )}
                                            {selectedService !== 0 && (
                                                <p className = "main-font-regular font-size-12 font-color-black" onClick = {() => { setSelectedService(0); setShowMenu(0); setServiceId(0); setProjectId(0); }} >{`${getFileNumber(buildingList[selectedService - 1].id)}-${buildingList[selectedService - 1].title}`}</p>
                                            )}
                                            </>
                                        )}
                                    </div>
                                    <div className = "documents-panel-panel-mobile-explorer overflow-y-auto">
                                        {!searchType ? (
                                            <>
                                            {selectedBuilding === 0 && data.map((item, index) => (
                                                <div className = "documents-panel-panel-mobile-item vertical-center" onClick = {() => { setSelectedBuilding(index + 1); setSelectedService(0); setShowMenu(0); setServiceId(0); setProjectId(0); }} >
                                                    <p className= "main-font-regular font-size-14 font-color-black margin-left-15">{`${getFileNumber(item.id)}-${item.title}`}</p>
                                                </div>
                                            ))}
                                            {selectedBuilding !== 0 && selectedService === 0 && data[selectedBuilding - 1].services.length > 0 && data[selectedBuilding - 1].services.map((item, index) => (
                                                <div className = "documents-panel-panel-mobile-item vertical-center" onClick = {() => { setSelectedService(index + 1); setShowMenu(0); readDocumentApi(item.id); setServiceId(item.serviceId); setProjectId(item.projectId); }} >
                                                    <p className= "main-font-regular font-size-14 font-color-black margin-left-15">{item.service.title}</p>
                                                </div>
                                            ))}
                                            </>
                                        ) : (
                                            <>
                                            {selectedBuilding === 0 && serviceList.map((item, index) => (
                                                <div className = "documents-panel-tab flex-row vertical-center animatable-400 ease-in-out-quart" onClick = {() => { getBuildingsByService(serviceList[index].id); setSelectedBuilding(index + 1); setSelectedService(0); setShowMenu(0); }}>
                                                    <p className = "main-font-regular font-size-14 font-color-black margin-left-15">{item.title}</p>
                                                </div>
                                            ))}
                                            {selectedBuilding !== 0 && selectedService === 0 && buildingList.map((item, index) => (
                                                <div className = "documents-panel-tab flex-row vertical-center animatable-400 ease-in-out-quart" onClick = {() => { findData(item.id); setSelectedService(index + 1); setShowMenu(0); readDocumentApi(item.id); }}>
                                                    <p className = "main-font-regular font-size-14 font-color-black animatable-400 ease-in-out-quart margin-left-35">{`${getFileNumber(item.id)}-${item.title}`}</p>
                                                </div>
                                            ))}
                                            </>
                                        )}
                                        {!searchType && selectedBuilding !== 0 && data[selectedBuilding - 1].services.length > 0 && selectedService !== 0 && data[selectedBuilding - 1].services[selectedService - 1].servicesDocuments.length > 0 && data[selectedBuilding - 1].services[selectedService - 1].servicesDocuments.map((item, index) => (
                                            <div className="flex-row vertical-center width-auto margin-top-10">
                                            <RowDetails
                                            file={item}
                                            userDetails={userDetails}
                                            index={index}
                                            deleteFile={deleteFile}
                                            />
                                        </div>
                                        ))}
                                        {searchType && selectedBuilding !== 0 && selectedService !== 0 && documentsList.length > 0 && documentsList[0].servicesDocuments.map((item, index) => (
                                            <div className="flex-row vertical-center width-auto margin-top-10">
                                            <RowDetails
                                            file={item}
                                            userDetails={userDetails}
                                            index={index}
                                            deleteFile={deleteFile}
                                            />
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </Grid>   
                                                    
                        </Container>
                    </div>
                </Grid>
            </Hidden>
        </Grid>
    )
}