import React, { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar"
import { debounce } from "lodash"
import { Grid, Hidden } from "@material-ui/core";
import MobileView from "../../mobile_view/mobile_view";
import axios from "axios";

import "./_timeline.scss";
import '../../services/calendar.css'

import Header from '../../header/header';

function ArrowLeft(props) {
    const { rotate } = props
    return (
      <img
        src={require('../../../../assets/svg/icon/arrow_left.svg')}
        alt="arrow left"
        style={{
          height: '15px',
          width: '15px',
          transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
        className="object-fit-contain"
      />
    )
}

function ServiceModal(props) {
    const [ selectedService, setSelectedService ] = useState(0) // we store the serviceId in here to send/update the data 
    const [ selectedDate, setSelectedDate ] = useState( new Date() );
    const [ startTimeHour, setStartTimeHour ] = useState("");
    const [ startTimeMinute, setStartTimeMinute ] = useState("");
    const [ endTimeHour, setEndTimeHour ] = useState("");
    const [ endTimeMinute, setEndTimeMinute ] = useState("");
    const [ datePickerActive, setDatePickerActive ] = useState(false)
    const [ servicePickerActive, setServicePickerActive ] = useState(false)
    const [serviceIndex, setServiceIndex] = useState(0)
    // error state vars
    const [ startTimeError, setStartTimeError ] = useState(false);
    const [ endTimeError, setEndTimeError ] = useState(false)

    const { data, index, getUpdatedData, setSelectedBuilding, selectedBuilding, building } = props;

    const handleStartTimeHour = (event) => {
        if(event.target.validity.valid) {
            startTimeError && setStartTimeError(false)
        }
        else {
            !startTimeError && setStartTimeError(true)
        }
        setStartTimeHour(event.target.value)
    }

    const handleStartTimeMinute = (event) => {
        if(event.target.validity.valid) {
            startTimeError && setStartTimeError(false)
        }
        else {
            !startTimeError && setStartTimeError(true)
        }
        setStartTimeMinute(event.target.value)
    }

    const handleEndTimeHour = (event) => {
        if(event.target.validity.valid) {
            endTimeError && setEndTimeError(false)
        }
        else {
            !endTimeError && setEndTimeError(true)
        }
        setEndTimeHour(event.target.value)
    }

    const handleEndTimeMinute = (event) => {
        if(event.target.validity.valid) {
            endTimeError && setEndTimeError(false)
        }
        else {
            !endTimeError && setEndTimeError(true)
        }
        setEndTimeMinute(event.target.value)
    }

    function updateServiceTimeline() {

        const token = localStorage.getItem('authToken');
        axios.post(process.env.REACT_APP_API_URL+'/project_services/update_time_line', {
            projectServiceId: selectedService,
            startTime: `${startTimeHour}:${startTimeMinute}:00`,
            date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12),
            endTime: `${endTimeHour}:${endTimeMinute}:00`
        }, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(response => {
            // saveEvent()
            setSelectedService(0)
            resetFields();
            getUpdatedData();
        }).catch(error => {
        })
    }

    function updateDateTime(eventId) {
        let token = localStorage.getItem('authToken')
        axios.post(process.env.REACT_APP_API_URL+'/project_services/update_service_date', {
          id: selectedService,
          billing_date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12),
          eventId:eventId
        }, {
          headers: {
            authorization: `bearer ${token}`
          }
        }).then(response => {
        //   let newData = JSON.parse(JSON.stringify(searchDataPool))
        //   const currentDate = new Date(date.split('-')[0],(date.split('-')[1]-1),date.split('-')[2],12);
        //   newData[buildingIndex].projects[projectIndex].projectServices[serviceIndex].billing_date = `${currentDate.getFullYear()} / ${currentDate.getMonth() + 1} / ${currentDate.getDate()}`
        //   setSearchDataPool(newData)
          // success
        }).catch(error => {
        })
      }
    
      function updateStartTime(eventId) {
        let token = localStorage.getItem('authToken')
        axios.post(process.env.REACT_APP_API_URL+'/project_services/update_service_start_time', {
          id: selectedService,
          billing_start_time:  `${startTimeHour}:${startTimeMinute}:00`,
        }, {
          headers: {
            authorization: `bearer ${token}`
          }
        }).then(response => {
          // success
        }).catch(error => {
        })
      }
    
      function updateEndTime(eventId) {
        let token = localStorage.getItem('authToken')
        axios.post(process.env.REACT_APP_API_URL+'/project_services/update_service_end_time', {
          id: selectedService,
          billing_end_time: `${endTimeHour}:${endTimeMinute}:00`
        }, {
          headers: {
            authorization: `bearer ${token}`
          }
        }).then(response => {
          // success
        }).catch(error => {
        })
      }
    
      function saveEvent() {
        const token = localStorage.getItem('authToken')
        const projectServiceData = building.projects[0].projectServices.filter(service => service.id === selectedService)
        console.log({data, building, selectedBuilding, selectedService, index, projectServiceData: projectServiceData[0].eventId})
        if(projectServiceData[0].eventId === null){
          const userDetails = JSON.parse( localStorage.getItem('loggedInUserDetails' ))
          let data = {
            user_id: userDetails.id,
            service_id: projectServiceData[0].service.id,
            message: "inspectie datum",
            email: building.user.email,
            start_date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12),
            end_date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12),
            start_time: `${startTimeHour}:${startTimeMinute}:00`,
            end_time: `${endTimeHour}:${endTimeMinute}:00`,
            is_full_day: false,
            company_name: building.fileName,
            service_name: projectServiceData[0].service.title,
            skip_email: false
          }
          axios.post(process.env.REACT_APP_API_URL+'/events/add', 
            data, 
            {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }).then((response) => {
            if(response.status===200){
              updateDateTime(response.data.id);
              updateStartTime()
              updateEndTime()
            }
          }).catch((error) => {
          })
        }
        else{

          const userDetails = JSON.parse( localStorage.getItem('loggedInUserDetails' ))
          let data = {
            id: projectServiceData[0].eventId,
            user_id: userDetails.id,
            service_id: projectServiceData[0].service.id,
            message: "inspectie datum",
            email: building.user.email,
            start_date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12),
            end_date: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12),
            start_time: `${startTimeHour}:${startTimeMinute}:00`,
            end_time: `${endTimeHour}:${endTimeMinute}:00`,
            is_full_day: false,
            company_name: building.fileName,
            service_name: projectServiceData[0].service.title,
          }
          axios.post(process.env.REACT_APP_API_URL+'/events/update', 
            data, 
            {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }).then((response) => {
            if(response.status===200){
              updateDateTime(projectServiceData[0].eventId);
              updateStartTime()
              updateEndTime()
            }
          }).catch((error) => {
          })
        }
      }

    function isFieldEmpty() {
        if(startTimeHour === "") {
            setStartTimeError(true);
            return true;
        }
        else if(startTimeMinute === "") {
            setStartTimeError(true);
            return true;
        }
        else if(endTimeMinute === "") {
            setEndTimeError(true);
            return true
        }
        else if(endTimeHour === "") {
            setEndTimeError(true)
            return true
        } 
        else return false;
    }

    const handleSaveButton = () => {
        // validate input and make sure date is valid 
        if(!startTimeError && !endTimeError) {
            if(!isFieldEmpty()) {
                updateServiceTimeline();
                saveEvent()
            }
        }
    }

    function resetFields() {
        setSelectedDate(new Date())
        setStartTimeHour("");
        setStartTimeMinute("");
        setEndTimeHour("");
        setEndTimeMinute("");
        setStartTimeError(false);
        setEndTimeError(false)
    }

    function cleanDateString(date) {
        let newDate = date.split(" ")[0];
        newDate = newDate.replace(/-/g, "/")
        return newDate;
    }   

    function cleanTimeString(time) {
        let newTime = time.split(":");
        newTime = `${newTime[0]}:${newTime[1]}`;
        return newTime;
    }

    const handleServiceMouseOver = () => { 
        !servicePickerActive && setServicePickerActive(true)
    }

    const handleServiceMouseLeave = () => { 
        servicePickerActive && setServicePickerActive(false)
    }

    const handleOnMouseOver = () => {
        !datePickerActive && setDatePickerActive(true)
    }

    const handleOnMouseLeave = () => {
        datePickerActive && setDatePickerActive(false)
    }

    return(
        <div className = "timeline-planner-sidebar-building cursor-pointer relative outline-none" tabIndex = "1" onBlur = {() => { if(!datePickerActive) setSelectedBuilding(0) }} >
            <div className = "height-auto width-auto vertical-center" onClick = {() => { selectedBuilding === (index + 1) ? setSelectedBuilding(0) : setSelectedBuilding(index + 1)}}>
                <h1 className = "main-font-medium font-size-14 font-color-black margin-left-25 text-ellipsis">{building.title}</h1>
            </div>
            {(index + 1) === selectedBuilding && (
                <div className = "timeline-planner-sidebar-building-modal-container flex-row fade-in-translate outline-none" >
                    <div className = "height-auto">
                        <img src = {require('../../../../assets/svg/icon/corner.png')} alt = "corner" className = "timeline-planner-sidebar-building-modal-arrow" />
                    </div>
                    <div className = "timeline-planner-sidebar-building-modal" onMouseEnter = {handleServiceMouseOver} onMouseLeave = {handleServiceMouseLeave}>
                        {data[index].projects.map(project => (
                            <>
                            {project.projectServices.map((service, index) => (
                                <div className = "timeline-planner-sidebar-building-modal-item timeline-planner-sidebar-building-modal-border flex-row horizontal-end" style = {{background: selectedService !== 0 && selectedService === service.id ? '#E5EDF3' : 'rgba(0, 0, 0, 0)'}} onClick = { selectedService === service.id ? () => {setSelectedService(0); setServiceIndex(0);} : () => {setSelectedService(service.id); setServiceIndex(index);}}>
                                    <div className = "flex-column">
                                        <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-disable">{service.service.title}</h1>
                                        <p className = "main-font-regular font-size-14 font-color-grey margin-top-5">{service.billing_date && service.billing_start_time && `${cleanDateString(service.billing_date)} - ${cleanTimeString(service.billing_start_time)}`}</p>
                                    </div>
                                    <div className = "flex-row">
                                        <img src = {require('../../../../assets/svg/icon/timeline.svg')} alt = "timeline" className = "timeline-planner-sidebar-building-modal-icon" />
                                    </div>
                                </div>
                            ))}
                            </>
                        ))}
                    </div>
                    <div className = "timeline-planner-sidebar-building-modal-date fade-in-translate" style = {{display: selectedService === 0 ? 'none' : 'flex', marginTop: serviceIndex * 50}} onMouseEnter = {handleOnMouseOver} onMouseLeave = {handleOnMouseLeave} >
                        <div className="services-details-date-filter relative">
                            <Calendar
                                prevLabel={<ArrowLeft />}
                                nextLabel={<ArrowLeft rotate />}
                                value={selectedDate}
                                onChange={(value, e) => {
                                    setSelectedDate(value)
                                }}
                            />
                            <div className="width-auto flex-row margin-top-15">
                                <div className="width-auto flex-column">
                                    <p className="main-font-light font-size-14 font-color-grey">
                                    Van
                                    </p>
                                    <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5" style = {{border: startTimeError ? 'solid 1px red' : 'solid 1px #DADADA'}}>
                                    <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        maxLength={2}
                                        placeholder = "12"
                                        value={startTimeHour}
                                        onChange={handleStartTimeHour}
                                    />
                                    <p className="main-font-medium font-color-black font-size-14">
                                        :
                                    </p>
                                    <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        maxLength={2}
                                        placeholder = "30"
                                        value={startTimeMinute}
                                        onChange={handleStartTimeMinute}
                                    />
                                    </div>
                                </div>
                                <div className="width-auto flex-column">
                                    <p className="main-font-light font-size-14 font-color-grey">
                                    Tot
                                    </p>
                                    <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5" style = {{border: endTimeError ? 'solid 1px red' : 'solid 1px #DADADA'}}>
                                    <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        maxLength={2}
                                        placeholder = "14"
                                        value={endTimeHour}
                                        onChange={handleEndTimeHour}
                                    />
                                    <p className="main-font-medium font-color-black font-size-14">
                                        :
                                    </p>
                                    <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        maxLength={2}
                                        placeholder = "45"
                                        value={endTimeMinute}
                                        onChange={handleEndTimeMinute}
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className = "timeline-planner-sidebar-building-modal-save center-content margin-top-10" onClick = {handleSaveButton} >
                                <h1 className = "main-font-semi-bold font-color-white font-size-14">Save</h1>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

function TimelineCard(props) {
    const [ dropdownState, setDropdownState ] = useState(false);

    const { services, service, buildingName } = props; // services is the object that contains the date and service is the actual service for that date 

    const handleDropdownState = () => {
        setDropdownState(!dropdownState)
    }

    const handleDropdownBlur = () => {
        setDropdownState(false)
    }

    function cleanDateString(date) {
        let newDate = date.split(" ")[0];
        newDate = newDate.replace(/-/g, "/")
        return newDate;
    }   

    function cleanTimeString(time) {
        let newTime = time.split(":");
        newTime = `${newTime[0]}:${newTime[1]}`;
        return newTime;
    }

    return(
        <div className = "timeline-planner-viewport-card center-content padding-5 relative outline-none" tabIndex = "1" style = {{width: services.services.length > 1 ? `calc(${100/services.services.length}% - 5px)` : '77px', background: service.service.color}} onClick = {handleDropdownState} onBlur = {handleDropdownBlur} >
            <div className = "height-auto width-auto center-content">
                <h1 className = "main-font-semi-bold font-size-9 font-color-white text-ellipsis margin-disable">{service.service.title}</h1>
            </div>
            <div className = "timeline-planner-viewport-card-modal-container flex-column animatable-400 ease-in-out-quart" style = {{height: dropdownState ? '120px' : '0px '}}>
                <div className = "width-auto horizontal-start">
                    <img src = {require('../../../../assets/svg/icon/corner.png')} alt = "arrow" className = "timeline-planner-viewport-card-modal-arrow" />
                </div>
                <div className = "timeline-planner-viewport-card-modal">
                    <h1 className = "main-font-semi-bold font-size-12 font-color-black margin-disable">{buildingName}</h1>
                    <div className = "flex-row vertical-center">
                        <div className = "timeline-planner-viewport-card-modal-color" style = {{background: service.service.color}} />
                        <p className = "main-font-regular font-size-12 font-color-grey">{service.service.title}</p>
                    </div>
                    <div className = "flex-row">
                        <p className = "main-font-regular font-size-12 font-color-grey">{service.billing_date && service.billing_start_time && `${cleanDateString(service.billing_date)} - ${cleanTimeString(service.billing_start_time)}`}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Timeline( props ) {
    const [ search, setSearch ] = useState("")
    const [ weekStart, setWeekStart ] = useState( new Date() );
    const [ weekEnd, setWeekEnd ] = useState( new Date() )
    const [ data, setData ] = useState([]);
    const [ dataPool, setDataPool ] = useState([])
    const [ cardData, setCardData ] = useState([]);
    const [ selectedBuilding, setSelectedBuilding ] = useState(0);

    const { getUpdatedData } = props;

    useEffect(() => {
        const token = localStorage.getItem('authToken')
        axios
        .post(
          process.env.REACT_APP_API_URL+'/dashboard/adminData',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
            setData(response.data)
            setDataPool(response.data)
        })
        .catch((error) => {
        })
    }, []);

    useEffect(() => {
        getCurrentWeek();
    }, [])

    useEffect(() => {
        generateDataPool(weekStart);
    }, [dataPool, weekStart])

    function getCurrentWeek() {
        // the week for this calendar starts on the monday, which has the value of 1
        const currentDate = new Date();
        let currentDay = currentDate.getDay();
        let weekStartDate, weekEndDate;
        
        // three scenarios, if the day is already monday, then day is greater than monday, and the day is Sunday
        if(currentDay === 0) {
            // this is the last day of the week
            weekStartDate = new Date( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6);
            weekEndDate = currentDate;
        }
        else if(currentDay > 1) {
            weekStartDate = new Date( currentDate.getFullYear(), currentDate.getMonth(), (currentDate.getDate() - currentDate.getDay()) + 1)
            // if we subtract the current date from current day we basically end up on the first day of that week, however since by default the first day of the week is Sunday, we manually add 1
            weekEndDate = new Date( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (7 - currentDate.getDay()));
        }
        else {
            weekStartDate = currentDate;
            weekEndDate = new Date( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 6)
        }

        generateDataPool(weekStartDate, weekEndDate)
        setWeekStart(weekStartDate);
        setWeekEnd(weekEndDate);
    }

    const previousWeek = () => {
        const weekStartDate = new Date( weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() - 7);
        const weekEndDate = new Date( weekEnd.getFullYear(), weekEnd.getMonth(), weekEnd.getDate() - 7);

        generateDataPool(weekStartDate, weekEndDate)
        setWeekStart(weekStartDate);
        setWeekEnd(weekEndDate);
    }

    const nextWeek = () => {
        const weekStartDate = new Date( weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 7);
        const weekEndDate = new Date( weekEnd.getFullYear(), weekEnd.getMonth(), weekEnd.getDate() + 7);

        generateDataPool(weekStartDate, weekEndDate)
        setWeekStart(weekStartDate);
        setWeekEnd(weekEndDate);
    }

    function generateDataPool( weekStart, weekEnd ) {
        const newDataPool = JSON.parse( JSON.stringify(dataPool) );
        const buildingDataSet = [];

        newDataPool.forEach(building => {
            const dataSets = [];
            building.projects.forEach(project => {
                Array(7).fill().map((_, index) => {
                    const dataSet = []; 
                    project.projectServices.forEach(service => {
                        if(service.billing_date) {
                            let timelineDate = service.billing_date.split(" ")
                            timelineDate = timelineDate[0].split("-");
                            // we first form data sets, for example if there are multiple services that are on the same day, we create a set
                            if(new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + index).getDate() === parseInt(timelineDate[2]) ) {
                                dataSet.push(service);
                            }
                        }
                    })
                    dataSets.push({
                        date: new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + index),
                        services: dataSet
                    })
                })
            })
            buildingDataSet.push({
                buildingName: building.title,
                buildingId: building.id,
                serviceData: dataSets
            })
        })

        setCardData(buildingDataSet)
    }

    function getStringMonth( month ) {
        if(month === 0) return "Januari";
        else if(month === 1) return "Februari";
        else if(month === 2) return "Maart";
        else if(month === 3) return "April";
        else if(month === 4) return "Mei";
        else if(month === 5) return "Juni";
        else if(month === 6) return "Juli";
        else if(month === 7) return "Augustus";
        else if(month === 8) return "Sepetember";
        else if(month === 9) return "Oktober";
        else if(month === 10) return "November";
        else if(month === 11) return "December";
    }

    function filterData(filterString) {
        const newData = JSON.parse( JSON.stringify(data) );
        const newDataPool = []
        newData.forEach(building => {
            if(building.title.toLowerCase().includes(filterString.toLowerCase())) {
                newDataPool.push(building)
            }
        })

        setDataPool(newDataPool)
    }

    const handleSearch = (event) => {
        setSearch(event.target.value)
        setSelectedBuilding(0)
        debouncedFilter(event.target.value)
    } 

    const debouncedFilter = debounce((value) => {
        filterData(value)
    }, 500);

    return(
        <Grid container>
            <Grid container>
                <Hidden xsDown>
                    <Header />
                </Hidden>
                <Hidden smUp>
                    <Header toShowHamburger />
                </Hidden>
            </Grid>
            <Hidden smUp>
                <MobileView />
            </Hidden>
            <Hidden xsDown>
                <Grid container item sm = {12}>
                    <div className = "timeline-container">
                        {data.length > 0 && data ? (
                            <Grid container item sm = {12}>
                                <Grid container item sm = {12}>
                                    <div className = "margin-top-25" />
                                </Grid>
                                <Grid container item sm = {12} alignItems = "center">
                                    <h1 className = "main-font-semi-bold font-size-18 font-color-black margin-disable">Timeline</h1>
                                    <div className = "timeline-search-container margin-left-15">
                                        <img src = {require('../../../../assets/svg/icon/glass_grey.svg')} alt = "glass grey" className = "timeline-search-icon margin-left-15" />
                                        <input className = "main-font-regular font-size-14 font-color-grey margin-left-15" placeholder = "Zoeken" value = {search} onChange = {handleSearch} />
                                    </div>
                                </Grid>
                                <Grid container item sm = {12}>
                                    <div className = "margin-top-25" />
                                </Grid>
                                <Grid container item sm = {12}>
                                    <div className = "timeline-planner-container flex-column">
                                        <div className = "flex-row width-auto">
                                            <div className = "timeline-planner-sidebar-month center-content">
                                                <div className = "flex-row vertical-center">
                                                    <button type = "button" className = "cursor-pointer" onClick = {previousWeek}>
                                                        <img src = {require('../../../../assets/svg/icon/down_arrow.svg')} className = "timeline-planner-sidebar-month-arrow-left" alt = "month arrow left" />
                                                    </button>
                                                    <div className = "timeline-planner-sidebar-month-text center-content">
                                                        <p className = "main-font-medium font-size-12 font-color-light-grey">{getStringMonth(weekStart.getMonth())} 2020</p>
                                                    </div>
                                                    <button type = "button" className = "cursor-pointer" onClick = {nextWeek}>
                                                        <img src = {require('../../../../assets/svg/icon/down_arrow.svg')} className = "timeline-planner-sidebar-month-arrow-right" alt = "month arrow right" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className = "timeline-planner-viewport-week">
                                                <h1 className = "main-font-semi-bold font-size-14 font-color-black">Ma. {new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getDate()}/{new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getMonth() + 1}</h1>
                                            </div>
                                            <div className = "timeline-planner-viewport-week timeline-planner-viewport-week-border">
                                                <h1 className = "main-font-semi-bold font-size-14 font-color-black">Di. {new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 1).getDate()}/{new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getMonth() + 1}</h1>
                                            </div>
                                            <div className = "timeline-planner-viewport-week timeline-planner-viewport-week-border">
                                                <h1 className = "main-font-semi-bold font-size-14 font-color-black">Wo. {new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 2).getDate()}/{new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getMonth() + 1}</h1>
                                            </div>
                                            <div className = "timeline-planner-viewport-week timeline-planner-viewport-week-border">
                                                <h1 className = "main-font-semi-bold font-size-14 font-color-black">Do. {new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 3).getDate()}/{new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getMonth() + 1}</h1>
                                            </div>
                                            <div className = "timeline-planner-viewport-week timeline-planner-viewport-week-border">
                                                <h1 className = "main-font-semi-bold font-size-14 font-color-black">Vr. {new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 4).getDate()}/{new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getMonth() + 1}</h1>
                                            </div>
                                            <div className = "timeline-planner-viewport-week timeline-planner-viewport-week-border">
                                                <h1 className = "main-font-semi-bold font-size-14 font-color-black">Za. {new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 5).getDate()}/{new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getMonth() + 1}</h1>
                                            </div>
                                            <div className = "timeline-planner-viewport-week timeline-planner-viewport-week-border">
                                                <h1 className = "main-font-semi-bold font-size-14 font-color-black">Zo. {new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 6).getDate()}/{new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate()).getMonth() + 1}</h1>
                                            </div>
                                        </div>
                                        <div className = "timeline-planner-container-sub">
                                            <div className = "flex-row width-auto">
                                                <div className = "timeline-planner-sidebar">
                                                    {dataPool.map((building, index) => (
                                                        <ServiceModal data = {dataPool} index = {index} selectedBuilding = {selectedBuilding} setSelectedBuilding = {setSelectedBuilding} getUpdatedData = {getUpdatedData} building = {building} />
                                                    ))}
                                                </div>
                                                <div className = "timeline-planner-viewport flex-column">
                                                    <div className = "width-auto height-auto flex-column relative">
                                                        {/* this generates the grid layout */}
                                                        {dataPool.map(() => (
                                                            <div className = "flex-row width-auto">
                                                                <div className = "timeline-planner-viewport-service-block">
                                                                
                                                                </div>
                                                                <div className = "timeline-planner-viewport-service-block timeline-planner-viewport-service-block-border">
                                                                
                                                                </div>
                                                                <div className = "timeline-planner-viewport-service-block timeline-planner-viewport-service-block-border">
                                                                
                                                                </div>
                                                                <div className = "timeline-planner-viewport-service-block timeline-planner-viewport-service-block-border">
                                                                
                                                                </div>
                                                                <div className = "timeline-planner-viewport-service-block timeline-planner-viewport-service-block-border">
                                                                
                                                                </div>
                                                                <div className = "timeline-planner-viewport-service-block timeline-planner-viewport-service-block-border">
                                                                
                                                                </div>
                                                                <div className = "timeline-planner-viewport-service-block timeline-planner-viewport-service-block-border">
                                                                
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {/* this is where we render the service cards, which is overlayed over the grid using position absolute */}
                                                        <div className = "height-auto width-auto absolute">
                                                            {cardData.map((building, buildingIndex) => (
                                                                <div className = "timeline-planner-viewport-card-row">
                                                                    {building.serviceData.map((services, serviceIndex) => (
                                                                        <div className = "timeline-planner-viewport-card-box" style = {{zIndex: parseInt(`${cardData.length - buildingIndex}${serviceIndex}`)}}>
                                                                            {services.services.map(service => {
                                                                                return <TimelineCard services = {services} service = {service} buildingName = {building.buildingName} />
                                                                            })}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        ) : (
                            <div className = "height-auto width-auto center-content">
                                <img src = {require('../../../../assets/svg/icon/loading.gif')} alt = "loader" className = "services-loader" />
                            </div>
                        )}
                    </div>
                </Grid>
            </Hidden>
        </Grid>
    )
}
