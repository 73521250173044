import React, { useState, useEffect, useRef } from 'react'
import { Dialog, Grid, Hidden, makeStyles } from '@material-ui/core'
import './_project_application_contactinfo.scss'
import axios from 'axios'
import { addClientCompanyForm, addClientContactForm } from '../../shared/sample_data';
import CheckIcon from '../../../assets/svg/icon_check_white.svg'
import TrashIcon from '../../../assets/png/icon/trash.png'
import UploadIcon from '../../../assets/png/icon/upload.png'
import Popup from '../../shared/popup/custom_popup.js';
import PropTypes from 'prop-types'
import PDFIcon from '../../../assets/svg/doc/pdf.svg';

export default function ProjectApplicationContactInfo(props) {
  let userDetails = JSON.parse(localStorage.getItem("loggedInUserDetails"));
  const { setStepNumber, selectedUser, setSelectedUser } = props

  const [ userList, setUserList ] = useState([])
  const [ dropdownState, setDropdownState ] = useState(false)
  const [updateUserList, setUserListState] = useState(false)
  const [addClientFormState, setAddClientFormState] = useState(false)
  const [addPrivatePersonFormState, setAddPrivatePersonFormState] = useState(false)
  const [showResponseState, setShowPopupState] = useState(false)
  const [responseMessageState, setResponseMessageState] = useState({})
  const [newUserCompanyId, setNewUserCompanyId] = useState(0)
  const token = localStorage.getItem('authToken')
  const emailRegex = /[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+/

  let screenWidth = window.screen.width

  useEffect(() => {
    setAddClientFormState(false)
    setAddPrivatePersonFormState(false)
    axios.post(process.env.REACT_APP_API_URL+'/user/all_users', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
      const list = response.data.filter(user => user.roleId < 3);
      setUserList(list)
      
    }).catch(error => {
    })
  }, [updateUserList])

  useEffect(() => {
    if(newUserCompanyId > 0){
      let selectedUser = userList.filter(user => user.companyId === newUserCompanyId)
      setSelectedUser(selectedUser[0])
    }
  }, [userList])
  
  const handleInfoSubmit = () => {
    setStepNumber(5)
  }
  
  return ( 
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className="margin-top-20"
    >
      {selectedUser === 0 ? (
        <>
          <p className="main-font-bold font-color-black font-size-14 width-auto">
            Contactpersoon
          </p>
          <div className = 'flex-row flex-wrap width-auto'>
            <div className = "project-application-contactinfo-dropdown-container cursor-pointer margin-right-30" onClick = {() => { setDropdownState(!dropdownState)}}>
              <div className = "project-application-contactinfo-dropdown-default horizontal-between vertical-center">
                <p className = "main-font-light font-size-14 font-color-grey">Select User</p>
                <img src = {require('../../../assets/svg/icon/arrow_down_grey.svg')} alt = "arrow down" className = "project-application-contactinfo-arrow" />
              </div>
              <div className = "project-application-contactinfo-dropdown animatable-400 ease-in-out-quart" style = {{height: dropdownState ? `${userList.length <= 6 ? userList.length * 40 : 240}px` : '0px', opacity: dropdownState ? 1 : 0}} >
                {userList.length > 0 && userList.map(item => (
                  <div className = "project-application-contactinfo-dropdown-item flex-column" onClick = {() => { setSelectedUser(item) }} >
                    <p className = "main-font-semi-bold font-size-14 font-color-black">{`${item.firstName} ${item.lastName}`}</p>
                    <p className = "main-font-light font-size-14 font-color-grey">{item.email}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className = {`margin-right-30 ${screenWidth < 600 ? 'width-auto' : 'width-9'}`} >
              <button type = 'button' className = 'selected-tab-button-style flex-row vertical-center margin-top-25' onClick = {()=>{setAddClientFormState(true)}} >
                <img alt = 'add' src = {require('../../../assets/svg/icon/Add.svg')} className = 'width-1 height-1' />
                <p className = 'width-auto center-text'>Nieuw bedrijf</p>
              </button>
            </div>
            <div className = {`margin-right-30 ${screenWidth < 600 ? 'width-auto' : 'width-12'}`} >
              <button type = 'button' className = 'selected-tab-button-style flex-row vertical-center margin-top-25' onClick = {()=>{setAddPrivatePersonFormState(true)}} >
                <img alt = 'add' src = {require('../../../assets/svg/icon/Add.svg')} className = 'width-1 height-1' />
                <p className = 'width-auto center-text'>Nieuw prive persoon</p>
              </button>
            </div>
          </div>
        </>
      ) : ( 
        <>
        <div className="project-application-service-size">
            <Grid item xs = {12} sm = {6} md = {4}>
              <div className = "flex-row width-auto horizontal-between">
                <span className="main-font-bold font-color-black font-size-14">
                  Contactpersoon
                </span>
                <img src = {require('../../../assets/svg/icon/cross.svg')} alt = "close" className = "project-application-contactinfo-dropdown-arrow cursor-pointer" onClick = {() => { setSelectedUser(0) }} />
              </div>
            </Grid>
            
          <Grid container item xs={12} sm = {6} md = {4} direction = "column">
            <span className="main-font-light font-size-14 font-color-grey margin-top-20">
              Voornaam
            </span>
            <p className= "margin-top-10 vertical-center main-font-medium font-size-14 font-color-black">{selectedUser.firstName}</p>
          </Grid>
          <Grid container item xs={12} sm = {6} md = {4} direction = "column">
            <span className="main-font-light font-size-14 font-color-grey margin-top-20">
              Achternaam
            </span>
            <p className= "margin-top-10 vertical-center main-font-medium font-size-14 font-color-black">{selectedUser.lastName}</p>
          </Grid>
          <Grid container item xs={12} sm = {6} md = {4} direction = "column">
            <span className="main-font-light font-size-14 font-color-grey margin-top-20">
              Email
            </span>
            <p className= "margin-top-10 vertical-center main-font-medium font-size-14 font-color-black">{selectedUser.email}</p>
          </Grid>
          <Grid container item xs={12} sm = {6} md = {4} direction = "column">
            <span className="main-font-light font-size-14 font-color-grey margin-top-20">
              Telefoon
            </span>
            <p className= "margin-top-10 vertical-center main-font-medium font-size-14 font-color-black">{selectedUser.phone}</p>
          </Grid>
          <Grid container item xs={12} sm = {6} md = {4} direction = "column">
            <span className="main-font-light font-size-14 font-color-grey margin-top-20">
              Beroep
            </span>
            <p className= "margin-top-10 vertical-center main-font-medium font-size-14 font-color-black">{selectedUser.profession}</p>
          </Grid>
        </div>
        <button
          onClick={handleInfoSubmit}
          type="button"
          className={`project-application-billinfo-submit vietnam-semibold cursor-pointer project-application-submit`}
        >
          Volgende
          <img
            alt = 'arrow_right'
            className="width-1 height-1"
            src={require('../../../assets/svg/icon/arrow_right.svg')}
          />
        </button>
        </>
      )}
      <AddClient popupState={addClientFormState} setUserListState = {setUserListState} updateUserList = {updateUserList} setAddClientFormState = {setAddClientFormState} showResponseState = {showResponseState} setShowResponseState = {setShowPopupState} responseMessageState={responseMessageState} setResponseMessageState = {setResponseMessageState} setSelectedUser = {setSelectedUser} setNewUserCompanyId = {setNewUserCompanyId} /> 
      <AddPrivatePerson popupState={addPrivatePersonFormState} setUserListState = {setUserListState} updateUserList = {updateUserList} setAddPrivatePersonFormState = {setAddPrivatePersonFormState} showResponseState = {showResponseState} setShowResponseState = {setShowPopupState} responseMessageState={responseMessageState} setResponseMessageState = {setResponseMessageState} setSelectedUser = {setSelectedUser} setNewUserCompanyId = {setNewUserCompanyId} /> 
      {
          showResponseState === true ?
          <Popup data={responseMessageState}/>
          : null
      }
    </Grid>
  )
}

const useStyles = makeStyles({
  paper: {
    width: '80%',
    maxHeight: '90%',
    borderRadius: '10px',
  },
})

const AddClient = (props) => {
const {popupState, setUserListState, updateUserList, setAddClientFormState ,setShowResponseState, setResponseMessageState, setNewUserCompanyId } = props
const classes = useStyles()
const {setCompanyId, setContactId, companyId, setShowService } = props
  const [ animateState, setAnimateState] = useState(false)
  const [ companyFormState, setCompanyFormState ] = useState(JSON.parse(JSON.stringify(addClientCompanyForm)))
  const [ contactFormState, setContactFormState ] = useState(JSON.parse(JSON.stringify(addClientContactForm)))
  const [companyImageState, setCompanyImageState] = useState(null)
  const [companyImageName, setCompanyImageName] = useState(null)

  // company form state vars 
  const [ companyName, setCompanyName ] = useState("");
  const [ companyBtw, setCompanyBtw ] = useState("");
  const [ companyAddress, setCompanyAddress ] = useState("")
  const [ companyNr, setCompanyNr ] = useState("")
  const [ companyBus, setCompanyBus ] = useState("")
  const [ companyZip, setCompanyZip ] = useState("")
  const [ companyCity, setCompanyCity ] = useState("")
  const [ companyEmail, setCompanyEmail ] = useState("")
  const [ companyTelephone, setCompanyTelephone ] = useState("")
  const [ companyBic, setCompanyBic ] = useState("")
  const [ companyIban, setCompanyIban ] = useState("")

  const [ companyNameError, setCompanyNameError ] = useState(false);
  const [ companyBtwError, setCompanyBtwError ] = useState(false);
  const [ companyAddressError, setCompanyAddressError ] = useState(false)
  const [ companyNrError, setCompanyNrError ] = useState(false)
  const [ companyBusError, setCompanyBusError ] = useState(false)
  const [ companyZipError, setCompanyZipError ] = useState(false)
  const [ companyCityError, setCompanyCityError ] = useState(false)
  const [ companyEmailError, setCompanyEmailError ] = useState(false)
  const [ companyTelephoneError, setCompanyTelephoneError ] = useState(false)
  const [ companyBicError, setCompanyBicError ] = useState(false)
  const [ companyIbanError, setCompanyIbanError ] = useState(false)
  const [ submitButtonClick, setSubmitButtonClick] = useState(false)
  const [adminFile,setAdminFile]=useState(null)
  const [ fileUploadProgress, setFileUploadProgress ] = useState(0)

  const adminImageRef = useRef(null)
  const imageInputRef = useRef(null)

  const emailRegex = /[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+/
  const token = localStorage.getItem('authToken')

  const handleContactInputChange = (item, event) => {
    const prevContactState = [...contactFormState]
    const currentObj = prevContactState.find(a => a.id === item.id)
    if (currentObj !== undefined) {
        if (event !== undefined) {
            const eventValue = event.target.value
            if (eventValue.trim() === ''){
              currentObj.errorValue = 'Field required'
            }
            else {
                if(currentObj.type === 'email') {
                    if (emailRegex.test(eventValue) === true) {
                      currentObj.errorValue = ''
                    }
                    else{
                      currentObj.errorValue = 'Email required'
                    }
                }
                else if(currentObj.id === 4) {
                  if(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm.test(event.target.value)){
                    currentObj.errorValue = ''
                  }
                  else {
                    currentObj.errorValue = 'number incorrect'
                  }
                }
                else if (currentObj.type === 'password') {
                  if (eventValue.trim().length < 8)  {
                    currentObj.errorValue = 'Password must be 8 digits long'
                  }
                  else {
                    currentObj.errorValue = ''
                  }
                }
                else if (currentObj.type === 'select') {
                  if (eventValue === '5') {
                    setShowService(true)
                  }
                  else {
                    setShowService(false)
                  }
                  currentObj.errorValue = ''
                }
                else {
                  currentObj.errorValue = ''
                }
            }
            currentObj.value = eventValue
        }
        else {
            currentObj.value = ''
        }
        setContactFormState(prevContactState)
    }
  }

  const handleSubmitClick = () => {
    const formValid = validateForms()
    const prevContactFormState = [...contactFormState]
    let contactInfo = {}
    let isRoleValid = false
    if (formValid === true && isCompanyFieldsValid()) {
      let contactFormFilled = false
      prevContactFormState.some(item => {
        if (item.name === 'contactId') {
          contactInfo.id = (item.value !== null && item.value !== undefined) ? parseInt(item.value) : 0
        }
        else if (item.name === 'contactName') {
          contactInfo.lastName = item.value
        }
        else if(item.name === 'contactFirstName') {
          contactInfo.firstName = item.value
        }
        else if(item.name === 'contactPhone') {
          contactInfo.phone = item.value
        }
        else if(item.name === 'contactProfession') {
          contactInfo.profession = item.value
        }
        else if(item.name === 'contactEmail') {
          contactInfo.email = item.value
        }
        else if(item.name === 'contactPassword') {
          contactInfo.password = item.value
        }
        else if(item.name === 'userRole') {
          if(item.value < 3){
            contactInfo.roleId = (item.value !== null && item.value !== undefined ) ? parseInt(item.value) : 10
            isRoleValid = true
          }
          else{
            item.errorValue = 'gebruiker kan Bouwen & verbouwen of Verkopen & verhuren'
            isRoleValid = false
          }
        }
        if (item.value !== '' && item.value !== '0') {
          contactFormFilled = true
        }
      })
      
      if(isRoleValid){
        let data = {
          companyInfo: {
            id: typeof companyId === "number" ? companyId : 0,
            name: companyName,
            BTW: companyBtw,
            companyAddress: companyAddress,
            no: companyNr,
            bus: companyBus,
            postalCode: companyZip,
            city: companyCity,
            generalEmail: companyEmail,
            generalTelephone: companyTelephone,
            profile_picture: companyImageName,
            bic: companyBic,
            iban: companyIban,
            private: false,
          },
          contactInfo: contactFormFilled === true ? contactInfo : null
        }
      setSubmitButtonClick(true)
        axios.post(
            process.env.REACT_APP_API_URL+`/admin/company/add`,
            data,
            { headers: { authorization: `bearer ${token}` } },
          )
          .then((response) => {
            const data = response.data
            let responseMessage = {
              success: true,
              heading: '',
              message: ''
            }
            setSubmitButtonClick(false)
            if (data !== undefined) {
              if (data.companyId > 0) {
                const companyIdControl = prevContactFormState.find(a => a.name === 'companyId')
                if (companyIdControl !== undefined && companyIdControl !== null) {
                  companyIdControl.value = data.companyId
                }
                setCompanyId(data.companyId)
                setNewUserCompanyId(data.companyId)
              }
              if (data.message !== null && data.message !== undefined) {
                if (contactFormFilled === true) {
                  if (data.message.userId > 0) {
                    const contactIdControl = prevContactFormState.find(a => a.name === 'contactId')
                    if (contactIdControl !== undefined && contactIdControl !== null) {
                      contactIdControl.value = data.message.userId
                    }
                    setShowResponseState(true)
                    responseMessage = {
                      isSuccess: true,
                      heading: 'Record Saved',
                      message: 'Company and contact person added successfully',
                      popupState: setShowResponseState
                    }
                    setResponseMessageState(responseMessage)
                    setContactId(data.message.userId)
                    setContactFormState(prevContactFormState)
                    setUserListState(!updateUserList)
                  }
                  else {
                    setShowResponseState(true)
                    responseMessage = {
                      isSuccess: false,
                      heading: 'Record Not Saved',
                      message: data.message.message,
                      popupState: setShowResponseState
                    }
                    setResponseMessageState(responseMessage)
                  }
                }
                else {
                  setShowResponseState(true)
                  responseMessage = {
                    isSuccess: true,
                    heading: 'Record Saved',
                    message: 'Company added successfully',
                    popupState: setShowResponseState
                  }
                  setUserListState(!updateUserList)
                  setResponseMessageState(responseMessage)
                }
              }
            }          
          })
          .catch((error) => {
            setSubmitButtonClick(false)
            setShowResponseState(true)
            let responseMessage = {
              isSuccess: false,
              heading: 'Record Not Saved',
              message: error.response.data.message ? error.response.data.message : 'Something wents wrong',
              popupState: setShowResponseState
            }
            setResponseMessageState(responseMessage)
          })
      }
    }
    setAnimateState(true)
    setTimeout(() => {
      setAnimateState(false)
    }, 2000)
  }

  const validateForms = () => {
    const prevContactState = [...contactFormState]
    let isValid = true
    const filledContactFields = prevContactState.filter(a => (a.value !== null) ?
                                  (a.value.toString().trim() !== '' && a.value.toString().trim() !== '0')
                                  : false)
    if (filledContactFields.length > 0) {
      const emptyContactFields = prevContactState.filter(a => a.value !== null ?
                                    a.value.toString().trim() === '' : true)
      if (emptyContactFields !== undefined) {
          emptyContactFields.forEach(item => {
            isValid = false
              item.errorValue = 'Field required'
          })
      }
    }
    else {
      prevContactState.forEach(item => {
        item.errorValue = ''
      })
    }
    const errorContactFields = prevContactState.filter(a => a.errorValue.length > 0)
    if (errorContactFields.length > 0) {
      isValid = false
    }
    setContactFormState(prevContactState)
    return isValid
  }

  const handleUploadImageClick = () => {
    if(imageInputRef.current !== undefined) {
      imageInputRef.current.click()
    }
  }
  const handleImageUploadChange = (event) => {
    if (event !== undefined) {
        const file = URL.createObjectURL(event.target.files[0])
      setCompanyImageState(file)
      callUploadApi(event.target.files[0])
    }
  }

const callUploadApi=(fileName)=>{
  const formData = new FormData()

  formData.append('imageToUpload',fileName)
  axios
  .post(
      process.env.REACT_APP_API_URL+`/user/new_user_image`,
      formData,
      {
          headers: {
              authorization: `bearer ${token}`,
          },
      },
  )
  .then((response) => {
      if (response.data.success === true) {
          let filename = response.data.filename
          setCompanyImageName(filename)
      }
  })
  .catch((error) => { })
}

  const handleDeleteImageClick = () => {
    if(imageInputRef.current !== undefined) {
      imageInputRef.current.value = ''
    }
    setCompanyImageState(null)
  }

  const handleCompanyName = (event) => {
    companyNameError && setCompanyNameError(false)
    setCompanyName(event.target.value)
  }

  const handleCompanyBtw = (event) => {
    companyBtwError && setCompanyBtwError(false)
    setCompanyBtw(event.target.value)
  }

  const handleCompanyAddress = (event) => {
    companyAddressError && setCompanyAddressError(false)
    setCompanyAddress(event.target.value)
  }

  const handleCompanyNr = (event) => {
    companyNrError && setCompanyNrError(false)
    setCompanyNr(event.target.value)
  }

  const handleCompanyBus = (event) => {
    if(event.target.validity.valid) {
      companyBusError && setCompanyBusError(false)
    }
    else {
      !companyBusError && setCompanyBusError(true)
    }
    setCompanyBus(event.target.value)
  }

  const handleCompanyZip = (event) => {
    if(event.target.validity.valid) {
      companyZipError && setCompanyZipError(false)
    }
    else {
      !companyZipError && setCompanyZipError(true)
    }
    setCompanyZip(event.target.value);
  }

  const handleCompanyCity = (event) => {
    companyCityError && setCompanyNameError(false)
    setCompanyCity(event.target.value)
  }

  const handleCompanyEmail = (event) => {
    if(event.target.validity.valid) {
      companyEmailError && setCompanyEmailError(false)
    }
    else {
      !companyEmailError && setCompanyEmailError(true)
    }
    setCompanyEmail(event.target.value)
  }

  const handleCompanyTelephone = (event) => {
    if(event.target.validity.valid) {
      companyTelephoneError && setCompanyTelephoneError(false)
    }
    else {
      !companyTelephoneError && setCompanyTelephoneError(true)
    }
    setCompanyTelephone(event.target.value)
  }

  const handleCompanyBic = (event) => {
    companyBicError && setCompanyBicError(false)
    setCompanyBic(event.target.value)
  }

  const handleCompanyIban = event => {
    companyIbanError && setCompanyIbanError(false)
    setCompanyIban(event.target.value)
  }

  function isCompanyFieldsValid() {
    // check to see if the fields are not empty and for the fields that have regex, to see if they have valid content
    let isValid = true;
    if(companyName === "") {
      isValid = false;
      setCompanyNameError(true);
    }
    if(companyBtw === "") {
      isValid = false;
      setCompanyBtwError(true);
    }
    if(companyAddress === "" || companyAddressError) {
      isValid = false;
      setCompanyAddressError(true)
    }
    if(companyNr === "") {
      isValid = false;
      setCompanyNrError(true)
    }
    if(companyZip === "" || companyZipError) {
      isValid = false;
      setCompanyZipError(true)
    }
    if(companyCity === "" ) {
      isValid = false;
      setCompanyCityError(true)
    }
    if(companyEmail === "" || companyEmailError) {
      isValid = false;
      setCompanyEmailError(true)
    }
    if(companyTelephone === "" || companyTelephoneError) {
      isValid = false;
      setCompanyTelephoneError(true)
    }
    if(parseInt(contactFormState[contactFormState.length - 1].value) === 4) {
      if(companyBic === "") {
        isValid = false;
        setCompanyBicError(true)
      }
      if(companyIban === "") {
        isValid = false;
        setCompanyIbanError(true)
      }
    }
    return isValid;
  }

  const handleAdminFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files
    if (files !== null && files !== undefined) {
        if(files.length > 0) {
            if (setAdminFile !== null && setAdminFile !== undefined) {
                if (files[0] !== null && files[0] !== undefined) {
                    let formData = new FormData()
                    formData.append('company_id', companyId)
                    formData.append('docsInput', files[0])
                    formData.append('file_name', files[0].name)
                    axios.post(
                        process.env.REACT_APP_API_URL+`/admin/company/upload_document`,
                        formData,
                        { 
                          headers: { 
                            authorization: `bearer ${token}` 
                          },
                          onUploadProgress: progress => {
                            const { total, loaded } = progress;
                            const totalSize = total/1000000;
                            const totalLoaded = loaded/1000000;
                            const percentage = (totalLoaded/totalSize) * 100;
                            setFileUploadProgress(percentage.toFixed(2));
                          },
                          
                        },
                    )
                    .then((response) => {
                      setFileUploadProgress(0)
                        const responseData = response.data
                        if (responseData !== null && responseData !== undefined) {
                            if (responseData.success === true) {
                                setAdminFile(files[0])
                            }
                            else {
                                setAdminFile(null)
                            }
                        }
                        else {
                            setAdminFile(null)
                        }
                      })
                      .catch((error) => {
                        // let responseMessage = {
                        //     isSuccess: false,
                        //     heading: 'Record not saved',
                        //     message: 'please fill form first',
                        //     popupState: setShowPopupState
                        //   }
                        // setShowPopupState(true)
                        // setPopupMessageState(responseMessage)
                        setAdminFile(null)
                    })
                }
                else {
                    setAdminFile(null)
                }
            }
        }
    }
}

const handleDeleteAdminImageClick = () => {
  axios.post(process.env.REACT_APP_API_URL+'/admin/company/delete_document' , {
    company_id: companyId,
  }, {
    headers: {
      authorization: `bearer ${token}`
    }
  }).then(response => {
  }).catch(error => {
  })
  setAdminFile({...adminFile,name:null})
}

  return(
    <Dialog
      open={popupState}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      scroll="paper"
      classes={{ paper: classes.paper }}
    >
      <Grid container className = 'height-auto'>
        <div className="add-client-main-div">
        <Grid container>
          <Grid container item xs={12} sm={12} md={6} lg={5}>
            <Grid container>
              <div className = 'width-auto flex-row vertical-center horizontal-between'>
                <span className="main-font-semi-bold font-color-black font-size-18">
                  Bedrijf
                </span>
                <Hidden lgUp>
                  <img
                    alt = 'cross'
                    className="profile-cross-icon cursor-pointer"
                    src={require('../../../assets/svg/icon/cross.svg')}
                    onClick={() => {setAddClientFormState(false)}}
                  />
                </Hidden>
              </div>
            </Grid>
            <Grid container className="margin-top-20">
              <Grid item xs={6}>
                {companyImageState ? (
                  <div className="add-client-img-div">
                    <img src = {companyImageState} alt="" className="add-client-logo-img"/>
                  </div>
                ) : (
                  <div className="add-client-img-div center-content" style = {{border: 'dotted 2px #9B9B9B'}}>
                    <p className = "main-font-semi-bold font-size-14 font-color-light-grey">Company logo here</p>
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <div className="add-client-div-icon">
                  <input type="file" className="cursor-pointer add-client-display-none" ref={imageInputRef}
                    onChange={handleImageUploadChange} accept=".png,.svg,.jpg,.jpeg"/>
                  <button className="cursor-pointer" onClick={handleUploadImageClick}>
                    <img src={UploadIcon} className="add-client-icon-button" alt="Upload"/>
                  </button>
                  <button className="cursor-pointer" onClick={handleDeleteImageClick}>
                    <img src={TrashIcon} className="add-client-icon-button" alt="Delete"/>
                  </button>
                </div>
              </Grid>
            </Grid>
            <Grid container item>
              <div className = "flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-10">Bedrijf</span>
                <input placeholder="" value = {companyName} className= "input-custom-control margin-top-10" onChange = {handleCompanyName} style = {{border: companyNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
              </div>
              <div className = "flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-10">BTW</span>
                <input placeholder="" value = {companyBtw} className= "input-custom-control margin-top-10" onChange = {handleCompanyBtw} style = {{border: companyBtwError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
              </div>
              <div className = "flex-row width-auto horizontal-between">
                <div className = "flex-column width-60-p">
                  <span className="main-font-regular font-color-grey margin-top-10">Adres</span>
                  <input placeholder="" value = {companyAddress} className= "input-custom-control margin-top-10" onChange = {handleCompanyAddress} style = {{border: companyAddressError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-19-p">
                  <span className="main-font-regular font-color-grey margin-top-10">Nr</span>
                  <input placeholder="" value = {companyNr} className= "input-custom-control margin-top-10" onChange = {handleCompanyNr} style = {{border: companyNrError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-19-p">
                  <span className="main-font-regular font-color-grey margin-top-10">Bus</span>
                  <input placeholder="" value = {companyBus} className= "input-custom-control margin-top-10" onChange = {handleCompanyBus} style = {{border: companyBusError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
              </div>
              <div className = "flex-row width-auto horizontal-between">
                <div className = "flex-column width-29-p">
                  <span className="main-font-regular font-color-grey margin-top-10">Postcode</span>
                  <input placeholder="" value = {companyZip} className= "input-custom-control margin-top-10" pattern = "[0-9]+" onChange = {handleCompanyZip} autoComplete = "new-password" style = {{border: companyZipError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-69-p">
                  <span className="main-font-regular font-color-grey margin-top-10">Gemeente</span>
                  <input placeholder="" value = {companyCity} className= "input-custom-control margin-top-10"  onChange = {handleCompanyCity} style = {{border: companyCityError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
              </div>
              <div className = "flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-10">Kantoor e-mail</span>
                <input placeholder="" value = {companyEmail} className= "input-custom-control margin-top-10" pattern = "[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+" autoComplete = "new-password" onChange = {handleCompanyEmail} style = {{border: companyEmailError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
              </div>
              <div className = "flex-column width-auto">
                <span className="main-font-regular font-color-grey margin-top-10">Kantoor tel.</span>
                <input placeholder="" value = {companyTelephone} className= "input-custom-control margin-top-10" pattern = "^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$" autoComplete = "new-password" onChange = {handleCompanyTelephone} style = {{border: companyTelephoneError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
              </div>
              {contactFormState.length > 0 && parseInt(contactFormState[contactFormState.length - 1].value) === 4 && (
              <Grid>
                <div className = "flex-row width-auto">
                  <div className = "flex-column width-auto margin-right-10">
                    <span className="main-font-regular font-color-grey margin-top-10">BIC</span>
                    <input placeholder="" value = {companyBic} className= "input-custom-control margin-top-10" autoComplete = "new-password" onChange = {handleCompanyBic} style = {{border: companyBicError ? 'solid 1px red' : 'solid 1px #DADADA'}}  />
                  </div>
                  <div className = "flex-column width-auto">
                    <span className="main-font-regular font-color-grey margin-top-10">IBAN</span>
                    <input placeholder="" value = {companyIban} className= "input-custom-control margin-top-10" autoComplete = "new-password" onChange = {handleCompanyIban} style = {{border: companyIbanError ? 'solid 1px red' : 'solid 1px #DADADA'}}  />
                  </div>
                </div>
                {companyId>0 &&
                  <Grid container className="margin-top-30" alignItems = "center" wrap = "nowrap" >
                    <div className = "flex-row">
                      <input type="file" ref={adminImageRef} className="ventilation-service-file-input" accept="application/pdf"
                          onChange={(event) => handleAdminFileChange(event)}/>
                      {adminFile!==null && adminFile!==undefined && adminFile.name!==null ? 
                        <button className="cursor-pointer" onClick={handleDeleteAdminImageClick}>
                          <img src={TrashIcon} className="add-client-icon-button" alt="Delete"/>
                        </button>
                      :
                        <button className="cursor-pointer"
                          onClick={() => adminImageRef.current.click()}>
                          <img src={UploadIcon} alt="" className="add-client-icon-button"/>
                        </button>
                      }</div>                         
                    {adminFile !== null && adminFile !== undefined && adminFile.name !== null ?
                      <div className = "vertical-center margin-left-15">
                          <img className="ventilation-service-button-icon" alt=""
                              src={ PDFIcon }
                          />
                          <span className="main-font-semi-bold font-color-black font-size-14 margin-left-5 margin-right-5 width-p-50">
                              {adminFile.name}
                          </span>
                      </div>
                    : null
                    }
                    {fileUploadProgress !== 0 && (
                      <div class="lds-roller margin-left-15">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        <span className = "height-auto width-auto center-content absolute">
                          <p className = "main-font-semi-bold font-size-12 font-color-blue">{fileUploadProgress}%</p>
                        </span>
                      </div>
                    )}
                  </Grid>
                }
            </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid container item xs={12} sm={12} md={6} lg={5}>
            <Grid container className="margin-bottom-25">
              <div className = 'width-auto flex-row vertical-center horizontal-between'>
                <span className="main-font-semi-bold font-color-black font-size-18">
                  Contactpersoon 1
                </span>
                <Hidden mdDown>
                  <img
                    alt = 'cross'
                    className="profile-cross-icon cursor-pointer"
                    src={require('../../../assets/svg/icon/cross.svg')}
                    onClick={() => {setAddClientFormState(false)}}
                  />
                </Hidden>
              </div>
            </Grid>
          {
                contactFormState !== null ?
                contactFormState.map(item => {
                    return(
                      item.id > 1 ?
                        item.type === 'select' ?
                        <Grid container key={item.id} className="font-size-14">
                            <span className="main-font-regular font-color-grey margin-top-10">
                            {item.label}
                            {(item.errorValue.length > 0 && animateState === true) ? (
                                <span className="font-color-red">*</span>
                            ) : null}
                            </span>
                            <select
                            className={`input-custom-control margin-top-10 
                                                    ${
                                                      (item.errorValue.length > 0 && animateState === true)
                                                        ? 'input-error-border'
                                                        : ''
                                                    } `}
                            value={item.value}
                            onChange={(event) => handleContactInputChange(item, event)}
                            >
                              <option selected disabled hidden value=''></option>
                                {item.options.map(option => {
                                    return(
                                        <option key={option.id} value={option.id}>
                                            {option.title}
                                        </option>
                                    )
                                })}
                            </select>
                            {item.errorValue.length > 0 && animateState === true ? (
                                <span
                                className= 'font-color-red font-size-14 margin-left-10 margin-top-5'
                                >
                                {item.errorValue}
                                </span>
                            ) : null}
                    </Grid>
                    :
                        <Grid container key={item.id} className="font-size-14">
                            <span className="main-font-regular font-color-grey margin-top-10">
                            {item.label}
                            {(item.errorValue.length > 0 && animateState === true) ? (
                                <span className="font-color-red">*</span>
                            ) : null}
                            </span>
                            <input
                              placeholder={item.placeholder}
                              type={item.type}
                              value={item.value}
                              pattern = {item.pattern && item.pattern}
                              onChange={(event) => handleContactInputChange(item, event)}
                              className={`input-custom-control margin-top-10 
                                                    ${
                                                        (item.errorValue.length > 0 && animateState === true)
                                                        ? 'input-error-border'
                                                        : ''
                                                    } `}
                            />
                            {item.errorValue.length > 0 && animateState === true ? (
                                <span
                                className= 'font-color-red font-size-14 margin-left-10 margin-top-5'
                                >
                                {item.errorValue}
                                </span>
                            ) : null}
                        </Grid>
                      :
                      null
                    )
                })
                :
                null
            }
          </Grid>
        </Grid>
        <Grid container className="margin-top-20">
          <Grid item xs={12}>
            <div className="add-client-div-save font-size-14">
              <button className="add-client-button-save" disabled={submitButtonClick === true ? true:false } onClick={handleSubmitClick}>
                <img src={CheckIcon} className="add-client-check-icon" alt=""/>
                  Save
                </button>
            </div>
          </Grid>
        </Grid>
        
      </div>
      </Grid>
    </Dialog>
)
}

const AddPrivatePerson = (props) => {
  const {popupState, updateUserList, setUserListState, setAddPrivatePersonFormState, setShowResponseState, setResponseMessageState, setNewUserCompanyId  } = props
  const classes = useStyles()

    const [ contactName, setContactName ] = useState("")
    const [ contactLastName, setContactLastName ] = useState("")
    const [ contactEmail, setContactEmail ] = useState("")
    const [ contactTelephone, setContactTelephone ] = useState("")
    const [ contactProfession, setContactProfession ] = useState("")
    const [ contactPassword, setContactPassword ] = useState("")
    const [ contactRole, setContactRole ] = useState(2)

    // we keep this data as company since this isn't actually stored in the user table
    const [ companyAddress, setCompanyAddress ] = useState("")
    const [ companyNr, setCompanyNr ] = useState("")
    const [ companyBus, setCompanyBus ] = useState("")
    const [ companyZip, setCompanyZip ] = useState("")
    const [ companyCity, setCompanyCity ] = useState("")

    // contact form error state vars
    const [ createdContactId, setCreatedContactId ] = useState(0)
    const [ contactNameError, setContactNameError ] = useState(false)
    const [ contactLastNameError, setContactLastNameError ] = useState(false)
    const [ contactEmailError, setContactEmailError ] = useState(false)
    const [ contactProfessionError, setContactProfessionError ] = useState(false)
    const [ contactTelephoneError, setContactTelephoneError ] = useState(false)
    const [ contactPasswordError, setContactPasswordError ] = useState(false)

    const [ companyAddressError, setCompanyAddressError ] = useState(false)
    const [ companyNrError, setCompanyNrError ] = useState(false)
    const [ companyZipError, setCompanyZipError ] = useState(false)
    const [ companyCityError, setCompanyCityError ] = useState(false)

    const token = localStorage.getItem('authToken')

    const handleSubmitClick = () => {
        const validate = isFieldsValid();
        if (validate) {
            let apiData = {
                companyInfo: {
                    id: 0,
                    name: contactName + contactLastName,
                    companyAddress: companyAddress,
                    BTW: '-',
                    no: companyNr,
                    bus: companyBus,
                    postalCode: companyZip,
                    city: companyCity,
                    generalEmail: contactEmail,
                    generalTelephone: contactTelephone,
                    private: true,
                },
                contactInfo: {
                    id: createdContactId !== 0 ? createdContactId : 0,
                    companyId: 0,
                    firstName: contactName,
                    lastName: contactLastName,
                    phone: contactTelephone,
                    profession: contactProfession,
                    email: contactEmail,
                    password: contactPassword,
                    roleId: contactRole
                }
            }
            axios.post(
              process.env.REACT_APP_API_URL+`/admin/company/add`,
            apiData,
              { headers: { authorization: `bearer ${token}` } },
            )
            .then((response) => {
              const data = response.data
              let responseMessage = {
                success: true,
                heading: '',
                message: ''
              }
              if (data !== undefined) {
                if (data.message !== null && data.message !== undefined) {
                    if (data.message.userId > 0) {
                        setShowResponseState(true)
                        responseMessage = {
                            isSuccess: true,
                            heading: 'Record Saved',
                            message: 'Company added successfully',
                            popupState: setShowResponseState
                        }
                        setResponseMessageState(responseMessage)
                        setCreatedContactId(data.message.userId)
                        setNewUserCompanyId(data.companyId)
                        setUserListState(!updateUserList)
                    }
                    else {
                        setShowResponseState(true)
                      responseMessage = {
                        isSuccess: false,
                        heading: 'Record Not Saved',
                        message: data.message.message,
                        popupState: setShowResponseState
                      }
                      setResponseMessageState(responseMessage)
                    }
                  }
                  else {
                    setShowResponseState(true)
                    responseMessage = {
                      isSuccess: true,
                      heading: 'Record Saved',
                      message: 'Company added successfully',
                      popupState: setShowResponseState
                    }
                    setUserListState(!updateUserList)
                    setNewUserCompanyId(data.companyId)
                    setResponseMessageState(responseMessage)
                  }
                }
            })
            .catch((error) => {
                setShowResponseState(true)
                let responseMessage = {
                isSuccess: false,
                heading: 'Record Not Saved',
                message: error.response.data.message ? error.response.data.message : 'Something wents wrong',
                popupState: setShowResponseState
              }
              setResponseMessageState(responseMessage)
            })
        }
    }

    const handleContactName = (event) => {
        contactNameError && setContactNameError(false)
        setContactName(event.target.value)
    }

    const handleContactLastName = (event) => {
        contactLastNameError && setContactLastNameError(false)
        setContactLastName(event.target.value)
    }

    const handleContactTelephone = (event) => {
        if(event.target.validity.valid) {
            contactTelephoneError && setContactTelephoneError(false)
        }
        else {
            !contactTelephoneError && setContactTelephoneError(true)
        }
        setContactTelephone(event.target.value)
    }

    const handleContactProfession = (event) => {
        contactProfessionError && setContactProfessionError(false)
        setContactProfession(event.target.value)
    }

    const handleContactEmail = (event) => {
        if(event.target.validity.valid) {
            contactEmailError && setContactEmailError(false)
        }
        else {
            !contactEmailError && setContactEmailError(true)
        }
        setContactEmail(event.target.value)
    }

    const handleContactPassword = (event) => {
        contactPasswordError && setContactPasswordError(false)
        setContactPassword(event.target.value)
    }

    const handleContactRole = (event) => {
        setContactRole(event.target.value)
    }

    const handleCompanyAddress = (event) => {
        companyAddressError && setCompanyAddressError(false)
        setCompanyAddress(event.target.value)
      }
    
      const handleCompanyNr = (event) => {
        companyNrError && setCompanyNrError(false)
        setCompanyNr(event.target.value)
      }
    
      const handleCompanyBus = (event) => {
        setCompanyBus(event.target.value)
      }
    
      const handleCompanyZip = (event) => {
        if(event.target.validity.valid) {
          companyZipError && setCompanyZipError(false)
        }
        else {
          !companyZipError && setCompanyZipError(true)
        }
        setCompanyZip(event.target.value);
      }
    
      const handleCompanyCity = (event) => {
        companyCityError && setCompanyCityError(false)
        setCompanyCity(event.target.value)
      }

    function isFieldsValid() {
        let isValid = true;
        if(contactName === "") {
            isValid = false;
            setContactNameError(true)
        }
        if(contactLastName === "") {
            isValid = false;
            setContactLastNameError(true)
        }
        if(contactTelephone === "" || contactTelephoneError) {
            isValid = false;
            setContactTelephoneError(true)
        }
        if(contactProfession === "") {
            isValid = false;
            setContactProfessionError(true)
        }
        if(contactEmail === "" || contactEmailError) {
            isValid = false;
            setContactEmailError(true)
        }
        if(contactPassword === "" || contactPassword.length < 8) {
            isValid = false;
            setContactPasswordError(true)
        }
        if(companyAddress === "") {
            isValid = false;
            setCompanyAddressError(true)
        }
        if(companyNr === "") {
            isValid = false;
            setCompanyNrError(true)
        }
        if(companyZip === "" || companyZipError) {
            isValid = false;
            setCompanyZipError(true)
        }
        if(companyCity === "" || companyCityError) {
            isValid = false;
            setCompanyCityError(true)
        }

        return isValid;
    }

  return(
    <Dialog
      open={popupState}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      scroll="paper"
      classes={{ paper: classes.paper }}
    >
      <Grid container>
            <div className="private-client-main-div">
            <Grid container justify = 'space-between' alignItems = 'center'>
                <span className="main-font-semi-bold font-color-black font-size-18">
                  Prive persoon
                </span>
                <img
                  alt = 'cross'
                  className="profile-cross-icon cursor-pointer"
                  src={require('../../../assets/svg/icon/cross.svg')}
                  onClick={() => {setAddPrivatePersonFormState(false)}}
                />
            </Grid>
            <Grid container>
                <div className = "flex-row width-auto margin-top-30" />
                <div className = "flex-column width-auto margin-top-15">
                    <p className = "main-font-light font-size-14 font-color-grey">Naam</p>
                    <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactLastName} onChange = {handleContactLastName} style = {{border: contactLastNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto">
                    <p className = "main-font-light font-size-14 font-color-grey">Voornaam</p>
                    <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactName} onChange = {handleContactName} style = {{border: contactNameError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-row width-auto horizontal-between">
                    <div className = "flex-column width-60-p">
                        <span className="main-font-regular font-color-grey margin-top-10">Adres</span>
                        <input placeholder="" value = {companyAddress} className= "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" onChange = {handleCompanyAddress} style = {{border: companyAddressError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                    </div>
                    <div className = "flex-column width-19-p">
                        <span className="main-font-regular font-color-grey margin-top-10">Nr</span>
                        <input placeholder="" value = {companyNr} className= "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" onChange = {handleCompanyNr} style = {{border: companyNrError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                    </div>
                    <div className = "flex-column width-19-p">
                        <span className="main-font-regular font-color-grey margin-top-10">Bus</span>
                        <input placeholder="" value = {companyBus} className= "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" onChange = {handleCompanyBus} />
                    </div>
                </div>
                <div className = "flex-row width-auto horizontal-between">
                    <div className = "flex-column width-29-p">
                        <span className="main-font-regular font-color-grey margin-top-10">Postcode</span>
                        <input placeholder="" value = {companyZip} className= "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" pattern = "[0-9]+" onChange = {handleCompanyZip} autoComplete = "new-password" style = {{border: companyZipError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                    </div>
                    <div className = "flex-column width-69-p">
                        <span className="main-font-regular font-color-grey margin-top-10">Gemeente</span>
                        <input placeholder="" value = {companyCity} className= "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black"  onChange = {handleCompanyCity} style = {{border: companyCityError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                    </div>
                </div>
                <div className = "flex-column width-auto margin-top-15">
                    <p className = "main-font-light font-size-14 font-color-grey">Telefoon</p>
                    <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" pattern = "^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$" value = {contactTelephone} onChange = {handleContactTelephone} style = {{border: contactTelephoneError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                    <p className = "main-font-light font-size-14 font-color-grey">Beroep</p>
                    <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" value = {contactProfession} onChange = {handleContactProfession} style = {{border: contactProfessionError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                    <p className = "main-font-light font-size-14 font-color-grey">E-mail</p>
                    <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" pattern = "[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+" value = {contactEmail} onChange = {handleContactEmail} style = {{border: contactEmailError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                    <p className = "main-font-light font-size-14 font-color-grey">Wachtwoord</p>
                    <input className = "input-custom-control margin-top-10 main-font-semi-bold font-size-14 font-color-black" type = "password" value = {contactPassword} onChange = {handleContactPassword} style = {{border: contactPasswordError ? 'solid 1px red' : 'solid 1px #DADADA'}} />
                </div>
                <div className = "flex-column width-auto margin-top-15">
                    <p className = "main-font-light font-size-14 font-color-grey">Gebruikersgroep</p>
                    <select placeholder = "" value = {contactRole} onChange = {handleContactRole} className = "add-contact-person-select main-font-semi-bold font-size-14 font-color-black" >
                    <option value = {2}>Bouwen & verbouwen</option>
                    <option value = {1}>Verkopen & verhuren</option>
                    </select>
                </div>
            </Grid>
            <div className="private-client-div-save font-size-14 margin-top-20">
                <button className="private-client-button-save" onClick={handleSubmitClick}>
                    <img src={CheckIcon} className="private-client-check-icon" alt=""/>
                    Save
                </button>
            </div>
            </div>
          </Grid>
    </Dialog>
  )
}

AddClient.propTypes = {
  setCompanyId: PropTypes.func,
  companyId: PropTypes.number,
  setShowService: PropTypes.func
}

AddClient.defaultProps = {
  setCompanyId: function() {},
  setContactId: function() {},
  setShowService: function() {},
  companyId: 0,
}