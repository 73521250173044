import { pdf } from "@react-pdf/renderer";
import axios from "axios";

export const saveBlob = async (blob, filename) => {
    const generatedPdf = await pdf(blob).toBlob();
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(generatedPdf);
    // a.href = url;
    // a.click();
    window.open(url);
};
  
export const savePdf = async (document, invoiceId, companyId) => {
    const generatedPdf = await pdf(document).toBlob()
    const file = new File([generatedPdf], 'invoice.pdf', { type: "application/pdf" })
    // saveBlob(generatedPdf, filename);
    const formData = new FormData();
    formData.append('invoice_id', invoiceId);
    formData.append('company_id', companyId)
    formData.append('docsInput', file);
    const token = localStorage.getItem('authToken')

    axios.post(process.env.REACT_APP_API_URL+"/invoice/uploadInvoices", formData , {
        headers: {
            authorization: `bearer ${token}`,
        }
    }).then(response => {
        // success
        axios.post(process.env.REACT_APP_API_URL+"/invoice/oferte_bill_send_email", {
            invoice_id: invoiceId
        } , {
            headers: {
                authorization: `bearer ${token}`,
            }
        }).response(() => {

        }).catch(error => {
        })
    }).catch(error => {
    })
};

export const saveSafetyPdf = async (document, safetyId, setUploadedPdf, setUploadSuccessState) => {
    const generatedPdf = await pdf(document).toBlob()
    const file = new File([generatedPdf], 'safetyService.pdf', { type: "application/pdf" })
    // saveBlob(generatedPdf, filename);
    const formData = new FormData();
    formData.append('safetyId', safetyId);
    formData.append('docsInput', file);
    const token = localStorage.getItem('authToken')
    
    axios.post(process.env.REACT_APP_API_URL+"/safety_service_form/upload_safty_pdf", formData , {
        headers: {
            authorization: `bearer ${token}`,
        }
    }).then(response => {
        if(response.data.success){
            setUploadedPdf(response.data.safety_pdf)
            setUploadSuccessState(true)
        }
        else{
            setUploadSuccessState(false)
        }
    }).catch(error => {
        setUploadSuccessState(false)
    })
};

export function isLocalDataValid() {
    const token = localStorage.getItem("authToken");
    const userDetails = localStorage.getItem("loggedInUserDetails");
    return token !== null && userDetails !== null;
}

export const standardStyles = {
    page: {
        width: 750,
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 42.5
    },
    pageLarge: {
        width: 1440,
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingBottom: 42.5,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    widthAuto: {
        width: '100%',
    },
    companyImage: {
        width: 212,
        height: 76,
        objectFit: 'cover',
        backgroundColor: '#CCC'
    },
    mainFontRegular: {
        fontFamily: 'vietnam',
        fontWeight: 'normal',
    },
    mainFontMedium: {
        fontFamily: 'vietnam',
        fontWeight: 'medium',
    },
    mainFontSemiBold: {
        fontFamily: 'vietnam',
        fontWeight: 'semibold',
    },
    mainFontBold: {
        fontFamily: 'vietnam',
        fontWeight: 'bold',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    alignCenter: {
        alignItems: 'center'
    }
}

export function isTokenValid(history) {
    const token = localStorage.getItem('authToken');
    if(token) {
        axios.post(process.env.REACT_APP_API_URL+"/user/latestData", {}, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(() => {

        }).catch(() => {
            localStorage.clear()
            history.push('/')
        })
    }
    else {
        localStorage.clear()
        history.push('/')
    }
}