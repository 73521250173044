import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { addClientContactForm, userRolesData } from '../../shared/sample_data';
import axios from 'axios'
import Popup from '../../shared/popup/custom_popup.js';
import PropTypes from 'prop-types'
import './_view_client.scss';
import EditIcon from '../../../assets/svg/icon_edit_grey.svg'

export default function ViewClient(props) {
    const {companyId, viewState} = props
    const [ contactFormState, setContactFormState ] = useState([])
    const [showResponseState, setShowResponseState] = useState(false)
    const [responseMessageState, setResponseMessageState] = useState({})

    // company form state vars
    const [ companyDetails, setCompanyDetails ] = useState(0);

    const token = localStorage.getItem('authToken')
    const userRoles = userRolesData

    useEffect(() => {
        setContactFormState(JSON.parse(JSON.stringify(addClientContactForm)))
        if (companyId > 0) {
            axios.post(
              process.env.REACT_APP_API_URL+`/admin/company/get/${companyId}`, {},
              { headers: { authorization: `bearer ${token}` } },
            )
            .then((response) => {
              const data = response.data
              if (data !== null && data !== undefined) {
                const companyData = data.company
                const contactData = data.user
                if (companyData !== null && companyData !== undefined) {
                  const prevContactFormState = [...contactFormState]
                  if (contactData !==  null && contactData !== undefined) {
                    if (contactData.length > 0) {
                      prevContactFormState.forEach(item => {
                        if (item.name === 'contactId') {
                          item.value = (contactData[0].id !== null && contactData[0].id !== undefined) ? parseInt(contactData[0].id) : 0
                        }
                        else if (item.name === 'contactName') {
                          item.value = contactData[0].lastName
                        }
                        else if(item.name === 'contactFirstName') {
                          item.value = contactData[0].firstName
                        }
                        else if(item.name === 'contactPhone') {
                          item.value = contactData[0].phone
                        }
                        else if(item.name === 'contactProfession') {
                          item.value = contactData[0].profession
                        }
                        else if(item.name === 'contactEmail') {
                          item.value = contactData[0].email
                        }
                        else if(item.name === 'contactPassword') {
                          item.value = contactData[0].password
                        }
                        else if(item.name === 'userRole') {
                          const userRoleObj = userRoles.find(a => a.id === contactData[0].roleId)
                          if (userRoleObj !== null && userRoleObj !== undefined) {
                            item.value = userRoleObj.title  
                          }
                          else {
                            item.value = ''
                          }
                        }
                      })
                      setContactFormState(prevContactFormState)
                    }
                  }
                  setCompanyDetails(companyData)
                }
              }
            })
            .catch((error) => {
              let responseMessage = {
                isSuccess: false,
                heading: 'Geen record gevonden',
                message: 'Er is iets fout gegaan',
                popupState: setShowResponseState
              }
              setShowResponseState(true)
              setResponseMessageState(responseMessage)
            })
          }
    }, [companyId])

    const handleEditClick = () => {
      viewState(false)
    }

    return (
      <Grid container>
        <div className="view-client-main-div">
          <Grid container>
            <Grid container item xs={12} sm={12} md={6} lg={5}>
              <Grid container>
                <div>
                  <span className="main-font-semi-bold font-color-black font-size-18">
                    Bedrijf
                  </span>
                </div>
              </Grid>
              <Grid container className="margin-top-20">
                <Grid item xs={6}>
                  {companyDetails.profile_picture ? (
                    <div className="view-client-img-div overflow-hidden">
                      <img alt = 'profile_picture' src={process.env.REACT_APP_API_URL+`/uploads/${companyDetails.profile_picture}`} className = "height-auto width-auto object-fit-contain" />
                    </div>
                  ) : (
                    <div className="view-client-img-div-default">
                      <img alt = 'user' src={require('../../../assets/svg/user.svg')} className = "height-auto width-auto object-fit-cover" />
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container item>
                <div className = "flex-column width-auto">
                  <span className="main-font-light font-size-14 font-color-grey margin-top-20">Bedrijf</span>
                  <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.name}</p>
                </div>
                <div className = "flex-column width-auto">
                  <span className="main-font-light font-size-14 font-color-grey margin-top-20">BTW</span>
                  <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.BTW}</p>
                </div>
                <div className = "flex-row width-auto horizontal-between">
                  <div className = "flex-column width-60-p">
                    <span className="main-font-regular font-color-grey margin-top-20">Adres</span>
                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.companyAddress}</p>
                  </div>
                  <div className = "flex-column width-19-p">
                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Nr</span>
                    <p className= "main-font-semi-bold font-size-14 font-color-black">{typeof companyDetails === "object" && companyDetails.no}</p>
                  </div>
                  <div className = "flex-column width-19-p">
                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Bus</span>
                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.bus}</p>
                  </div>
                </div>
                <div className = "flex-row width-auto horizontal-between">
                  <div className = "flex-column width-29-p">
                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Zip</span>
                    <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.postalCode}</p>
                  </div>
                  <div className = "flex-column width-69-p">
                    <span className="main-font-light font-size-14 font-color-grey margin-top-20">Gemeente</span>
                    <p className= "main-font-semi-bold font-size-14 font-color-black">{typeof companyDetails === "object" && companyDetails.city}</p>
                  </div>
                </div>
                <div className = "flex-column width-auto">
                  <span className="main-font-light font-size-14 font-color-grey margin-top-20">Kantoor e-mail</span>
                  <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.generalEmail}</p>
                </div>
                <div className = "flex-column width-auto">
                  <span className="main-font-light font-size-14 font-color-grey margin-top-20">Kantoor tel.</span>
                  <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.generalTelephone}</p>
                </div>
                {contactFormState.length > 0 && contactFormState[contactFormState.length - 1].value.toLowerCase() === "superadmin" && (
                  <div className = "flex-row width-auto">
                    <div className = "flex-column width-auto margin-right-5">
                      <span className="main-font-light font-size-14 font-color-grey margin-top-20">BIC</span>
                      <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.bic !== null && companyDetails.bic}</p>
                    </div>
                    <div className = "flex-column width-auto">
                      <span className="main-font-light font-size-14 font-color-grey margin-top-20">IBAN</span>
                      <p className= "main-font-semi-bold font-size-14 font-color-black margin-top-5">{typeof companyDetails === "object" && companyDetails.iban !== null && companyDetails.iban}</p>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid container item xs={12} sm={12} md={6} lg={5}>
              <Grid container className="margin-bottom-25">
                <div>
                  <span className="main-font-semi-bold font-color-black font-size-18">
                    Contactpersoon 1
                  </span>
                </div>
              </Grid>
              {contactFormState !== null
                ? contactFormState.map((item) => {
                    return (item.id > 1 &&
                      item.value !== '0') ? (
                        (item.name === 'contactPassword') ?
                        <Grid container key={item.id}>
                        <div className="flex-column font-size-14">
                          <span className="main-font-regular font-color-grey margin-top-10">
                            {item.label}
                          </span>
                          <span className="main-font-semi-bold font-color-black margin-top-10">
                            ********
                          </span>
                        </div>
                      </Grid>
                      :
                      <Grid container key={item.id}>
                        <div className="flex-column font-size-14">
                          <span className="main-font-regular font-color-grey margin-top-10">
                            {item.label}
                          </span>
                          <span className="main-font-semi-bold font-color-black margin-top-10">
                            {item.value}
                          </span>
                        </div>
                      </Grid>
                    ) : null
                  })
                : null}
            </Grid>
          </Grid>
          <Grid container className="margin-top-20">
            <Grid item xs={12}>
              <div className="horizontal-end margin-top-20">
                <button
                  className="view-client-edit-button font-color-grey"
                  onClick={() => handleEditClick()}
                >
                  <img src={EditIcon} className="view-client-button-icon" alt="" />
                  Edit
                </button>
              </div>
            </Grid>
          </Grid>
          {showResponseState === true ? (
            <Popup data={responseMessageState} />
          ) : null}
        </div>
      </Grid>
    )
}

ViewClient.propType = {
    companyId: PropTypes.number,
    viewState: PropTypes.func
}
ViewClient.defaultProps = {
    companyId: 0,
    viewState: function() {}
}
