import React, { useState, useRef, useEffect } from "react";
import { Grid, Hidden } from "@material-ui/core";
import axios from 'axios'
import Header from '../header/header';
import Calendar from "./calendar/calendar";
import MobileView from "../mobile_view/mobile_view";
import { adminColors } from "../../shared/sample_data"

import "./_planning.scss";

function EventModal( props ) {
    const [toggleState, setToggleState] = useState(false);
    const modalRef = useRef(0);
    
    const { deleteFunction, id, editFunction } = props;

    const handleClick = () => {
        if(toggleState === false) {
            modalRef.current.focus();
        }
        setToggleState(!toggleState);
    }

    const handleBlur = () => {
        setToggleState(false)
    }

    const handleDelete = () => {
        deleteFunction(id)
    }

    const handleEdit = () => {
        editFunction();
    }
    
    return(
        <div className = "flex-column vertical-center relative outline-none" tabIndex = "2" ref = {modalRef} onBlur = {handleBlur} >
            <div className = "flex-column cursor-pointer" onClick = {handleClick} >
                <div className = "planning-event-panel-menu-dot" />
                <div className = "planning-event-panel-menu-dot margin-top-5 margin-bottom-5" />
                <div className = "planning-event-panel-menu-dot" />
            </div>
            <div className = "planning-event-panel-modal-container absolute animatable-400 ease-in-out-quart" style = {{height: toggleState ? '125px' : '0px', opacity: toggleState ? 1 : 0}} >
                <div className = "width-auto horizontal-end">
                    <img src = {require('../../../assets/svg/icon/corner.png')} alt = "corner" className = "planning-event-panel-modal-arrow" />
                </div>
                <div className = "planning-event-panel-modal flex-column">
                    <button type = "button" className = "planning-event-panel-modal-button planning-event-panel-modal-line horizontal-end vertical-center" onClick = {handleEdit} >
                        <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-right-10">Editeren</h1>
                    </button>
                    <button type = "button" className = "planning-event-panel-modal-button horizontal-end vertical-center" onClick = {handleDelete} >
                        <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-right-10">Delete</h1>
                    </button>
                </div>
            </div>
        </div> 
    )
}

export default function Planning() {
    const [ plannerData, setPlannerData ] = useState([]);
    const [ newEventState, setNewEventState ] = useState(false);
    const [ eventRadioState, setEventRadioState ] = useState(false);
    const [ selectedResponsible, setSelectedResponsible ] = useState(0); // 0 value is default for all
    const [ variant, setVariant ] = useState(0) // variant has 3 states, it shows on the monthly version, hidden on weekly version, shown on weekly version
    const [ monthServiceDataPool, setMonthServiceDataPool ] = useState([]);
    const [ responsibleList, setResponsibleList ] = useState([])
    const [ serviceList, setServiceList ] = useState([])
    const [ serviceDropdownState, setServiceDropdownState ] = useState(false)
    const [ currentUser, setCurrentUser ] = useState({});
    const [ selectedDate, setSelectedDate ] = useState( new Date() );
    const [ activityDialogState, setActivityDialogState ]= useState(0); // 0 is for hiding the dialog, 1 is for showing the new event added, 2 is for showing event deleted

    // error state vars
    const [ errorState, setErrorState ] = useState(false)
    const [ endDateError, setEndDateError ] = useState(false);
    const [ startTimeError, setStartTimeError ] = useState(false);
    const [ endTimeError, setEndTimeError ] = useState(false);
    const [ clientError, setClientError ] = useState(false)
    const [ emailError, setEmailError ] = useState(false);
    const [ descriptionError, setDescriptionError ] = useState(false)

    // state vars for new event form
    const [ endDate, setEndDate ] = useState("");
    const [ startTime, setStartTime ] = useState("");
    const [ endTime, setEndTime ] = useState("");
    const [ client, setClient ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ selectedService, setSelectedService ] = useState(0);
    const [ updateId, setUpdateId ] = useState(0);
    const [buttonDisableState, setButtonDisableState] = useState(false)

    useEffect(() => {
        const adminData = JSON.parse( localStorage.getItem('loggedInUserDetails') );
        
        setCurrentUser(adminData);
        getAdminData()
        getAllServices();
    }, [])

    const handleRadioButton = () => {
        setButtonDisableState(false)
        setEventRadioState(!eventRadioState);
    }

    const newEventHandle = () => {
        if(newEventState) {
            resetFields();
        }
        setNewEventState(!newEventState);
        setVariant( variant === 2 ? 1 : 0);
        setButtonDisableState(false)
    }

    function getAdminData() {
        let token = localStorage.getItem('authToken');
        axios.post(process.env.REACT_APP_API_URL+'/user/all_admin', {}, {
          headers: {
            authorization: `bearer ${token}`
          }
        }).then(response => {
            let newData = []
            response.data.data.map((item, index) => {
                let newObj = item;
                newObj.color = adminColors[index].color;
                newData.push(newObj)
            })
            setResponsibleList(newData)
        }).catch(error => {
        })
      }

    function getMonthData(startDate, endDate){
        const token = localStorage.getItem('authToken')
        axios.post(
            process.env.REACT_APP_API_URL+'/events/get_between_date',
            {
                startDate: startDate,
                endDate: endDate
            },
            {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            },
        )
        .then((response) => {
            setPlannerData(response.data)
        })
        .catch((error) => {
        })
    }

    function areFieldsValid() {
        let validation = true;
        if(eventRadioState) {
            const parsedEndDate = endDate !== "" ? endDate.split("/") : false;
            const isEndDateValid = parsedEndDate !== false ? selectedDate.getDate() <= parseInt(parsedEndDate[0]) ? (selectedDate.getMonth() + 1) <= parseInt(parsedEndDate[1]) ? selectedDate.getFullYear() <= parseInt(parsedEndDate[2]) : false : false : false; 
            if(!isEndDateValid){
                setEndDateError(true)
                validation = false;
            }
            if(serviceList.length === 0) {
                validation = false;
            }
            if(client === "") {
                setClientError(true)
                validation = false;
            }
            if(email === "") {
                setEmailError(true)
                validation = false;
            }
            if(description === ""){
                setDescriptionError(true);
                validation = false;
            }
        }
        else {
            if(startTime === ""){
                setStartTimeError(true)
                validation = false;
            }
            if(endTime === ""){
                setEndTimeError(true)
                validation = false;
            }
            if(serviceList.length === 0) {
                validation = false;
            }
            if(client === "") {
                setClientError(true)
                validation = false;
            }
            if(email === "") {
                setEmailError(true)
                validation = false;
            }
            if(description === ""){
                setDescriptionError(true);
                validation = false;
            }
        }

        return validation;
    }

    function addNewEvent() {
        // first validate the fields 
        const isValidated = areFieldsValid();
        const splitEndDate = eventRadioState ? endDate.split("/") : null;
        if(isValidated) {
            setButtonDisableState(true)
            let token = localStorage.getItem("authToken")
            axios.post(process.env.REACT_APP_API_URL+'/events/add', {
                user_id: currentUser.id,
                service_id: serviceList[selectedService].id,
                message: description,
                email: email,
                start_date: selectedDate,
                end_date: splitEndDate !== null ? new Date(parseInt(splitEndDate[2]), parseInt(splitEndDate[1]) - 1, parseInt(splitEndDate[0])) : selectedDate,
                start_time: eventRadioState ? '12:00:00' : `${startTime}:00`,
                end_time: eventRadioState ? '12:00:00' : `${endTime}:00`,
                is_full_day: eventRadioState,
                company_name: client,
                service_name: serviceList[selectedService].title,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setVariant( variant === 2 ? 1 : 0 )
                triggerDialog(1)
                setNewEventState(false);
                resetFields();
            }).catch((error) => {
                setButtonDisableState(false)
            })
        }
        else {
            !errorState && setErrorState(true);
        }
    }

    function deleteEvent(id) {
        let token = localStorage.getItem("authToken")
        axios.post(process.env.REACT_APP_API_URL+'/events/delete', {
            id
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            const newDataPool = monthServiceDataPool.filter(event => event.id !== id);
            triggerDialog(2)
            setMonthServiceDataPool(newDataPool);
        }).catch((error) => {
        })
    }

    function getAllServices() {
        let token = localStorage.getItem("authToken")
        axios.post(process.env.REACT_APP_API_URL+'/service/all', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
        .then((response) => {
            setServiceList(response.data)
        })
        .catch((error) => {
        })
    }

    function cleanDateTimeString(dateTime) {
        let newDateTime = dateTime.split(" ");
        newDateTime = {
            date: newDateTime[0].split("-"),
            time: newDateTime[1].split(":")
        }

        newDateTime.date = `${newDateTime.date[2]}/${newDateTime.date[1]}/${newDateTime.date[0]}`;
        newDateTime.time = `${newDateTime.time[0]}:${newDateTime.time[1]}`

        return `${newDateTime.date}`;
    }

    function cleanTimeString(time) {
        let newTime = time.split(":");

        return `${newTime[0]}:${newTime[1]}`
    }

    const handleStartTime = (event) => {
        if(event.target.validity.valid) {
            startTimeError && setStartTimeError(false)
        }
        else {
            setStartTimeError(true)
        }
        setButtonDisableState(false)
        setStartTime(event.target.value)
    }

    const handleEndTime = (event) => {
        if(event.target.validity.valid) {
            endTimeError && setEndTimeError(false)
        }
        else {
            setEndTimeError(true)
        }
        setButtonDisableState(false)
        setEndTime(event.target.value)
    }

    const handleEndDate = (event) => {
        if(event.target.validity.valid){
            endDateError && setEndDateError(false);
        }
        else {
            setEndDateError(true);
        }
        setButtonDisableState(false)
        setEndDate(event.target.value);
    }
    
    const handleClient = (event) => {
        if(event.target.value === "") {
            !clientError && setClientError(true);
        }
        else {
            clientError && setClientError(false)
        }
        setButtonDisableState(false)
        setClient(event.target.value)
    }

    const handleEmail = (event) => {
        if(event.target.validity.valid) {
            emailError && setEmailError(false);
        }
        else {
            !emailError && setEmailError(true)
        }
        setButtonDisableState(false)
        setEmail(event.target.value)
    }

    const handleDescription = (event) => {
        if(event.target.value === "") {
            !descriptionError && setDescriptionError(true);
        }
        else {
            descriptionError && setDescriptionError(false)
        }
        setButtonDisableState(false)
        setDescription(event.target.value)
    }

    function resetFields() {
        setStartTime("");
        setEndTime("");
        setSelectedService(0);
        setClient("");
        setEmail("");
        setDescription("");
        setEndDate("");
        setUpdateId(0);

        // reset the errors 
        setStartTimeError(false);
        setEndTimeError(false);
        setClientError(false);
        setEmailError(false);
        setDescriptionError(false)
        setEndDateError(false);
    }

    function triggerDialog(type) {
        setActivityDialogState(type);
        setTimeout(() => {
            setActivityDialogState(0)
        }, 1600);
    }
    
    function editEvent(event) {
        const eventRadio = event.is_full_day;
        let newSelectedDate = event.start_date.split(" ");
        newSelectedDate = newSelectedDate[0].split("-");
        let newEndDate;
        let startTime;
        let endTime;  

        if(eventRadio) {
            newEndDate = event.end_date.split(" ");
            newEndDate = newEndDate[0].split("-");
            startTime = ""
            endTime = "";
        }
        else {
            newEndDate = "";
            startTime = event.start_time.split(":");
            endTime = event.end_time.split(":");
        }

        const serviceId = serviceList.findIndex(service => service.id === event.service_id)

        setSelectedDate( new Date( parseInt(newSelectedDate[0]), parseInt(newSelectedDate[1]) - 1, parseInt(newSelectedDate[2]), 12));
        setEndDate(`${newEndDate !== "" ? `${newEndDate[2]}/${newEndDate[1]}/${newEndDate[0]}` : ""}`)
        setStartTime( `${eventRadio ? startTime : `${startTime[0]}:${startTime[1]}`}`)
        setEndTime( `${eventRadio ? endTime : `${endTime[0]}:${endTime[1]}`}`)
        setEventRadioState(eventRadio)
        setSelectedService(serviceId);
        setClient(event.company_name);
        setEmail(event.email);
        setDescription(event.message);
        setUpdateId(event.id)
        setNewEventState(true)
    }

    function updateEvent() {
        const isValidated = areFieldsValid();
        const splitEndDate = eventRadioState ? endDate.split("/") : null;
        if(isValidated) {
            let token = localStorage.getItem("authToken")
            setButtonDisableState(true)
            axios.post(process.env.REACT_APP_API_URL+'/events/update', {
                id: updateId,
                user_id: currentUser.id,
                service_id: serviceList[selectedService].id,
                message: description,
                email: email,
                start_date: selectedDate,
                end_date: splitEndDate !== null ? new Date(parseInt(splitEndDate[2]), parseInt(splitEndDate[1]) - 1, parseInt(splitEndDate[0])) : selectedDate,
                start_time: eventRadioState ? '12:00:00' : `${startTime}:00`,
                end_time: eventRadioState ? '12:00:00' : `${endTime}:00`,
                is_full_day: eventRadioState,
                company_name: client,
                service_name: serviceList[selectedService].title,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                triggerDialog(3)
                setNewEventState(false);
                resetFields();
            }).catch((error) => {
                setButtonDisableState(false)
            })
        }
        else {
            !errorState && setErrorState(true);
        }
    }
    
    return(
        <Grid container>
            <Grid container>
                <Hidden smDown>
                    <Header />
                </Hidden>
                <Hidden smUp>
                    <Header toShowHamburger />
                </Hidden>
            </Grid>
            <Hidden smUp>
                <MobileView />
            </Hidden>
            <Hidden xsDown>
                <Grid container>
                    <Grid container item sm = {12}>
                        <div className = "planning-container overflow-x-hidden">
                            <Grid container item sm = {12} justify = "center" className = "height-disable">
                                <Grid container item sm = {11}>
                                    <Grid container item sm = {12}>
                                        <div className = "margin-top-15" />
                                    </Grid>
                                    {/* calendar side */}
                                    <Grid container item sm = {12} className = "relative">
                                        <div className = "planning-calendar-container animatable-400 ease-in-out-quart" style = {{width: variant !== 0 ? '100%' : '750px'}}>
                                            <Grid container item sm = {12}>
                                                <Grid container item sm = {12}>
                                                    <Grid container item sm = {12}>
                                                        <h1 className = "main-font-semi-bold font-size-18 font-color-black margin-left-25">Agenda</h1>
                                                    </Grid>
                                                    <Grid container item sm = {12}>
                                                        <div className = "margin-top-15" />
                                                    </Grid>
                                                    <Grid container item sm = {12}>
                                                        <Calendar selectedResponsible = {selectedResponsible} setSelectedResponsible = {setSelectedResponsible} responsibleList={responsibleList} variant = {variant} setVariant = {setVariant} data = {plannerData} setMonthServiceDataPool = {setMonthServiceDataPool} getMonthData={getMonthData} selectedDate = {selectedDate} setSelectedDate = {setSelectedDate} setNewEventState = {setNewEventState} setStartTime = {setStartTime} setEndTime = {setEndTime} editEvent = {editEvent} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        {/* sidebar */}
                                        <div className = "planning-panel-container margin-left-25 animatable-400 ease-in-out-quart" style = {{opacity: variant !== 0 ? variant === 1 ? 0 : 1 : 1, position: variant !== 0 ? 'absolute' : 'relative', left: variant !== 0 ? variant === 1 ? '100%' : 'calc(100% - 307px)' : '0px'}}>
                                            <Grid container item sm = {12} className = "height-disable">
                                                <Grid container item sm = {12} direction = "column">
                                                    <div className = "width-auto relative vertical-center animatable-400 ease-in-out-quart" style = {{height: '44px', opacity: variant === 0 ? 1 : 0}}>
                                                        <h1 className = "main-font-semi-bold font-size-18 font-color-black margin-left-25 animatable-400 ease-in-out-quart absolute margin-disable" style = {{opacity: newEventState ? 0 : 1, transform: newEventState ? 'translateX(-15px)' : 'translateX(0px)'}} >{selectedResponsible === 0 ? "Iedereen" : selectedResponsible.firstName}</h1>
                                                        <h1 className = "main-font-semi-bold font-size-18 font-color-black margin-left-25 animatable-400 ease-in-out-quart absolute margin-disable" style = {{opacity: newEventState ? 1 : 0, transform: newEventState ? 'translateX(0px)' : 'translateX(15px)'}} >Nieuwe event</h1>
                                                    </div>
                                                </Grid>
                                                <Grid container item sm = {12}>
                                                    <div className = "margin-top-15" />
                                                </Grid>
                                                <Grid container item sm = {12}>
                                                    <div className = "planning-event-panel">
                                                        {/* list of services given to that specific backoffice user */}
                                                        {!newEventState && (
                                                            <div className = "flex-column width-auto">
                                                            {/* when a backoffice user is selected */}
                                                            {selectedResponsible !== 0 ? monthServiceDataPool.filter(event => event.user_id === selectedResponsible.id).map((item, index) => (
                                                                <div className = {`${index === 0 ? 'planning-event-panel-service' : 'planning-event-panel-service-border'} vertical-center horizontal-between fade-in-bottom`} style = {{animationDelay: `${index * 0.1}s`, zIndex: monthServiceDataPool.filter(event => event.user_id === selectedResponsible.id).length - index}} >
                                                                    <div className = "flex-row vertical-center">
                                                                        <div className = "planning-event-panel-service-profile" />
                                                                        <div className = "flex-column margin-left-25">
                                                                            <p className = "main-font-regular font-size-14 font-color-light-grey margin-bottom-5">{cleanDateTimeString(item.start_date)} - {cleanTimeString(item.start_time)}</p>
                                                                            <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-disable margin-bottom-5">{item.service_name}</h1>
                                                                            <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-disable">{item.company_name}</h1>
                                                                        </div>
                                                                    </div>
                                                                    <EventModal deleteFunction = {deleteEvent} editFunction = {() => { editEvent(item) }} id = {item.id} />
                                                                </div>
                                                                // when all is selected
                                                            )) : monthServiceDataPool.map((item, mainIndex) => (
                                                                <div className = {`${mainIndex === 0 ? 'planning-event-panel-service' : 'planning-event-panel-service-border'} vertical-center horizontal-between fade-in-bottom`} style = {{zIndex: (monthServiceDataPool.length * 10) - mainIndex}} >
                                                                    <div className = "flex-row vertical-center">
                                                                        <div className = "planning-event-panel-service-profile" />
                                                                        <div className = "flex-column margin-left-25">
                                                                            <p className = "main-font-regular font-size-14 font-color-light-grey margin-bottom-5">{cleanDateTimeString(item.start_date)} - {cleanTimeString(item.start_time)}</p>
                                                                            <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-disable margin-bottom-5">{item.service_name}</h1>
                                                                            <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-disable">{item.company_name}</h1>
                                                                        </div>
                                                                    </div>
                                                                    <EventModal deleteFunction = {deleteEvent} editFunction = {() => { editEvent(item) }} id = {item.id} />
                                                                </div>
                                                            ))}
                                                            </div>
                                                        )}

                                                        {/* new event form */}
                                                        {newEventState && (
                                                            <Grid container item sm = {12} className = "fade-in-bottom">
                                                                <div className = "flex-row width-auto vertical-center">
                                                                    <button type = "button" className = "planning-event-panel-new-radio cursor-pointer animatable-400 ease-in-out-quart" style = {{background: eventRadioState ? '#7DA5C2' : 'rgba(0,0,0,0)'}} onClick = {handleRadioButton} />
                                                                    <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-left-10">Hele dag</h1>
                                                                </div>

                                                                <Grid container item sm = {12}>
                                                                    <div className = "margin-top-15" />
                                                                </Grid>

                                                                <Grid container item sm = {12}>
                                                                    <Grid container item sm = {12}>
                                                                        {/* when Hele dag radio button is off */}
                                                                        {!eventRadioState && (
                                                                            <Grid container item sm = {12}>
                                                                                {/* date input */}
                                                                                <Grid container item sm = {12}>
                                                                                    <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Datum</p>
                                                                                </Grid>
                                                                                <Grid container item sm = {12}>
                                                                                    <input className = "planning-event-panel-new-input center-content main-font-semi-bold font-size-14 font-color-black" placeholder = "17/09/2020" value = {`${selectedDate.getDate()}/${selectedDate.getMonth() + 1}/${selectedDate.getFullYear()}`} disabled /> 
                                                                                </Grid>

                                                                                <Grid container item sm = {12}>
                                                                                    <div className = "margin-top-20" />
                                                                                </Grid>

                                                                                {/* from time and end time inputs */}
                                                                                <Grid container item sm = {12} justify = "space-between">
                                                                                    <div className = "flex-column width-48-p">
                                                                                        <Grid container item sm = {12}>
                                                                                            <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Van</p>
                                                                                        </Grid>
                                                                                        <Grid container item sm = {12}>
                                                                                            <input className = "planning-event-panel-new-input center-content main-font-semi-bold font-size-14 font-color-black" style = {{border: startTimeError ? 'solid 1px #f44336' : 'solid 1px #DADADA'}} pattern = "([0-1][0-9]|[2][0-4])[:]{1}[0-5][0-9]" maxLength = {5} value = {startTime} onChange = {handleStartTime} placeholder = "16:30" /> 
                                                                                        </Grid>
                                                                                    </div>
                                                                                    <div className = "flex-column width-48-p">
                                                                                        <Grid container item sm = {12}>
                                                                                            <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Tot</p>
                                                                                        </Grid>
                                                                                        <Grid container item sm = {12}>
                                                                                            <input className = "planning-event-panel-new-input center-content main-font-semi-bold font-size-14 font-color-black" style = {{border: endTimeError ? 'solid 1px #f44336' : 'solid 1px #DADADA'}} pattern = "([0-1][0-9]|[2][0-4])[:]{1}[0-5][0-9]" maxLength = {5} value = {endTime} onChange = {handleEndTime} placeholder = "17:30" /> 
                                                                                        </Grid>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}

                                                                        {/* when Hele dag radio button is on */}
                                                                        {eventRadioState && (
                                                                            <Grid container item sm = {12}>
                                                                                <Grid container item sm = {12}>
                                                                                    <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Van</p>
                                                                                </Grid>
                                                                                <Grid container item sm = {12}>
                                                                                    <input className = "planning-event-panel-new-input center-content main-font-semi-bold font-size-14 font-color-black" placeholder = "17/09/2020" value = {`${selectedDate.getDate()}/${selectedDate.getMonth() + 1}/${selectedDate.getFullYear()}`} disabled /> 
                                                                                </Grid>

                                                                                <Grid container item sm = {12}>
                                                                                    <div className = "margin-top-20" />
                                                                                </Grid>

                                                                                <Grid container item sm = {12}>
                                                                                    <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Tot</p>
                                                                                </Grid>
                                                                                <Grid container item sm = {12}>
                                                                                    <input className = "planning-event-panel-new-input center-content main-font-semi-bold font-size-14 font-color-black" style = {{border: endDateError ? 'solid 1px #f44336' : 'solid 1px #DADADA'}} maxLength = {10} placeholder = "21/09/2020" pattern = "(([0][1-9])|([1-2][0-9])|([3][0-1])|([1-9]))[/]{1}(([0][1-9])|([1][0-2])|([1-9]))[/]{1}[1-2][0][0-9]{2}" value = {endDate} onChange = {handleEndDate} /> 
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}

                                                                        <Grid container item sm = {12}>
                                                                            <div className = "margin-top-20" />
                                                                        </Grid>

                                                                        {/* service drop down */}
                                                                        <Grid container item sm = {12}>
                                                                            <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Service</p>
                                                                        </Grid>
                                                                        <Grid container item sm = {12}>
                                                                            <div className = "planning-event-panel-new-service">
                                                                                <button type = "button" className = "planning-event-panel-new-service-input cursor-pointer animatable-400 ease-in-out-quart" onClick = {() => { setServiceDropdownState(!serviceDropdownState) }} style = {{height: serviceDropdownState ? `${serviceList.length < 6 ? (45 * serviceList.length) : (45 * 6)}px` : '45px', overflowY: serviceDropdownState ? 'auto' : 'hidden'}} >
                                                                                    <div className = "planning-event-panel-new-service-item flex-row width-auto horizontal-between ">
                                                                                        <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-disable start-text">{serviceList.length > 0 && serviceList[selectedService].title}</h1>
                                                                                        <img src = {require('../../../assets/svg/icon/arrow_down_grey.svg')} alt = "arrow down" className = "planning-event-panel-new-service-arrow" />
                                                                                    </div>
                                                                                    {serviceDropdownState && serviceList.length > 0 && serviceList.map((item, index) => (
                                                                                        <div className = "planning-event-panel-new-service-item flex-row width-auto" onClick = {() => { setSelectedService(index) }}>
                                                                                            <h1 className = "main-font-semi-bold font-size-14 font-color-black margin-disable start-text">{item.title}</h1>
                                                                                        </div>
                                                                                    ))}
                                                                                </button>
                                                                            </div>
                                                                        </Grid>

                                                                        <Grid container item sm = {12}>
                                                                            <div className = "margin-top-20" />
                                                                        </Grid>

                                                                        {/* client input */}
                                                                        <Grid container item sm = {12}>
                                                                            <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Klant</p>
                                                                        </Grid>
                                                                        <Grid container item sm = {12}>
                                                                            <input className = "planning-event-panel-new-input center-content main-font-semi-bold font-size-14 font-color-black" style = {{border: clientError ? 'solid 1px #f44336' : 'solid 1px #DADADA'}}  value = {client} onChange = {handleClient} placeholder = "We Invest" /> 
                                                                        </Grid>

                                                                        <Grid container item sm = {12}>
                                                                            <div className = "margin-top-20" />
                                                                        </Grid>

                                                                        {/* Email input */}
                                                                        <Grid container item sm = {12}>
                                                                            <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Uitnodigen</p>
                                                                        </Grid>
                                                                        <Grid container item sm = {12}>
                                                                            <input className = "planning-event-panel-new-input center-content main-font-semi-bold font-size-14 font-color-black" style = {{border: emailError ? 'solid 1px #f44336' : 'solid 1px #DADADA'}} pattern = "[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+" value = {email} onChange = {handleEmail} placeholder = "info@lauramco.be" /> 
                                                                        </Grid>

                                                                        <Grid container item sm = {12}>
                                                                            <div className = "margin-top-20" />
                                                                        </Grid>

                                                                        {/* description input */}
                                                                        <Grid container item sm = {12}>
                                                                            <p className = "main-font-regular font-size-14 font-color-grey margin-bottom-5">Beschrijving</p>
                                                                        </Grid>
                                                                        <Grid container item sm = {12}>
                                                                            <textarea className = "planning-event-panel-new-textarea center-content main-font-semi-bold font-size-14 font-color-black" style = {{border: descriptionError ? 'solid 1px #f44336' : 'solid 1px #DADADA'}}  value = {description} onChange = {handleDescription} placeholder = "Sleutels gaan halen aan het kantoor voor het project" /> 
                                                                        </Grid>
                                                                        <Grid container item sm = {12}>
                                                                            <p className = "main-font-regular font-size-14 animatable-400 ease-in-out-quart planning-event-panel-error margin-top-20" style = {{opacity: errorState ? 1 : 0, transform: errorState ? 'translateY(0px)' : 'translateY(-15px)'}}>Fields are not filled correctly.</p>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid container item sm = {12} justify = "space-between">
                                                    <button type = "button" className = "planning-event-panel-button center-content relative animatable-400 ease-in-out-quart" onClick = {() => { newEventState ? updateId === 0 ? addNewEvent() : updateEvent() : newEventHandle() }} disabled = {buttonDisableState} style = {{width: newEventState ? '49%' : '100%'}}>
                                                        <h1 className = "main-font-semi-bold font-size-14 font-color-white absolute margin-disable animatable-400 ease-in-out-quart" style = {{opacity: newEventState ? 0 : variant === 0 ? 1 : 0, transform: newEventState ? 'translateX(15px)' : 'translateX(0px)'}} >Nieuw event</h1>
                                                        <h1 className = "main-font-semi-bold font-size-14 font-color-white absolute margin-disable animatable-400 ease-in-out-quart" style = {{opacity: newEventState ? 1 : 0, transform: newEventState ? 'translateX(0px)' : 'translateX(-15px)'}}>Save</h1>
                                                    </button>
                                                    <button type = "button" className = "planning-event-panel-cancel center-content relative animatable-400 ease-in-out-quart" onClick = {newEventHandle} style = {{opacity: newEventState ? 1 : 0, width: newEventState ? '49%' : '0%'}} >
                                                        <h1 className = "main-font-semi-bold font-size-14 font-color-white absolute margin-disable">Cancel</h1>
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <div className = "planning-event-activity-container animatable-400 ease-in-out-quart relative" style = {{opacity: activityDialogState === 0 ? 0 : 1, transform: activityDialogState === 0 ? 'translateY(50px)' : 'translateY(0px)'}}>
                        <div className = "flex-row width-auto height-auto vertical-center padding-left-15 padding-right-15 absolute animatable-400 ease-in-out-quart" style = {{opacity: activityDialogState === 1 ? 1 : 0}} >
                            <img src = {require('../../../assets/svg/check.svg')} alt = "check circle" className = "planning-event-activity-icon" />
                            <h1 className = "main-font-semi-bold font-size-14 font-color-grey margin-disable margin-left-15">New Event Added</h1>
                        </div>                                                               
                        <div className = "flex-row width-auto height-auto vertical-center padding-left-15 padding-right-15 absolute animatable-400 ease-in-out-quart" style = {{opacity: activityDialogState === 2 ? 1 : 0}} >
                            <img src = {require('../../../assets/svg/cancel.svg')} alt = "cancel circle" className = "planning-event-activity-icon" />
                            <h1 className = "main-font-semi-bold font-size-14 font-color-grey margin-disable margin-left-15">Event Deleted</h1>
                        </div>        
                        <div className = "flex-row width-auto height-auto vertical-center padding-left-15 padding-right-15 absolute animatable-400 ease-in-out-quart" style = {{opacity: activityDialogState === 3 ? 1 : 0}} >
                            <img src = {require('../../../assets/svg/check.svg')} alt = "check circle" className = "planning-event-activity-icon" />
                            <h1 className = "main-font-semi-bold font-size-14 font-color-grey margin-disable margin-left-15">Event Updated</h1>
                        </div>                                                            
                    </div>
                </Grid>
            </Hidden>
        </Grid>
    )
}