import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import './_step_four.scss'

export default function StepFour(props) {
  const { safetyPdfFile, safetyPdfFileBackendName, generatedPdfFile } = props
  
  return (
    <Grid container className="animatable-400 fade-in-translate">
      <Grid
        container
        className="epb-service-Category-heading "
        alignItems="center"
      >
        <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
          <Grid container justify="flex-end" xs={10} sm={10} md={10}>
            <img
              alt = 'info'
              className="epb-service-Category-heading-image-size"
              src={require('../../../../assets/svg/icon/info.svg')}
            />
          </Grid>
        </Grid>

        <Grid container xs={11} sm={11} md={11}>
          <Grid container>
            <p className="epb-service-Category-heading-label vietnam-regular">
              Postinterventiedossier
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      <Grid container>
        <Grid container>
          <div className="margin-top-10" />
        </Grid>

        <Grid xs={1} sm={1} md={1} />
        {/* <Grid container xs={6} sm={3} md={2}>
          <a
            href={
              safetyPdfFile &&
              process.env.REACT_APP_API_URL+`/documents/safety_pdf/download_file/${safetyPdfFileBackendName}`
            }
            download
            className={
              `${safetyPdfFile ? 'selected-tab-button-style' : 'disabled-button-style'} cursor-pointer flex-row vertical-center`
            }
          >
            <img
              alt=""
              src={safetyPdfFile   ? require('../../../../assets/svg/icon/download.svg') : require('../../../../assets/svg/icon/download_grey.svg') }
              className={safetyPdfFile   ? '' : "step-two-icon-size margin-left-10"}
            />

              <p className="vietnam-semi-bold center-text width-auto">{`${safetyPdfFile  ? 'Download' : ''}`}</p>
          </a>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container >
            <p className="step-two-description vietnam-regular">
              {safetyPdfFile  && safetyPdfFile}
            </p>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid container>
        <div className="margin-top-10" />
      </Grid>

      <Grid container>
        <Grid container>
          <div className="margin-top-10" />
        </Grid>

        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={6} sm={3} md={2}>
          <a
            href={
              generatedPdfFile &&
              process.env.REACT_APP_API_URL+`/documents/download_file/${generatedPdfFile}`
            }
            download
            className={
              `${generatedPdfFile ? 'selected-tab-button-style' : 'disabled-button-style'} cursor-pointer flex-row vertical-center`
            }
          >
            <img
              alt=""
              src={generatedPdfFile  ? require('../../../../assets/svg/icon/download.svg') : require('../../../../assets/svg/icon/download_grey.svg') }
              className={generatedPdfFile  ? '' : "step-two-icon-size margin-left-10"}
            />

              <p className="vietnam-semi-bold center-text width-auto">{`${generatedPdfFile ? 'Download' : ''}`}</p>
          </a>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid container >
            <p className="step-two-description vietnam-regular">
              {generatedPdfFile && `gegenereerd pdf-bestand`}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
