import React, { useState, useRef, useEffect } from 'react'
import { Container, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteConfirmation from '../../../shared/delete_confirm/delete_confirm';

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

export default function ServiceMobile(props) {
    // state vars for modal control
    const [statusFilter, setStatusFilter] = useState(false)
    const [statusControlBlur, setStatusControlBlur] = useState(false)
    const [responsibleFilter, setResponsibleFilter] = useState(false)
    const [responsibleControlBlur, setResponsibleConrtrolBlur] = useState(false)
    const [dateFilter, setDateFilter] = useState(false)
    const [dateControlBlur, setDateControlBlur] = useState(false)
  
    // state vars for date/time picker
    const [date, setDate] = useState(new Date())
    const [startTimeHour, setStartTimeHour] = useState('')
    const [startTimeMinute, setStartTimeMinute] = useState('')
    const [endTimeHour, setEndTimeHour] = useState('')
    const [endTimeMinute, setEndTimeMinute] = useState('')
  
    // state vars for dropdown fields that change intial selected value
    const [responsible, setResponsible] = useState('-')
  
    // standard state vars
    const [mouseActive, setMouseActive] = useState(false)
    const [instancedData, setInstancedData] = useState({})
    const [renderDom, setRenderDom] = useState(false)
    const [unmountState, setUnmountState] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
  
    // refs for modal dom control
    const nameFilterRef = useRef(0)
    const responsibleFilterRef = useRef(0)
    const dateFilterRef = useRef(0)
  
    const { buildingData, changeCategory, deleteService, buildingIndex, projectIndex, serviceIndex, responsibleList, zIndex, statusRef, searchDataPool, setSearchDataPool, data } = props
    const history = useHistory();
    const token = localStorage.getItem('authToken')
  
    useEffect(() => {
      if(data?.id) {
        if(data?.billing_start_time !== null) {
          let billingStartTime = data?.billing_start_time.split(":")
          setStartTimeHour(billingStartTime[0]);
          setStartTimeMinute(billingStartTime[1])
        }
        
        if(data?.billing_end_time !== null) {
          let billingEndTime = data?.billing_end_time.split(":")
          setEndTimeHour(billingEndTime[0]);
          setEndTimeMinute(billingEndTime[1]);
        }
  
        setInstancedData(data)
        setRenderDom(true)
      }
    }, [data])
  
    useEffect(() => {
      if(data?.assigned_admin_id !== null && responsibleList.length > 0) {
        const userName = responsibleList.filter( admin => admin.id === data?.assigned_admin_id)
        setResponsible(userName[0] && userName[0].firstName)
      }
    }, [data, responsibleList])
  
    // functions for animating and controlling the filters
    const handleStatusFilter = () => {
      if (!statusControlBlur) {
        if (!statusFilter) {
          nameFilterRef.current.focus()
        }
        setStatusControlBlur(true)
        setStatusFilter(!statusFilter)
      } else {
        setStatusControlBlur(false)
      }
    }
  
    const handleStatusFilterBlur = () => {
      setStatusFilter(false)
    }
  
    const handleResponsibleFilter = () => {
      if (!responsibleControlBlur) {
        if (!responsibleFilter) {
          responsibleFilterRef.current.focus()
        }
        setResponsibleConrtrolBlur(true)
        setResponsibleFilter(!responsibleFilter)
      } else {
        setResponsibleConrtrolBlur(false)
      }
    }
  
    const handleResponsibleFilterBlur = () => {
      setResponsibleFilter(false)
    }
  
    const handleDateFilter = () => {
      if (!dateControlBlur) {
        if (!dateFilter) {
          dateFilterRef.current.focus()
        }
        setDateControlBlur(true)
        setDateFilter(!responsibleFilter)
      } else {
        setDateControlBlur(false)
      }
    }
  
    const handleDateFilterBlur = () => {
      if (!mouseActive) {
        setDateFilter(false)
      }
    }
  
    // functions for input times and date mechanics
    const handleStartTimeHour = (event) => {
      let newData = JSON.parse(JSON.stringify(instancedData))
      if (event.target.validity.valid) {
        let newTime = event.target.value
        if (parseInt(newTime) > 23) {
          newTime = '23'
        }
        newData.billing_start_time = `${newTime}:${startTimeMinute}`;
        setInstancedData(newData)
        setStartTimeHour(newTime)
      } else {
        setStartTimeHour(startTimeHour)
      }
    }
  
    const handleStartTimeMinute = (event) => {
      let newData = JSON.parse(JSON.stringify(instancedData))
      if (event.target.validity.valid) {
        let newTime = event.target.value
        if (parseInt(newTime) > 59) {
          newTime = '59'
        }
        newData.billing_start_time = `${startTimeHour}:${newTime}`;
        setInstancedData(newData)
        setStartTimeMinute(newTime)
      } else {
        setStartTimeMinute(startTimeMinute)
      }
    }
  
    const handleEndTimeHour = (event) => {
      let newData = JSON.parse(JSON.stringify(instancedData))
      if (event.target.validity.valid) {
        let newTime = event.target.value
        if (parseInt(newTime) > 23) {
          newTime = '23'
        }
        newData.billing_end_time = `${newTime}:${endTimeMinute}`;
        setInstancedData(newData)
        setEndTimeHour(newTime)
      } else {
        setEndTimeHour(endTimeHour)
      }
    }
  
    const handleEndTimeMinute = (event) => {
      let newData = JSON.parse(JSON.stringify(instancedData))
      if (event.target.validity.valid) {
        let newTime = event.target.value
        if (parseInt(newTime) > 59) {
          newTime = '59'
        }
        newData.billing_end_time = `${endTimeHour}:${newTime}`;
        setInstancedData(newData)
        setEndTimeMinute(newTime)
      } else {
        setEndTimeMinute(endTimeMinute)
      }
    }
  
    function updateDate(value) {
      let newData = JSON.parse(JSON.stringify(instancedData))
      newData.billing_date = value
      setInstancedData(newData)
      setDate(value)  
    }
  
    function deleteServiceData() {
      deleteService(buildingIndex, projectIndex, serviceIndex, setUnmountState)
    }
  
    function setAdmin(id){
      axios.post(process.env.REACT_APP_API_URL+'/project_services/update_assigned_admin_id', {
        id: instancedData.id,
        assigned_admin_id: id
      }, {
        headers: {
          authorization: `bearer ${token}`
        }
      }).then(response => {
        statusRef.current.showCustomer();
        // succcess
      }).catch(error => {
      })
    }
  
    function updateDateTime(eventId) {
      axios.post(process.env.REACT_APP_API_URL+'/project_services/update_service_date', {
        id: instancedData.id,
        billing_date: new Date(date.split('-')[0],(date.split('-')[1]-1),date.split('-')[2],12),
        eventId:eventId
      }, {
        headers: {
          authorization: `bearer ${token}`
        }
      }).then(response => {
        let newData = JSON.parse(JSON.stringify(searchDataPool))
        const currentDate = new Date(date.split('-')[0],(date.split('-')[1]-1),date.split('-')[2],12);
        newData[serviceIndex].billing_date = `${currentDate.getFullYear()} / ${currentDate.getMonth() + 1} / ${currentDate.getDate()}`
        setSearchDataPool(newData)
        // success
      }).catch(error => {
      })
    }
  
    function updateStartTime(eventId) {
      axios.post(process.env.REACT_APP_API_URL+'/project_services/update_service_start_time', {
        id: instancedData.id,
        billing_start_time: `${startTimeHour}:${startTimeMinute}:00`,
      }, {
        headers: {
          authorization: `bearer ${token}`
        }
      }).then(response => {
        // success
      }).catch(error => {
      })
    }
  
    function updateEndTime(eventId) {
      axios.post(process.env.REACT_APP_API_URL+'/project_services/update_service_end_time', {
        id: instancedData.id,
        billing_end_time: `${endTimeHour}:${endTimeMinute}:00`,
      }, {
        headers: {
          authorization: `bearer ${token}`
        }
      }).then(response => {
        // success
      }).catch(error => {
      })
    }
  
    function saveEvent() {
      if(instancedData.eventId === null){
        const userDetails = JSON.parse( localStorage.getItem('loggedInUserDetails' ))
        let data = {
          user_id: userDetails.id,
          service_id: instancedData.service.id,
          message: "inspectie datum",
          email: instancedData?.project?.building?.user?.email,
          start_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
          end_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
          start_time: `${startTimeHour}:${startTimeMinute}:00`,
          end_time: `${endTimeHour}:${endTimeMinute}:00`,
          is_full_day: false,
          company_name: instancedData?.project?.building?.fileName,
          service_name: instancedData.service.title,
          skip_email: false
        }
        axios.post(process.env.REACT_APP_API_URL+'/events/add', 
          data, 
          {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
          if(response.status===200){
            updateDateTime(response.data.id);
            updateStartTime()
            updateEndTime()
            setDateControlBlur(false)
            setDateFilter(false)
            statusRef.current.showDatum();
          }
        }).catch((error) => {

        })
      }
      else{
        const userDetails = JSON.parse( localStorage.getItem('loggedInUserDetails' ))
        let data = {
          id: instancedData.eventId,
          user_id: userDetails?.id,
          service_id: instancedData?.service?.id,
          message: "inspectie datum",
          email: instancedData?.project?.building?.user?.email,
          start_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
          end_date: new Date(date.split('-')[0], (date.split('-')[1]-1), date.split('-')[2], 12),
          start_time: `${startTimeHour}:${startTimeMinute}:00`,
          end_time: `${endTimeHour}:${endTimeMinute}:00`,
          is_full_day: false,
          company_name: instancedData?.project?.building?.fileName,
          service_name: instancedData?.service?.title,
        }
        axios.post(process.env.REACT_APP_API_URL+'/events/update', 
          data, 
          {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
          if(response.status===200){
            updateDateTime(instancedData.eventId);
            updateStartTime()
            updateEndTime()
            setDateControlBlur(false)
            setDateFilter(false)
            statusRef.current.showDatum();
          }
        }).catch((error) => {

        })
      }
    }
  
    // function goToInvoice() {
    //   // first we check if an invoice status is 2 or above, which means it's created 
    //   if(instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].invoice_statuses_id === 1) {
    //     const token = localStorage.getItem("authToken")
    //     axios.post(process.env.REACT_APP_API_URL+`/invoice/update_invoice_status`, {
    //           facturate_id: instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id,
    //           invoice_statuses_id: 2,
    //           is_cancelled: false,
    //       }, {
    //         headers: {
    //           authorization: `bearer ${token}`,
    //         },
    //       }).then((response) => {
    //       if(response.data.success){
    //         // invoice has been succesfully created so we now route user to the invoice 
    //         if(instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].is_billing){
    //           history.push(`/invoice-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
    //         }
    //         else{
    //           history.push(`/quotations-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
    //         }        
    //       }
    //     }).catch(error => {
  
    //     })
    //   }
    //   else {
    //     // invoice already exists so just route to invoice 
    //     if(instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].is_billing){
    //       history.push(`/invoice-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
    //     }
    //     else{
    //       history.push(`/quotations-draft/${instancedData.projects[projectIndex].projectServices[serviceIndex].invoices[0].id}`)
    //     }
  
    // }
  
    // }
  
    function generateFileNumber() {
      let buildingId = instancedData?.project?.building?.id.toString();
      let serviceId = instancedData?.service?.id.toString();
      let newBuildingDigits = '0000'
      let newServiceDigits = '0000'
      newBuildingDigits = newBuildingDigits.slice(0, 4 - buildingId.length)
      let newbuildingId = newBuildingDigits.concat(buildingId)
      newServiceDigits = newServiceDigits.slice(0, 4 - serviceId.length)
      let newserviceId = newServiceDigits.concat(serviceId)
  
      return `${newbuildingId}-${newserviceId}`
    }  
  
    const classes = useStyles();
  
  
    return (
      <Grid container>
        {/* we use renderDom here to control when to render, we don't want to pass anything other than a complete object since objects are volatile and create exceptions */}
        {renderDom && (
          <Grid container>
            <Container maxWidth='lg'>
              
                <div className="services-details-name-box flex-row relative padding-right-10 ">
                <div
                  className={`absolute services-radio${
                    instancedData.invoices[0]?.is_billing
                      ? '-filled'
                      : '-outline'
                  }`}
                />
                  <div
                    className="services-details-name-color flex-shrink-disable"
                    style={{
                      background:
                        instancedData?.service_status_id === 0
                        ? '#B2D5FF'
                        : instancedData?.service_status_id === 1
                        ? '#508CD0'
                        : instancedData?.service_status_id === 2
                        ? '#9A8A55'
                        : instancedData?.service_status_id === 3
                        ? '#7BB84D'
                        : instancedData?.service_status_id === 4
                        ? '#686886'
                        : instancedData?.service_status_id === 5
                        ? '#B30419'
                        : instancedData?.service_status_id === 6
                        ? '#F2BBAD'
                        : instancedData?.service_status_id === 7
                        ? '#FBDCCE'
                        : instancedData?.service_status_id === 8
                        ? '#AFDFDB'
                        : instancedData?.service_status_id === 9
                        ? '#8cc9ba'
                        : '#8cc9ba'
                    }}
                  />
                  <div className='flex-column width-auto'>
                    <div className='flex-row width-auto '>
                    <div
                      className="height-auto width-auto flex-row cursor-pointer vertical-center"
                      
                    >
                      
                      <div className='flex-column ' onClick = {() => {history.push(`/details/${instancedData.id}-${instancedData?.project?.building?.id}`, instancedData) }}>
                        <p className="main-font-bold margin-top-5 font-size-12 font-color-black">
                          {instancedData?.service.title || ""}
                        </p>
                        <p className="main-font-bold margin-top-5 font-size-12 font-color-black">
                          Bedrijfsnaam {instancedData?.project?.building?.user?.company?.name || ""}
                        </p>  
                        <p className="main-font-bold margin-top-5 font-size-12 font-color-black">
                          {`${instancedData?.project?.building?.address} #${instancedData && generateFileNumber()}`}
                        </p> 
                      </div> 
                    </div>
                    <div className="services-details-icons cursor-pointer">
                      <img
                        src={require('../../../../assets/svg/icon/factuur.svg')}
                        alt="factuur"
                        className="height-auto width-auto object-fit-contain"
                        style = {{display: 'none'}}
                      />
                    </div>
                
                    <div
                      className="services-details-name-filter-container animatable-400 ease-in-out-quart"
                      ref={nameFilterRef}
                      tabIndex="2"
                      onBlur={handleStatusFilterBlur}
                      style={{
                        height: statusFilter ? '245px' : '10px',
                        opacity: statusFilter ? 1 : 0,
                      }}
                    >
                  <div className="flex-row width-auto horizontal-start">
                    <div className="services-details-name-filter-arrow">
                      <img
                        src={require('../../../../assets/svg/icon/corner.png')}
                        alt="triangle"
                        className="height-auto width-auto object-fit-contain"
                      />
                    </div>
                  </div>
                  <div className="services-details-name-filter vertical-center">
                    <Grid
                      container
                      item
                      sm={12}
                      direction="column"
                      alignItems="flex-end"
                    >
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setStatusFilter(false)
                          changeCategory(buildingIndex, projectIndex, serviceIndex, 1, setUnmountState)
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                          Ontvangen
                        </p>
                        <div className="services-details-name-filter-bubble background-color-faded-blue" />
                      </div>
                      <div className="flex-row">
                        <div className="services-details-name-filter-line" />
                      </div>
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setStatusFilter(false)
                          changeCategory(buildingIndex, projectIndex, serviceIndex, 2, setUnmountState)
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                          In behandeling
                        </p>
                        <div className="services-details-name-filter-bubble background-color-faded-tan" />
                      </div>
                      <div className="flex-row">
                        <div className="services-details-name-filter-line" />
                      </div>
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setStatusFilter(false)
                          changeCategory(buildingIndex, projectIndex, serviceIndex, 3, setUnmountState)
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                          Afgewerkt
                        </p>
                        <div className="services-details-name-filter-bubble background-color-faded-green" />
                      </div>
                      <div className="flex-row">
                        <div className="services-details-name-filter-line" />
                      </div>
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setStatusFilter(false)
                          changeCategory(buildingIndex, projectIndex, serviceIndex, 4, setUnmountState)
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                          On hold
                        </p>
                        <div className="services-details-name-filter-bubble background-color-faded-purple" />
                      </div>
                      <div className="flex-row">
                        <div className="services-details-name-filter-line" />
                      </div>
                      <div
                        className="flex-row vertical-center cursor-pointer"
                        onClick={() => {
                          setStatusFilter(false)
                          changeCategory(buildingIndex, projectIndex, serviceIndex, 5, setUnmountState)
                        }}
                      >
                        <p className="main-font-semi-bold font-size-14 font-color-black margin-right-15">
                          Geannuleerd
                        </p>
                        <div className="services-details-name-filter-bubble background-color-faded-pink" />
                      </div>
                    </Grid>
                  </div>
                </div>
                  </div>
                    <div className='flex-row margin-top-10 width-auto'>
                        <Grid container sm={6} xs={6} >
                          <p className='main-font-medium height-1 font-size-12 font-color-grey'>Tijdslijn</p>
                          <p className="main-font-medium font-size-12 font-color-black width-auto">
                            {instancedData?.form_progress || '-'}
                          </p>
                        </Grid>
  
                        <Grid container sm={6} xs={6}>
                          <div className='services-vertical-line'/>
                          <div className='padding-left-10 relative'>
                            <p className='main-font-medium font-size-12 font-color-grey'>Verantw.</p>
                            <div className="services-details-timeline-box vertical-center margin-top-10 " onClick={handleResponsibleFilter}>
                              <p className="margin-left-2 main-font-medium font-size-12 font-color-black">
                                {responsible}
                              </p>
                            </div>
                            <div
                              className="services-details-responsible-filter-container animatable-400 ease-in-out-quart" 
                              ref={responsibleFilterRef}
                              tabIndex="2"
                              onBlur={handleResponsibleFilterBlur}
                              style={{
                                transform: responsibleFilter
                                  ? `scaleY(1)`
                                  : 'scaleY(0)',
                                opacity: responsibleFilter ? 1 : 0,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start'
                              }}
                            >
                              <div className="flex-row width-auto">
                                <div className="services-details-responsible-filter-arrow">
                                  <img
                                    src={require('../../../../assets/svg/icon/corner.png')}
                                    alt="corner"
                                    className="height-auto width-auto object-fit-contain"
                                  />
                                </div>
                              </div>
                              <div className="services-details-responsible-filter margin-left-10" style = {{width: 'auto'}}>
                                <Grid
                                  container
                                  item
                                  sm={12}
                                  direction="column"
                                  alignItems="flex-end"
                                >
                                  {responsibleList.map((item, index) => (
                                    <>
                                      <div
                                        className="flex-row vertical-center cursor-pointer"
                                        onClick={() => {
                                          setAdmin(item.id)
                                          setResponsible(
                                          item.firstName,
                                          )
                                          setResponsibleFilter(false)
                                        }}
                                      >
                                        <p className="main-font-semi-bold font-size-14 font-color-black">
                                          {item.firstName}
                                        </p>
                                      </div>
                                      {index !== responsibleList.length - 1 && (
                                        <div className="flex-row">
                                          <div className="services-details-name-filter-line" />
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </Grid>
                              </div>
                            </div>
                          </div>
                        </Grid>
                    </div>
                    <div className='flex-row margin-top-10 width-auto'>
                        <Grid container sm={6} xs={6} >
                          <div>
                            <p className='main-font-medium font-size-12 font-color-grey '>Inspectie Datum</p>
                            <div className="services-details-timeline-box center-content margin-top-10" onClick={handleDateFilter}>
                              <p className="main-font-medium font-size-12 font-color-black">
                              {instancedData?.billing_date !== null ? typeof instancedData?.billing_date === 'string'
                                ? instancedData?.billing_date.substring(0, 10).replace(/-/g, "-" )
                                : date.getDate() +
                                  '-' +
                                  (date.getMonth() + 1) +
                                  '-' +
                                  date.getFullYear()
                                : 'Tijd'
                                }
                              </p>
                            </div>
                            <div
                              className="services-details-date-filter-container animatable-400 ease-in-out-quart cursor-pointer"
                              ref={dateFilterRef}
                              tabIndex="3"
                              onBlur={handleDateFilterBlur}
                              style={{
                                height: dateFilter ? '220px' : '10px',
                                opacity: dateFilter ? 1 : 0,
                                zIndex: 5
                              }}
                              onMouseOver={() => {
                                setMouseActive(true)
                              }}
                              onMouseLeave={() => {
                                setMouseActive(false)
                              }}
                            >
                              <div className="flex-row width-auto">
                                <div className="services-details-date-filter-arrow">
                                  <img
                                    src={require('../../../../assets/svg/icon/corner.png')}
                                    alt="corner"
                                    className="height-auto width-auto object-fit-contain"
                                  />
                                </div>
                              </div>
                              <div className="services-details-date-filter flex-column vertical-center">
                                {dateFilter && (
                                  
                                  <form className={classes.container} noValidate >
                                    <Grid container justify="center">
                                      <TextField
                                        id="date"
                                        type="date"
                                        value={date}
                                        onChange={(e)=>{updateDate(e.target.value)}}
                                        
                                        defaultValue={date}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                  </form>
                                
                                )
                                }
                                <Grid container justify='center'>
                                  <div className="width-auto flex-row margin-top-15 margin-left-25 horizontal-center">
                                  <div className="width-auto flex-column">
                                    <p className="main-font-light font-size-14 font-color-grey">
                                      Van
                                    </p>
                                    <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5">
                                      <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder = "12"
                                        maxLength={2}
                                        value={startTimeHour}
                                        onChange={handleStartTimeHour}
                                      />
                                      <p className="main-font-medium font-color-black font-size-14">
                                        :
                                      </p>
                                      <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder = "30"
                                        maxLength={2}
                                        value={startTimeMinute}
                                        onChange={handleStartTimeMinute}
                                      />
                                    </div>
                                  </div>
                                  <div className="width-auto flex-column">
                                    <p className="main-font-light font-size-14 font-color-grey">
                                      Tot
                                    </p>
                                    <div className="flex-row services-details-date-filter-input width-auto center-content margin-top-5">
                                      <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder = "14"
                                        maxLength={2}
                                        value={endTimeHour}
                                        onChange={handleEndTimeHour}
                                      />
                                      <p className="main-font-medium font-color-black font-size-14">
                                        :
                                      </p>
                                      <input
                                        className="main-font-medium font-color-black font-size-14 flex-row services-details-date-filter-input-sub"
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder = "30"
                                        maxLength={2}
                                        value={endTimeMinute}
                                        onChange={handleEndTimeMinute}
                                      />
                                    </div>
                                  </div>
                                </div>
                                </Grid>
                                <div className = "services-details-date-filter-save center-content cursor-pointer" onClick = {() => { saveEvent() }}>
                                  <p className = "main-font-semi-bold font-size-14 font-color-white">Save</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
  
                        <Grid container sm={6} xs={6} justify='space-between'>
                          <div className = 'flex-row width-auto'>
                            <div className='flex-row width-85-p'>
                              <div className='services-vertical-line'/>
                              <div className='flex-row vertical-center width-auto'>
                                <div className="center-content margin-left-10">
                                  <img
                                    src={require('../../../../assets/svg/icon/icon_folder_darkgrey.svg')}
                                    alt="folder icon"
                                    className="services-details-icon"
                                  />
                                  <p className="main-font-medium font-size-12 font-color-black">
                                    {instancedData?.no_of_documents || ""}
                                  </p>
                                </div>
                                <div className="center-content margin-left-10">
                                  <img
                                    src={require('../../../../assets/svg/icon/icon_chat_darkgrey.svg')}
                                    alt="chat icon"
                                    className="services-details-icon"
                                  />
                                  <p className="main-font-medium font-size-12 font-color-black">
                                    {instancedData?.no_of_messages || ""}
                                  </p>
                                </div>
                                
                              </div>
                            </div>
                            <div className={`services-details-icons cursor-pointer ${window.screen.width > 320 ? 'margin-top-20' : 'margin-top-5'}`} onClick = {() => { setDeleteModal(true) }}>
                                <img
                                  src={require('../../../../assets/svg/icon/trash.svg')}
                                  alt="trash"
                                  className="width-auto height-auto object-fit-contain"
                                />
                            </div>
                          </div>
                        </Grid>
                    </div>
                </div>
              </div>
             
            </Container>  
          </Grid>
        )}
        {deleteModal && (
          <DeleteConfirmation id = {1} setOpenState = {setDeleteModal} handleDeleteClick = {deleteServiceData} />
        )}
      </Grid>
    )
  }

  ServiceMobile.propTypes = {
    data: PropTypes.object,
  }
  
  ServiceMobile.defaultProps = {
    data: {
      id: 0,
      name: 'EPB-verslaggeving',
      file: 70234,
      file_name: 'Dossiernaam',
      customer: 'Bedrijfsnaam',
      responsible: '-',
      doc: 2,
      message: 6,
      price: '345',
      date: 'Tijd',
      timeline: 'Startverklaring',
      type: 1,
    },
  }
  
  ServiceMobile.propTypes = {
    data: PropTypes.object,
    responsibleList: PropTypes.array,
  }
  
  ServiceMobile.defaultProps = {
    data: {
      id: 0,
      name: 'EPB-verslaggeving',
      file: 70234,
      file_name: 'Dossiernaam',
      customer: 'Bedrijfsnaam',
      responsible: null,
      doc: 2,
      message: 6,
      price: '345',
      date: 'Tijd',
      timeline: 'Startverklaring',
      type: 1,
      responsible_list: [
        {
          id: 0,
          name: 'Cristine',
        },
        {
          id: 1,
          name: 'Albert',
        },
      ],
    },
    responsibleList: []
  }
  
  