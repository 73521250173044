import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import Header from '../dashboard/header/header'
import ResidenceAmboise from './components/residence_amboise/residence_amboise'
import ResidenceAmboiseActions from './components/residence_amboise_actions/residence_amboise_actions'
import ActionsInformation from './components/actions_information/actions_information'
import { isLocalDataValid } from '../shared/shared_functions';
import { EmailModal } from "./components/email_modal/email_modal";
import { DocumentSelector } from "./components/document_selector/document_selector";

import './_residence.scss'
import PremiumInfo from './components/premium_info/premium_info'

export default function Residence() {
  const [projectId, setProjectId] = useState()
  const [categoryId, setCategoryId] = useState(0)
  const [serviceId, setServiceId] = useState()
  const [projectServiceId, setProjectServiceId] = useState(0)
  const [buildingId, setBuildingId] = useState()
  const [update, setUpdate] = useState(false)
  const [serviceStatus,setServiceStatus]=useState(0)
  const [serviceFileNumber,setServiceFileNumber]=useState('-')
  const [responsibleList,setResponsibleList]=useState([])
  const [responsibleId,setResponsibleId]=useState(0)
  const [buildingDetails, setBuildingDetails] = useState({
    buildingName: '',
    buildingAddress: '',
    status:'',
    fileName:'',
    shift:'',
    typePand:'-',
    caseNumber:'',
    performer:'',
    phone:'',
    description:'',
    inspectionDate: '',
    inspectionStartTime: '',
    inspectionEndTime: '',
    contact: {},
  })
  var [isInfoTabEnabled, setInfoTabVisibility] = useState(true)
  var [isMsgTabEnabled, setMsgTabVisibility] = useState(false)
  var [isDocTabEnabled, setDocTabVisibility] = useState(false)
  const [ singleBuildingData, setSingleBuildingData ] = useState([]);

  const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'))
  const token = localStorage.getItem('authToken')
  const location = useLocation()
  const data = location.state
  const parameters = useParams()
  const history = useHistory();
  const emailModalRef = useRef(0);
  const documentSelectorRef = useRef(0)

  useEffect(() => {
    if(!isLocalDataValid()) {
      localStorage.clear();
      history.push("/")
  }

    let url = parameters.id.split('-')
    setProjectServiceId(url[0])
    setBuildingId(url[1])
    setProjectId(url[1])

    console.log("===> url",url)
    console.log("===> url0",url[0])
    console.log("===> url1",url[1])

  }, [parameters.id])

  useEffect(()=>{
    let buildingData = []
    let tempBuildingDetails = buildingDetails
    axios.post(process.env.REACT_APP_API_URL+'/user/all_admin', {}, {
      headers: {
        authorization: `bearer ${token}`
      }
    }).then(response => {
        setResponsibleList(response.data.data)
    }).catch(error => {
      if(error.response.status === 401) {
        localStorage.clear();
        history.push("/")
      }
    })
    let url = parameters.id.split('-')


    if (projectId > 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL+`/buildlings/get_by_id`,
          { id: url[1] },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data[0] !== null) {
            setSingleBuildingData(response.data[0])
            buildingData = response.data[0]
            tempBuildingDetails.buildingName=buildingData.title
            tempBuildingDetails.buildingAddress=buildingData.address
            tempBuildingDetails.status=serviceStatus
            tempBuildingDetails.fileName=buildingData.fileName
            tempBuildingDetails.shift= buildingData.shift
            tempBuildingDetails.typeOfProperty=buildingData.typeOfProperty
            tempBuildingDetails.zip=buildingData.zip
            tempBuildingDetails.city=buildingData.city
            tempBuildingDetails.caseNumber=serviceFileNumber
            tempBuildingDetails.performer=getResponsibleName(responsibleId)
            tempBuildingDetails.phone=userDetails.phone
            tempBuildingDetails.description=buildingData.description
            tempBuildingDetails.companyId = buildingData.companyId
            tempBuildingDetails.contact = buildingData.contact;
           if(buildingData.projects.length>0){
             setProjectId(buildingData.projects[0].id)
           }
            setBuildingDetails(tempBuildingDetails)
            setBuildingId(buildingData.id)
          }
        })
        .catch((error) => {})
      }
  },[projectId])

  useEffect(() => {
  }, [projectServiceId])

  useEffect(() => {
    // setCategoryId(categoryId)
  }, [categoryId])

  useEffect(() => {
    setBuildingId(buildingId)
  }, [buildingId])

  useEffect(()=>{},[projectId])

  useEffect(() => {
    setServiceId(serviceId)
    axios
    .post(
      process.env.REACT_APP_API_URL+`/service/get_by_id`,
      { id:serviceId },
      { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        let tempBuildingDetails=JSON.parse(JSON.stringify(buildingDetails))
        tempBuildingDetails.shift=response.data.title
        tempBuildingDetails.status=serviceStatus
        tempBuildingDetails.caseNumber=serviceFileNumber
        tempBuildingDetails.performer=getResponsibleName(responsibleId)
        let projectServiceData = singleBuildingData.projects[0].projectServices.filter(data => data.id === projectServiceId)
        tempBuildingDetails.inspectionDate = projectServiceData[0].billing_date
        tempBuildingDetails.inspectionStartTime = projectServiceData[0].billing_start_time
        tempBuildingDetails.inspectionEndTime = projectServiceData[0].billing_end_time
        setBuildingDetails(tempBuildingDetails)
      })
      .catch((error) => {
      })
  }, [serviceId,serviceStatus,responsibleId,serviceFileNumber])

  useEffect(() => {
  }, [buildingDetails])

  useEffect(() => {
    let tempBuildingDetails = JSON.parse(JSON.stringify(buildingDetails))
    console.log("buildingDetails : ",buildingDetails)
    console.log("singleBuildingData : ",singleBuildingData)


    if( singleBuildingData.id !== undefined ){

      setBuildingDetails(tempBuildingDetails)
    }
  }, [projectServiceId])

  const updateCategoryId = (id) => {
    setCategoryId(id)
    setUpdate(!update)
  }
  const updateServiceId = (id, servicesMetaTitle) => {

    var tempBuildingDetails={...buildingDetails}
    tempBuildingDetails.typePand=servicesMetaTitle == null ? null:servicesMetaTitle
    setBuildingDetails(tempBuildingDetails)

    setServiceId(id)
  }

  function getResponsibleName(id){
    let name='-'
    if(responsibleList.length>0){
      responsibleList.forEach(item=>{
        if(item.id===id){
          name=item.firstName+' '+item.lastName
        }
      })
    }
    return name
  }

  const handleTabChange = (value) => {
    setInfoTabVisibility(
      value === 'info' ? (isInfoTabEnabled = true) : (isInfoTabEnabled = false),
    )
    setMsgTabVisibility(
      value === 'emails' ? (isMsgTabEnabled = true) : (isMsgTabEnabled = false),
    )
    setDocTabVisibility(
      value === 'documents'
        ? (isDocTabEnabled = true)
        : (isDocTabEnabled = false),
    )
  }

  return (
    <Grid
      xs={12}
      md={12}
      className="residence-grid-height height-auto background-color-faded-faint-grey "
    >
      <Grid container>
        <Header isLogoEnabled toShowHamburger={true} />
      </Grid>
      <Grid container justify="flex-start" className='background-color-faded-faint-grey padding-10'>
        <Grid container justify = "center">
          <Grid container>
            <div className="margin-top-13" />
          </Grid>
          <Grid container item xs={12} sm={12} md={12} className = "relative">
            <Grid container item xs={12} sm={12} md={6} lg={6} justify="center">
                <ResidenceAmboise
                  buildingData={buildingDetails}
                  projectId={projectId}
                  sendCategoryId={updateCategoryId}
                  categoryId = {categoryId}
                  token={token}
                  sendData={data.service}
                />
                <ResidenceAmboiseActions
                  projectId={projectId}
                  categoryId={categoryId}
                  update={update}
                  sendServiceId={updateServiceId}
                  token={token}
                  sendData={data.service}
                  projectServiceId={projectServiceId}
                  setProjectServiceId={setProjectServiceId}
                  serviceStatus={serviceStatus}
                  setServiceStatus={setServiceStatus}
                  setFileNumber={setServiceFileNumber}
                  setResponsibleId={setResponsibleId}
                  urlServiceData = {data}
                />
            </Grid>
            <Grid container item xs={12} sm={12} md={6} lg={6} justify="center" className = 'animatable-400 fade-in-translate'>
              {
                categoryId === 8 ?
                  <PremiumInfo setCategoryId = {setCategoryId} handleChange = {handleTabChange}/>
                :
                <ActionsInformation
                  token={token}
                  buildingId={buildingId}
                  serviceId={serviceId}
                  projectId={projectId}
                  projectServiceId={projectServiceId}
                  buildingData={buildingDetails}
                  singleBuildingData = {singleBuildingData}
                  handleChange = {handleTabChange}
                  isInfoTabEnabled = {isInfoTabEnabled}
                  isMsgTabEnabled = {isMsgTabEnabled}
                  isDocTabEnabled = {isDocTabEnabled}
                  emailModalRef = {emailModalRef}
                  documentSelectorRef = {documentSelectorRef}
                  urlServiceData = {data}
                />
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DocumentSelector ref = {documentSelectorRef} emailModalRef = {emailModalRef} />
      <EmailModal ref = {emailModalRef} buildingData = {buildingDetails} buildingId = {buildingId} projectServiceId = {projectServiceId} />
    </Grid>
  )
}
