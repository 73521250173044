import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { debounce } from "lodash";
import { Grid, Hidden, Dialog, makeStyles } from '@material-ui/core'
import './_invoice_all.scss'
import MobileView from "../../mobile_view/mobile_view"
import Header from '../../header/header.js'
import SearchIcon from '../../../../assets/png/icon/search_icon.png'
import ViewIcon from '../../../../assets/png/icon/view.png'
import axios from 'axios'
import { invoiceHeaders } from '../../../shared/sample_data'
import PropTypes from 'prop-types'
import ArrowDown from '../../../../assets/svg/icon/arrow_down_grey.svg'
import ArrowUp from '../../../../assets/svg/icon/arrow_up_grey.svg'
import CheckIcon from '../../../../assets/svg/icon/check_grey.svg'
import TrashIcon from '../../../../assets/svg/icon/trash.svg'
import FactuurIcon from '../../../../assets/svg/icon/factuur.svg'
import LoaderIcon from '../../../../assets/svg/loader.svg'
import CrossIcon from '../../../../assets/svg/icon/cross.svg'
import EuroIcon from '../../../../assets/svg/euro.svg'
import InvoiceNew from "../invoice_new/invoice_new";

export default function InvoiceAll() {
  const [originalDataState, setOriginalDataState] = useState([])
  const [filteredDataState, setFilteredDataState] = useState([])
  const [filterByState, setFilterByState] = useState(1)
  const [headerState, setHeaderState] = useState([])
  const [sortByState, setSortByState] = useState({
    id: 1,
    title: 'Dienst',
    asc: true,
  })
  const [forceUpdateState, setForceUpdateState] = useState(false)
  const [viewInvoiceAllState, setViewInvoiceAllState] = useState(false)
  const [viewDataState, setViewDataState] = useState(null)
  const [ newFacturateState, setNewFacturateState ] = useState(false)
  const [ adminList, setAdminList ] = useState([]);
  const [ loader, setLoader ] = useState(false)

  const history = useHistory()
  const token = localStorage.getItem('authToken')
  const searchRef = useRef(0)

  useEffect(() => {
    axios.post(process.env.REACT_APP_API_URL+'/user/all_admin', {}, {
      headers: {
        authorization: `bearer ${token}`,
      }
    }).then(response => {
      const list = response.data.data.filter(admin => admin.roleId === 4)
      setAdminList(list)
    })
  }, [])

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL+'/invoice/all',
        {
          is_billing: true,
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        let data=response.data
        let tempInvoiceAllData=[]
        data.forEach(item=>{
          if(item.projectServices!==null && item.companyBilling!==null){
          var invoiceDetails = {}
            invoiceDetails['projectServiceId']=item.project_service_id
            invoiceDetails['companyId']=item.companyBilling.companyId
            invoiceDetails['facturateNumber']= item.id
            invoiceDetails['is_cancelled'] = item.is_cancelled;
            invoiceDetails['companyBillingId']=item.companyBilling.id
            invoiceDetails['shift']= item.projectServices.service.title
            invoiceDetails['admin']= item.assigned_admin_id
            invoiceDetails['number'] = item.invoices_nr
            invoiceDetails['customer']= item.companyBilling.companyName
            invoiceDetails['date']= item.updatedAt
            invoiceDetails['status']= item.projectServices.service_status_id
            invoiceDetails['invoiceStatusId']=item.invoice_statuses_id
            tempInvoiceAllData.push(invoiceDetails)
        }
      })
        setHeaderState(invoiceHeaders)
        setOriginalDataState(tempInvoiceAllData)
        setFilteredDataState(tempInvoiceAllData)
      })
      .catch((error) => {})

  }, [forceUpdateState])

  useEffect(() => {
    onSearchChange()
  }, [originalDataState, filterByState, sortByState, forceUpdateState])

  function convertDigits(value) {
    let newDigits = [0, 0, 0, 0]
    let newValue = value.toString().split('');
    newValue = newDigits.concat(newValue);
    newValue.splice(0, newValue.length - 4)
    return newValue.join("")
  }
  
  const getFilteredData = debounce(() => {
    const filterPromise = new Promise((resolve, reject) => {
      const resolved = true;
      if(resolved) {
        setLoader(true);
        resolve('success');
      }
      else {
        reject('failed')
      }
    })

    filterPromise.then(() => {
      const originalData = [...originalDataState]
      const searchData = searchRef.current?.value?.toLowerCase() || ""
      let filteredData = []
      if (originalData) {
        if (filterByState === 2) {
          filteredData = originalData.filter((a) => a.invoiceStatusId === 2)
        } else if (filterByState === 3) {
          filteredData = originalData.filter((a) => a.invoiceStatusId === 3)
        } else if (filterByState === 4) {
          filteredData = originalData.filter((a) => a.invoiceStatusId === 4)
        } else {
          filteredData = originalData
        }
        filteredData = filteredData.filter((a) => (a.shift && a.shift.toLowerCase().includes(searchData.toLowerCase())) || (a.number && a.date && `${a.date.substring(0, 4)}-${convertDigits(a.number)}`.includes(searchData.toLowerCase())) || (a.customer && a.customer.toLowerCase().includes(searchData.toLowerCase())) || (a.admin && adminList.filter(admin => admin.id === a.admin)[0]?.firstName.toLowerCase().includes(searchData.toLowerCase())) || (a.date && a.date.toLowerCase().includes(searchData.toLowerCase())))
        if (filteredData !== undefined) {
          getSortedList(filteredData)
        } else {
          getSortedList([])
        }
        setLoader(false)
      }
    }).catch(error => {
    })
  }, 500)

  const onSearchChange = () => {
    getFilteredData()
  }

  const handleFilterByClick = (value) => {
    if (value !== filterByState) {
      setFilterByState(value)
    }
  }
  const handleHeaderClick = (item) => {
    if (item !== null && item !== undefined) {
      const prevState = { ...sortByState }
      if (prevState.id !== item.id) {
        setSortByState({ id: item.id, title: item.title, asc: true })
      } else {
        if (prevState.asc === true)
          setSortByState({ id: item.id, title: item.title, asc: false })
        else setSortByState({ id: item.id, title: item.title, asc: true })
      }
    } else {
      setSortByState({ id: 1, title: 'Dienst', asc: true })
    }
  }
  const getSortedList = (filteredData) => {
    
    const sortBy = { ...sortByState }
    let sortedData = []
    if (filteredData !== undefined && filteredData.length > 0) {
      if (sortBy !== {} && sortBy !== null) {
        if (sortBy.title.includes('Dienst')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.shift !== null
                ? a.shift.localeCompare(b.shift !== null ? b.shift : '')
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.shift !== null
                ? b.shift.localeCompare(a.shift !== null ? a.shift : '')
                : '',
            )
        } else if (sortBy.title.includes('Facturate nr.')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.number
                ? a.number.toString().localeCompare(b.number ? b.number : '0')
                : '0'.localeCompare(b.number ? b.number : '0'),
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.number
                ? b.number.toString().localeCompare(a.number ? a.number : '0')
                : '0'.localeCompare(a.number ? a.number : '0'),
            )
        } else if (sortBy.title.includes('admin')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.admin
                ? a.admin.toString().localeCompare(
                    b.admin ? b.admin : 'zzz',
                  )
                : 'zzz'.localeCompare(
                  b.admin ? b.admin : 'zzz',
                ),
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.admin
                ? b.admin.toString().localeCompare(
                    a.admin ? a.admin : 'zzz',
                  )
                : 'zzz'.localeCompare(
                  a.admin ? a.admin : 'zzz',
                ),
            )
        } else if (sortBy.title.includes('Klant')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.customer !== null
                ? a.customer.localeCompare(
                    b.customer !== null ? b.customer : '',
                  )
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.customer !== null
                ? b.customer.localeCompare(
                    a.customer !== null ? a.customer : '',
                  )
                : '',
            )
        } else if (sortBy.title.includes('Status')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a,b)=> 
            b.status && a.status ?
            a.status -b.status : a.status - b.status
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.status && a.status
                ? b.status - a.status
                : b.status - a.status,
            )
        } else if (sortBy.title.includes('Laatste update')) {
          if (sortBy.asc)
            sortedData = filteredData.sort((a, b) =>
              a.date !== null
                ? a.date.localeCompare(b.date !== null ? b.date : '')
                : '',
            )
          else
            sortedData = filteredData.sort((a, b) =>
              b.date !== null
                ? b.date.localeCompare(a.date !== null ? a.date : '')
                : '',
            )
        }
      } else {
        sortedData = filteredData.sort((a, b) => a.shift)
      }
    }
    setFilteredDataState(sortedData)
  }

  const handleDeleteClick = (id) => {
    if (id > 0) {
      axios
      .post(
        process.env.REACT_APP_API_URL+'/invoice/delete',
        {
          facturate_id:id
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if(response.data.success){
          
          setForceUpdateState(!forceUpdateState)
        }
      })
      .catch(error=>{})
    }
  }
  
  const handleViewClick = (data) => {
    if (data.projectServiceId > 0) {
      history.push(`/invoice-draft/${data.facturateNumber}`, data)
    }
  }

  const handleEuroClick=(facturateNumber, is_cancelled)=>{
    axios
      .post(
        process.env.REACT_APP_API_URL+`/invoice/update_invoice_status`,
        {
            facturate_id: facturateNumber,
            invoice_statuses_id:2,
            is_billing: true,
            is_cancelled
        },
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if(response.data.success){
          history.push(`/invoice-draft/${facturateNumber}`,)
          setForceUpdateState(!forceUpdateState)
        }
      })
      .catch(error=>{})
  }

  return (
    <Grid container>
      <Grid container>
          <Hidden smDown>
              <Header />
          </Hidden>
          <Hidden smUp>
              <Header toShowHamburger />
          </Hidden>
      </Grid>
      <Hidden smUp>
        <MobileView />
      </Hidden>
      <Hidden xsDown>
        <div className="invoice-all-main-div">
          {originalDataState.length > 0 && originalDataState ? (
            <Grid container item xs={12} className="height-disable fade-in-right">
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <div className="invoice-all-search-bar">
                    <img
                      src={SearchIcon}
                      alt=""
                      className="invoice-all-search-icon"
                    />
                    <input
                      type="text"
                      placeholder="Zoeken"
                      className="width-auto font-color-black margin-left-5"
                      onChange={(event) => onSearchChange(event)}
                      ref = {searchRef}
                    />
                  </div>
                </Grid>
                <Hidden smDown>
                  <div className="invoice-all-vertical-div">
                    <span className="invoice-all-vertical-line-blue"></span>
                  </div>
                </Hidden>
                <div className="invoice-all-filter-div main-font-regular font-size-14">
                  <button
                    className={
                      filterByState === 1
                        ? 'invoice-all-filter-active'
                        : 'invoice-all-filter-inactive'
                    }
                    onClick={() => handleFilterByClick(1)}
                  >
                    Alles
                  </button>
                </div>
                <div className="invoice-all-filter-div main-font-regular font-size-14">
                  <button
                    className={
                      filterByState === 2
                        ? 'invoice-all-filter-active'
                        : 'invoice-all-filter-inactive'
                    }
                    onClick={() => handleFilterByClick(2)}
                  >
                    Draft
                  </button>
                </div>
                <div className="invoice-all-filter-div main-font-regular font-size-14">
                  <button
                    className={
                      filterByState === 3
                        ? 'invoice-all-filter-active'
                        : 'invoice-all-filter-inactive'
                    }
                    onClick={() => handleFilterByClick(3)}
                  >
                    Verzonden
                  </button>
                </div>
                <div className="invoice-all-filter-div main-font-regular font-size-14">
                  <button
                    className={
                      filterByState === 4
                        ? 'invoice-all-filter-active'
                        : 'invoice-all-filter-inactive'
                    }
                    onClick={() => handleFilterByClick(4)}
                  >
                    Betaald
                  </button>
                </div>
                <div className = "invoice-all-new-facturate relative">
                  <button type = "button" className = "invoice-all-new-facturate-button center-content main-font-semi-bold font-size-14 font-color-white cursor-pointer" onClick = {() => { setNewFacturateState(!newFacturateState) }} >Nieuwe Facturatie</button>
                  <div className = "invoice-all-new-facturate-modal-container animatable-400 ease-in-out-quart" style = {{transform: newFacturateState ? 'scale(1)' : 'scale(0)', opacity: newFacturateState ? 1 : 0}}>
                    <div className = "invoice-all-new-facturate-modal">
                      <InvoiceNew />
                    </div>
                  </div>
                </div>
              </Grid>
              {!loader ? (
                <>
                <Grid container item xs={12}>
                  <div className="invoice-all-header-main vertical-center">
                    {headerState !== null && headerState !== undefined
                      ? headerState.map((header) => {
                          return (
                            <div
                              key={header.id}
                              onClick={
                                header.title !== ''
                                  ? () => handleHeaderClick(header)
                                  : null
                              }
                              className={
                                ` ${header.id === 1 
                                  ? 'invoice-all-header-item-lg'
                                  : header.id === 2 ||
                                    header.id === 3 ||
                                    header.id === 6
                                  ? 'invoice-all-header-item-sm'
                                  : header.id === 7 ? '' : 'invoice-all-header-item'}`
                              }
                            >
                              <span className="main-font-light font-size-14 font-color-grey">
                                {header.title}
                              </span>
                              {sortByState !== null ? (
                                sortByState.id === header.id &&
                                sortByState.asc === true ? (
                                  <span className="font-color-grey">
                                    <img
                                      src={ArrowDown}
                                      alt=""
                                      className="invoice-all-arrow-icon margin-top-2 margin-left-5"
                                    />
                                  </span>
                                ) : sortByState.id === header.id &&
                                  sortByState.asc === false ? (
                                  <span className="font-color-grey">
                                    <img
                                      src={ArrowUp}
                                      alt=""
                                      className="invoice-all-arrow-icon margin-top-2 margin-left-5"
                                    />
                                  </span>
                                ) : null
                              ) : null}
                            </div>
                          )
                        })
                      : null}
                  </div>
                  <div className='invoice-all-second-part-width'/>
                </Grid>
                <Grid container>
                  <div className="invoice-all-card-container flex-column width-auto">
                    {filteredDataState !== null && filteredDataState !== undefined ? (
                      filteredDataState.length > 0 ? (
                        filteredDataState.map((item, index) => {
                          return index % 2 === 0 ? (
                            <Grid
                              container
                              key={item.id}
                              className={`invoice-all-card-main background-color-white`}
                            >
                              <CardDetail
                                adminList = {adminList}
                                data={item}
                                handleDeleteClick={handleDeleteClick}
                                handleViewClick={handleViewClick}
                                handleEuroClick={handleEuroClick}
                              />
                            </Grid>
                          ) : (
                            <Grid
                              container
                              key={item.id}
                              className={`invoice-all-card-main background-color-transparent`}
                            >
                              <CardDetail
                                adminList = {adminList}
                                data={item}
                                handleDeleteClick={handleDeleteClick}
                                handleViewClick={handleViewClick}
                                handleEuroClick={handleEuroClick}
                              />
                            </Grid>
                          )
                        })
                      ) : (
                        <React.Fragment></React.Fragment>
                      )
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </Grid>
                </>
              ) : (
                <div className = "height-auto width-auto center-content">
                  <img src = {require('../../../../assets/svg/icon/loading.gif')} alt = "loader" className = "services-loader" />
                </div>
              )}
            </Grid>
          ) : (
            <div className = "height-auto width-auto center-content">
              <img src = {require('../../../../assets/svg/icon/loading.gif')} alt = "loader" className = "services-loader" />
            </div>
          )}
          {viewInvoiceAllState === true ? (
            <InvoiceAllView
              data={viewDataState}
              setOpenState={setViewInvoiceAllState}
            />
          ) : null}
        </div>
      </Hidden>
    </Grid>
  )
}

const CardDetail = (props) => {
  const { data, handleDeleteClick, handleViewClick,handleEuroClick, adminList } = props
  const [animateOutState, setAnimateOutState] = useState(false)
  const [animateInState, setAnimateInState] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setAnimateInState(false)
    }, 500)
  }, [])

  function convertDigits(value) {
    let newDigits = [0, 0, 0, 0]
    let newValue = value.toString().split('');
    newValue = newDigits.concat(newValue);
    newValue.splice(0, newValue.length - 4)
    return newValue.join("")
  }
  // const handleSwitch = () => {
  //   setAnimateOutState(true)
  //   handleSwitchClick(data.id)
  //   setTimeout(() => {
  //     setAnimateOutState(false)
  //   }, 500)
  // }

  return (
    <div
      className={`height-auto width-auto vertical-center 
               ${animateInState === true ? 'slide-in-right' : ''} ${
        animateOutState === true ? 'slide-out-left' : ''
      }`}
    >
    <div className='invoice-all-first-part-width'>
      <div className="invoice-all-card-item-lg">
        <p className="main-font-regular font-size-14 font-color-black invoice-all-word-break">
          {data.shift !== null && data.shift !== undefined ? data.shift : ''}
        </p>
      </div>
      <div className="invoice-all-card-item-sm">
        <p className="main-font-regular font-size-14 font-color-black invoice-all-word-break">
          {data.number && data.date ? `${data.date.substring(0, 4)}-${convertDigits(data.number)}` : '-'}
        </p>
      </div>
      <div className="invoice-all-card-item-sm">
        <p className="main-font-regular font-size-14 font-color-black invoice-all-word-break">
          {data.admin
            ? (adminList.length > 0 ? adminList.filter(admin => admin.id === data.admin)[0]?.firstName || "-" : '-')
            : '-'}
        </p>
      </div>
      <div className="invoice-all-card-item">
        <p className="main-font-regular font-size-14 font-color-black invoice-all-word-break">
          {data.customer !== null && data.customer !== undefined
            ? data.customer
            : ''}
        </p>
      </div>
      <div className="invoice-all-card-item">
        <div
          className={`invoice-all-status-${
            data.status !== null &&
            data.status !== undefined &&
            data.status === 0
              ? 'blue'
              : data.status === 1
              ? 'yellow'
              : data.status === 2
              ? 'green'
              : data.status === 3
              ? 'purple'
              : 'red'
          }-dot`}
        />
        <p className="main-font-regular font-size-14 font-color-black invoice-all-word-break margin-left-10">
          {data.status !== null && data.status !== undefined ? data.status ===0 ? 'Ontvangen' : data.status === 1 ? 'In behandeling' : data.status === 2 ? 'Afgewerkt' : data.status === 3 ?'On hold':'Geannuleerd' : ''}
        </p>
      </div>
      <div className="invoice-all-card-item-sm">
        <p className="main-font-regular font-size-14 font-color-black invoice-all-word-break">
          {data.date !== null && data.date !== undefined ? data.date : ''}
        </p>
      </div>
      </div>
      <div className="invoice-all-vertical-line-blue "></div>
      <div className='invoice-all-second-part-width vertical-center'>
        {/* <div className="invoice-all-card-item-lg "> */}
        {(data.invoiceStatusId === 1 || data.invoiceStatusId === null) ? (
          <div className="invoice-all-status-div font-color-grey ">
            <span className="main-font-regular font-size-14 ">Aanmaken</span>
          </div>
        ) : data.invoiceStatusId === 2 ? (
          <div className="invoice-all-status-div flex-row font-color-grey ">
            <span className="main-font-regular font-size-14 ">Draft</span>
            <img
              src={LoaderIcon}
              alt=""
              className="invoice-all-symbol-icon margin-top-2 "
            />
          </div>
        ) : data.invoiceStatusId === 3 ? (
          <div className="invoice-all-status-div font-color-grey ">
            <span className="main-font-regular font-size-14 ">Verzonden</span>
            <img
              src={CheckIcon}
              alt=""
              className="invoice-all-symbol-icon margin-top-2"
            />
          </div>
        ) : data.invoiceStatusId === 4 ? (
          <div className="invoice-all-status-div font-color-grey ">
            <span className="main-font-regular font-size-14 ">Betaald</span>
            <img
              src={EuroIcon}
              alt=""
              className="invoice-all-symbol-icon margin-top-2"
            />
          </div>
        ) : data.invoiceStatusId===5?(
          <div className="invoice-all-status-div flex-row font-color-grey ">
            <span className="main-font-regular font-size-14 ">
              Geannuleerd
            </span>
            <img
              src={EuroIcon}
              alt=""
              className="invoice-all-symbol-icon margin-top-2 "
            />
          </div>
        ) : (
          <div className="invoice-all-status-div flex-row font-color-grey ">
            <span className="main-font-regular font-size-14 ">
              Kredietnota
            </span>
            <img
              src={EuroIcon}
              alt=""
              className="invoice-all-symbol-icon margin-top-2 "
            />
          </div>
        )
      }
        {(data.invoiceStatusId === 1 || data.invoiceStatusId === null) && (
          <button className="cursor-pointer " onClick={()=>{handleEuroClick(data.facturateNumber, data.is_cancelled)}}>
            <img src={FactuurIcon} alt="" className="invoice-all-button-icon" />
          </button>
        )}
        {(data.invoiceStatusId !== 1 && data.invoiceStatusId !== null) && (
          <button
            className="cursor-pointer"
            onClick={() => handleViewClick(data)}
          >
            <img src={ViewIcon} alt="" className="invoice-all-button-icon" />
          </button>
        )}

        <button
          className="cursor-pointer"
          onClick={() => handleDeleteClick(data.facturateNumber)}
        >
          {data.invoiceStatusId === 2 ? (
            <img src={TrashIcon} alt="" className="invoice-all-button-icon" />
          ) : (
            <div className="margin-right-35" />
          )}
        </button>
      </div>
      </div>
    // </div>
  )
}

const useStyles = makeStyles({
  paper: {
    width: '60%',
    maxHeight: '80%',
    borderRadius: '10px',
  },
})

const InvoiceAllView = (props) => {
  const { data, setOpenState } = props
  const classes = useStyles()
  const [popupState, setPopupState] = useState(true)

  const handleButtonClick = () => {
    setPopupState(false)
    if (setOpenState !== undefined && setOpenState !== null) {
      setOpenState(false)
    }
  }
  return (
    <React.Fragment>
      {data !== null && data !== {} ? (
        <Dialog
          open={popupState}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="sm"
          scroll="paper"
          classes={{ paper: classes.paper }}
        >
          <div className="horizontal-end">
            <div className="margin-top-10 margin-right-10">
              <button onClick={handleButtonClick} className="cursor-pointer">
                <img
                  src={CrossIcon}
                  alt=""
                  className="invoice-all-symbol-icon margin-top-2 margin-left-5"
                />
              </button>
            </div>
          </div>
          <div className="width-auto height-auto flex-column padding-left-30 padding-bottom-25 padding-right-15">
            <div className="flex-column font-size-14 margin-top-20">
              <span className="main-font-light font-color-dark-grey">
                Notificatie
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-5">
                {data.title !== null ? data.title : ''}
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-25">
              <span className="main-font-light font-color-dark-grey">
                Bedrijf
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-5">
                {data.companyName !== null ? data.companyName : ''}
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-25">
              <span className="main-font-light font-color-dark-grey">
                Email
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-5">
                {data.email !== null ? data.email : ''}
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-25">
              <span className="main-font-light font-color-dark-grey">
                Datum
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-5">
                {data.date !== null ? data.date : ''}
              </span>
            </div>
            <div className="flex-column font-size-14 margin-top-25">
              <span className="main-font-light font-color-dark-grey">
                Bericht
              </span>
              <span className="main-font-semi-bold font-color-black margin-top-5">
                {data.message !== null ? data.message : ''}
              </span>
            </div>
          </div>
        </Dialog>
      ) : null}
    </React.Fragment>
  )
}

CardDetail.propTypes = {
  data: PropTypes.shape({}),
  handleSwitchClick: PropTypes.func,
  handleViewClick: PropTypes.func,
}
CardDetail.defaultProps = {
  data: {},
  handleSwitchClick: function () {},
  handleViewClick: function () {},
}

InvoiceAllView.propTypes = {
  data: PropTypes.shape({}),
  setOpenState: PropTypes.func,
}

InvoiceAllView.defaultProps = {
  data: {},
  setOpenState: function () {},
}
