import React, { useState, useEffect, createRef, useRef, useImperativeHandle, forwardRef } from 'react'
import { Grid } from '@material-ui/core'
import axios from 'axios'
import './_step_four.scss'
import { CustomInput } from '../../../shared_components/custom_input/custom_input'
import Popup from '../../../shared_components/custom_popup/custom_popup'

export default function StepFour(props) {
  const { token,isButtonClick, epbFormId, stepFourData, projectServiceId, userRole } = props
  const [fileDetailsRef, setFileDetailsRef] = useState([])
  const [roofIsolationFiles, setRoofIsolationFiles] = useState([])
  const [ventilationApplianceFiles, setVentilationApplianceFiles] = useState([])
  const [installationFiles, setInstallationFiles] = useState([])
  const [heatingFiles, setHeatingFiles] = useState([])
  const [PD1Files, setPD1Files] = useState([])
  const [M1Files, setM1Files] = useState([])
  const [HD1Files, setHD1Files] = useState([])
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})
  const [infoHoverNumber, setInfoHoverNumber] = useState(0)
  const screenWidth = window.screen.width

  useEffect(() => {
    const tempArray = []
    Array(7)
      .fill()
      .map(() => {
        tempArray.push(createRef(0))
      })
    setFileDetailsRef(tempArray)
  },[])
  
  useEffect(() => {
  if (stepFourData.length>0) {
      stepFourData.sort(function (a, b) {
        return -(b.id - a.id || b.name.localeCompare(a.name))
      })
      stepFourData.forEach(item=>{
        if(item.id===1){
          setRoofIsolationFiles(item.ebp_supporting_pieces)
        }
        else if(item.id===2){
          setVentilationApplianceFiles(item.ebp_supporting_pieces)
        }
        else if(item.id===3){
          setInstallationFiles(item.ebp_supporting_pieces)
        }
        else if(item.id===4){
          setHeatingFiles(item.ebp_supporting_pieces)
        }
        else if(item.id===5){
          setPD1Files(item.ebp_supporting_pieces)
        }
        else if(item.id===6){
          setM1Files(item.ebp_supporting_pieces)
        }
        else if(item.id===7){
          setHD1Files(item.ebp_supporting_pieces)
        }
      })
    }
  }, [stepFourData])

useEffect(() => {
  if (fileDetailsRef.length > 0) {
      fileDetailsRef.map((item) => {
        item.current.getValue().map((element) => {
          axios
            .post(
              process.env.REACT_APP_API_URL+`/ebp_form/epb_step4_store`,
              {
                ebp_form_id: epbFormId,
                record_id: element.id,
                ebp_supporting_pieces_category_id: element.ebp_supporting_pieces_category_id,
                effective_implementation:element.effective_implementation
              },
              {
                headers: {
                  authorization: `bearer ${token}`,
                },
              },
            )
            .then((response) => {
              if (response.data.message === 'Success!') {
                let responseMessage = {
                  isSuccess: true,
                  heading: 'Succesrecord opgeslagen',
                  message: '',
                  popupState: setShowPopupState
                }
                setShowPopupState(true)
                setPopupMessageState(responseMessage)
              }
            })
            .catch((error) => {})
        })
      })
    }
  }, [isButtonClick])

  return (
    <Grid container className= {`animatable-400 fade-in-translate width-auto ${screenWidth > 600 ? 'height-77-p overflow-hidden' : '' }`}>
      <Grid container>
        <Grid xs={1} sm={1} md={1} />
        <Grid container xs={10} sm={10} md={10}>
          <p className="step-three-description vietnam-regular">
            De stavingsstukken van de gebruikte materialen, installaties en
            technieken zijn de bouwstenen van uw EPB-dossier en van groot belang
            bij deze eindberekening (ook eindaangifte genoemd). Op basis van de
            ontvangen stavingsstukken zal de verslaggever namelijk zijn
            berekening uitvoeren. Indien geen stavingsstukken worden afgeleverd,
            dient de EPB-verslaggever zijn berekening uit te voeren op basis van
            waarden bij ontstentenis. Dit zijn meestal ongunstige waarden, wat
            helaas resulteert in een minder goed of zelfs slecht resultaat. Via
            onderstaande lijst kunnen jullie de nodige stavingsstukken opladen.
            Wij kijken het na en verwerken de informatie.
          </p>
        </Grid>
      </Grid>
      <div className = {`width-auto ${screenWidth > 600 ? 'height-75-p overflow-y-auto' : 'height-auto'}`}>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid
            container
            className="epb-service-Category-heading "
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
              {screenWidth > 600 && (
                <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                <img
                  alt = 'info'
                  className="epb-service-Category-heading-image-size"
                  src={require('../../../../assets/svg/icon/info.svg')}
                  onMouseOver = {() => {setInfoHoverNumber(1)}}
                  onMouseLeave = {() => {setInfoHoverNumber(0)}}
                />
                { infoHoverNumber === 1 && (
                  <div className = 'flex-row fade-in-right'>
                    <img
                      src={require('../../../../assets/svg/icon/corner.png')}
                      alt="corner"
                      className="absolute step-four-info-arrow"
                    />
                    <div className = 'step-four-info-modal' style = {{alignSelf: 'flex-start'}}>
                        <div className = 'step-four-info-highlight-box width-auto'>
                          <ul>
                            <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>
                              Factuur met vermelding:
                              <ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Merk, tyrepree en dikte</li>
                              </ul>
                            </li>
                            <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Gebruik erkende materialen, zie www.epbd.be</li>
                            <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Foto's bij de uitvoering van de werken waarop de isolatie duidelijk te zien (met een meetlat)</li>
                          </ul>
                        </div>
                        <p className = 'margin-top-10 font-color-black vietnam-regular font-size-14 line-height-15 width-auto'>Documenten met volgend onderwerp mogen hier opgeladen worden. Gelieven het onderwerp ook in de document naam te vermelden alvorens u deze oplaad:</p>
                        <ul>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Dakisolatie: plat dak</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Dakisolatie: hellend dak</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Vloerisolatie</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Tussenvloerisolatie</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Vloerisolatie: kelder</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Muurisolatie</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Muurisolatie: kelder</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Scheidingsmuurisolatie</li>
                        </ul>
                    </div>
                  </div>
                )}
              </Grid>
              )}
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Isolatiematerialen
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <FileDetails
              ref={fileDetailsRef[0]}
              filesArray={roofIsolationFiles}
              setFile={setRoofIsolationFiles}
              token={token}
              categoryId={1}
              projectServiceId={projectServiceId}
              epbFormId={epbFormId}
              disabled = { userRole === 'Extern'}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid
            container
            className="epb-service-Category-heading "
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
            {screenWidth > 600 && (    
              <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                <img
                  alt = 'info'
                  className="epb-service-Category-heading-image-size"
                  src={require('../../../../assets/svg/icon/info.svg')}
                  onMouseOver = {() => {setInfoHoverNumber(2)}}
                  onMouseLeave = {() => {setInfoHoverNumber(0)}}
                />
                { infoHoverNumber === 2 && (
                  <div className = ' flex-row fade-in-right'>
                    <img
                      src={require('../../../../assets/svg/icon/corner.png')}
                      alt="corner"
                      className="absolute step-four-info-arrow"
                    />
                    <div className = 'step-four-info-modal' style = {{alignSelf: 'flex-start'}}>
                      <div className = 'step-four-info-highlight-box width-auto'>
                        <ul>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Factuur met vermelding:
                            <ul>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Glas, Merk & type en G-waarden</li>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Profielen, Merk & type en UF waarde profielen</li>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Deuren, luiken en/of poorten (met paneel), Soort (hout, aluminium), merk & type en Opbouw paneel:dikte &soort</li>
                            </ul>
                            <ul className = "step-four-info-marker-disable">
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15 step-four-info-marker-disable'>=> Uw- waarde berekening per raam</li>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Uw- waarde berekening deuren, poorten, luiken</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <p className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Documenten met volgend onderwerp mogen hier opgeladen worden. Gelieven het onderwerp ook in de document naam te vermelden alvorens u deze oplaad:</p>
                      <ul>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Ramen</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Dakvensters/ koepels</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Deuren</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Poorten</li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Luiken, rolluiken, screens</li>
                      </ul>
                    </div>
                  </div>
                )}
              </Grid>
            )}
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Buitenschrijnwerk
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <FileDetails
              ref={fileDetailsRef[1]}
              filesArray={ventilationApplianceFiles}
              setFile={setVentilationApplianceFiles}
              token={token}
              categoryId={2}
              projectServiceId={projectServiceId}
              epbFormId={epbFormId}
              disabled = { userRole === 'Extern'}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid
            container
            className="epb-service-Category-heading "
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
              {screenWidth > 600 && (      
                <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                  <img
                    alt = 'info'
                    className="epb-service-Category-heading-image-size"
                    src={require('../../../../assets/svg/icon/info.svg')}
                    onMouseOver = {() => {setInfoHoverNumber(3)}}
                    onMouseLeave = {() => {setInfoHoverNumber(0)}}
                  />
                  { infoHoverNumber === 3 && (
                    <div className = ' flex-row fade-in-right'>
                      <img
                        src={require('../../../../assets/svg/icon/corner.png')}
                        alt="corner"
                        className="absolute step-four-info-arrow"
                      />
                      <div className = 'step-four-info-modal'>
                        <div className = 'step-four-info-highlight-box'>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15 padding-10'>Rapport van de luchtdichtheidsmeting inclusief conformiteitsattest van het keuringsorganisme.</li>
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Luchtdichtheidsmeting
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <FileDetails
              ref={fileDetailsRef[2]}
              filesArray={installationFiles}
              setFile={setInstallationFiles}
              token={token}
              categoryId={3}
              projectServiceId={projectServiceId}
              epbFormId={epbFormId}
              disabled = { userRole === 'Extern'}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid
            container
            className="epb-service-Category-heading "
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
              {screenWidth > 600 && (
                <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                  <img
                    alt = 'info'
                    className="epb-service-Category-heading-image-size"
                    src={require('../../../../assets/svg/icon/info.svg')}
                    onMouseOver = {() => {setInfoHoverNumber(4)}}
                    onMouseLeave = {() => {setInfoHoverNumber(0)}}
                  />
                  { infoHoverNumber === 4 && (
                    <div className = ' flex-row fade-in-right'>
                      <img
                        src={require('../../../../assets/svg/icon/corner.png')}
                        alt="corner"
                        className="absolute step-four-info-arrow"
                      />
                      <div className = 'step-four-info-modal'>
                        <p className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Documenten met volgend onderwerp mogen hier opgeladen worden. Gelieven het onderwerp ook in de document naam te vermelden alvorens u deze oplaad:</p>
                        <ul>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Ventilatiesysteem D:
                            <div className = 'step-four-info-highlight-box'>
                              <ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Facturatie met vermelding:</li>
                                  <ul>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Merk, type en dikte</li>
                                  </ul>
                              </ul>
                              <ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Doorstroomopeningen:</li>
                                <ul>
                                  <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Detailfoto met een meetlat van de spleten onder uw deuren</li>
                                  <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Overzichtsfoto van de gehele deur</li>
                                </ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Ondertekend meetrapport van de ventilatiedebieten (systeem D)</li>
                              </ul>
                            </div>
                          </li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Ventilatiesysteem C+ roosters:
                            <div className = 'step-four-info-highlight-box'>
                              <ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Facturatie met vermelding
                                  <ul>
                                      <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                                      <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Merk, type en dikte</li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Ventilatie
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <FileDetails
              ref={fileDetailsRef[3]}
              filesArray={heatingFiles}
              setFile={setHeatingFiles}
              token={token}
              categoryId={4}
              projectServiceId={projectServiceId}
              epbFormId={epbFormId}
              disabled = { userRole === 'Extern'}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid
            container
            className="epb-service-Category-heading "
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
              {screenWidth > 600 && (
                <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                  <img
                    alt = 'info'
                    className="epb-service-Category-heading-image-size"
                    src={require('../../../../assets/svg/icon/info.svg')}
                    onMouseOver = {() => {setInfoHoverNumber(5)}}
                    onMouseLeave = {() => {setInfoHoverNumber(0)}}
                  />
                  { infoHoverNumber === 5 && (
                    <div className = ' flex-row fade-in-right'>
                      <img
                        src={require('../../../../assets/svg/icon/corner.png')}
                        alt="corner"
                        className="absolute step-four-info-arrow"
                      />
                      <div className = 'step-four-info-modal' >
                        <p className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Documenten met volgend onderwerp mogen hier opgeladen worden. Gelieven het onderwerp ook in de document naam te vermelden alvorens u deze oplaad:</p>
                        <ul>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Verwarmingsketel:
                            <div className = 'step-four-info-highlight-box'>
                              <ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Facturatie met vermelding:
                                  <ul>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Merk en type ketel</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Randapparatuur</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Kamerthermostaat</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Thermostatische kranen</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Buitenvloer</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Boiler</li>
                                  </ul>
                                </li>
                                  <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Foto van de isolatie in de ruimte</li>
                                  <p className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Overzichtsfoto (kamer) en detailfoto (toestel)</p>
                              </ul>
                            </div>
                          </li>
                          <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Warmtepomp:
                            <div className = 'step-four-info-highlight-box'>
                              <ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Facturatie met vermelding:</li>
                                <ul>
                                  <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                                  <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Merk en type warmtepomp </li>
                                </ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Foto van de isolatie in de ruimte</li>
                                <p className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Overzichtsfoto (kamer) en detailfoto (toestel)</p>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'> Dimensioneringsberekening (indien warmtestudie is uitgevoerd)</li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Verwarming & sanitair warm water
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <FileDetails
              ref={fileDetailsRef[4]}
              filesArray={PD1Files}
              setFile={setPD1Files}
              token={token}
              categoryId={5}
              projectServiceId={projectServiceId}
              epbFormId={epbFormId}
              disabled = { userRole === 'Extern'}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid
            container
            className="epb-service-Category-heading "
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
              {screenWidth > 600 && (
                <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                  <img
                    alt = 'info'
                    className="epb-service-Category-heading-image-size"
                    src={require('../../../../assets/svg/icon/info.svg')}
                    onMouseOver = {() => {setInfoHoverNumber(6)}}
                    onMouseLeave = {() => {setInfoHoverNumber(0)}}
                  />
                  { infoHoverNumber === 6 && (
                    <div className = ' flex-row fade-in-right'>
                      <img
                        src={require('../../../../assets/svg/icon/corner.png')}
                        alt="corner"
                        className="absolute step-four-info-arrow"
                      />
                      <div className = 'step-four-info-modal' style = {{marginTop: -100}}>
                        <div className = 'step-four-info-highlight-box width-auto'>
                            <ul>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>
                                Factuur met vermelding:
                              </li>
                              <ul>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Merk en type panelen</li>
                                <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Aantal panelen</li>
                              </ul>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Foto van de isolatie op het dak</li>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Gegevens opbouw installatie</li>
                              <p className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Oriëntatie en helling van de panelen</p>
                            </ul>
                        </div>
                          <p className = 'margin-top-10 font-color-black vietnam-regular font-size-14 line-height-15 width-auto'>Documenten met volgend onderwerp mogen hier opgeladen worden. Gelieven het onderwerp ook in de document naam te vermelden alvorens u deze oplaad:</p>
                          <ul>
                            <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Zonnepanelen</li>
                            <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Zonneboiler</li>
                          </ul>
                        </div>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Hernieuwbare energie
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <FileDetails
              ref={fileDetailsRef[5]}
              filesArray={M1Files}
              setFile={setM1Files}
              token={token}
              categoryId={6}
              projectServiceId={projectServiceId}
              epbFormId={epbFormId}
              disabled = { userRole === 'Extern'}
            />
          </Grid>
          <Grid container>
            <div className="margin-top-10" />
          </Grid>
          <Grid
            container
            className="epb-service-Category-heading "
            alignItems="center"
          >
            <Grid container xs={1} sm={1} md={1} justify="center" alignItems="center">
              {screenWidth > 600 && (
                <Grid container justify="flex-end" xs={10} sm={10} md={10}>
                  <img
                    alt = 'info'
                    className="epb-service-Category-heading-image-size"
                    src={require('../../../../assets/svg/icon/info.svg')}
                    onMouseOver = {() => {setInfoHoverNumber(7)}}
                    onMouseLeave = {() => {setInfoHoverNumber(0)}}
                  />
                  { infoHoverNumber === 7 && (
                    <div className = ' flex-row fade-in-right'>
                      <img
                        src={require('../../../../assets/svg/icon/corner.png')}
                        alt="corner"
                        className="absolute step-four-info-arrow"
                      />
                      <div className = 'step-four-info-modal' style = {{alignSelf: 'flex-end'}}>
                        <div className = 'step-four-info-highlight-box width-auto' >
                            <ul>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>
                                Factuur met vermelding:
                                  <ul>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Werfadres of kadastraal nummer</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Merk en type</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Aantal armaturen</li>
                                    <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Lampen en schakelende systemen per ruimte</li>
                                  </ul>
                              </li>
                              <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Technische fiche  
                                <ul>
                                  <li className = 'font-color-black vietnam-regular font-size-14 line-height-15'>Fluxcodes</li>
                                </ul>
                              </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container xs={11} sm={11} md={11}>
              <Grid container>
                <p className="epb-service-Category-heading-label vietnam-regular">
                  Verlichting
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <FileDetails
              ref={fileDetailsRef[6]}
              filesArray={HD1Files}
              setFile={setHD1Files}
              token={token}
              categoryId={7}
              projectServiceId={projectServiceId}
              epbFormId={epbFormId}
              disabled = { userRole === 'Extern'}
            />
          </Grid>
          <Grid container>
        <div className="margin-top-10" />
      </Grid>
      </div>
      {
        showPopupState === true ?
            <Popup data={popupMessageState}/>
        : null
      }
    </Grid>
  )
}

const FileDetails = forwardRef((props,ref) => {
  const {
    filesArray,
    setFile,
    token,
    categoryId,
    epbFormId,
    disabled
  } = props
  const fileRef = useRef(null)
  const [stateFile, setStateFile] = useState({})
  const [formDataList,setFormDataList]=useState([])
  const [effectiveImplementationRef,setEffectiveImplementationRef] = useState([])
  const [ showPopupState, setShowPopupState ] = useState(false)
  const [ popupMessageState, setPopupMessageState ] = useState({})
  const [ fileUploadProgress, setFileUploadProgress ] = useState(0)

  useEffect(() => {
    if (filesArray !== undefined && filesArray.length > 0) {
      let tempArray = []
      Array(filesArray.length)
        .fill()
        .map((item) => {
          tempArray.push(createRef(0))
        })
      setEffectiveImplementationRef(tempArray)
    }
  }, [filesArray])

  const handleFileChange = (event) => {
    event.preventDefault()
    const files = event.target.files
    if (files !== null && files !== undefined) {
      if (files.length > 0) {
        if (files[0] !== null && files[0] !== undefined) {
          setStateFile(files[0])
        }
      }
    }
  }

  const handleUploadClick = (recordId) => {
    if(epbFormId>0){
      let formData = new FormData()
    formData.append('record_id', recordId)
    formData.append('docsInput', stateFile)
    formData.append('ebp_form_id', epbFormId)
    formData.append(
      'effective_implementation',
      effectiveImplementationRef.current.getValue(),
    )
    formData.append('ebp_supporting_pieces_category_id', categoryId)

    axios
      .post(
        process.env.REACT_APP_API_URL+`/ebp_form/epb_step4_store`,
        formData,
        {
          headers: { authorization: `bearer ${token}` },
          onUploadProgress: progress => {
            const { total, loaded } = progress;
            const totalSize = total/1000000;
            const totalLoaded = loaded/1000000;
            const percentage = (totalLoaded/totalSize) * 100;
            setFileUploadProgress(percentage.toFixed(2));
          },
        },
      )
      .then((response) => {
        setFileUploadProgress(0)
        const responseData = response.data

        if (responseData !== null && responseData !== undefined) {
          if (responseData.message === 'Success!') {
            let tempFile = stateFile
            tempFile[
              'effective_implementation'
            ] = formData.get('effective_implementation')
            console.log(formData.get('effective_implementation'))
            setFile([...filesArray, tempFile])
            setFormDataList([...formDataList, FormData])
            setStateFile({})
            let responseMessage = {
              isSuccess: true,
              heading: 'Succesrecord opgeslagen',
              message: '',
              popupState: setShowPopupState
            }
            setShowPopupState(true)
            setPopupMessageState(responseMessage)
          } else {
            let responseMessage = {
              isSuccess: false,
              heading: 'Record kan niet worden opgeslagen',
              message: '',
              popupState: setShowPopupState
            }
            setShowPopupState(true)
            setPopupMessageState(responseMessage)
            setStateFile(null)
          }
        } else {
          let responseMessage = {
            isSuccess: false,
            heading: 'Record kan niet worden opgeslagen',
            message: '',
            popupState: setShowPopupState
          }
          setShowPopupState(true)
          setPopupMessageState(responseMessage)
          setStateFile(null)
        }
      })
      .catch((error) => {
        let responseMessage = {
          isSuccess: false,
          heading: 'Record kan niet worden opgeslagen',
          message: '',
          popupState: setShowPopupState
        }
        setShowPopupState(true)
        setPopupMessageState(responseMessage)
        setStateFile(null)
      })
    }
    else{
      let responseMessage = {
        isSuccess: false,
        heading: "Het document kan nog niet worden opgeslagen. Gelieven eerst enkele 'Startgegevens' in te vullen.",
        message: '',
        popupState: setShowPopupState
      }
      setShowPopupState(true)
      setPopupMessageState(responseMessage)
    }
  }

  const deleteFile = (recordId,index) => {
    axios
      .post(
        process.env.REACT_APP_API_URL+`/ebp_form/epb_step4_remove_doc`,
        { record_id: recordId },
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        const responseData = response.data
        if (responseData !== null && responseData !== undefined) {
          if (responseData.message === 'Success!') {
            let responseMessage = {
              isSuccess: true,
              heading: 'Record succesvol verwijderd',
              message: '',
              popupState: setShowPopupState
            }
            let tempFilesArray=JSON.parse(JSON.stringify(filesArray))
            tempFilesArray.splice(index,1)
            setFile(tempFilesArray)
            setShowPopupState(true)
            setPopupMessageState(responseMessage)
            // setFile(null)
          }
        }
      })
      .catch((error) => {
        let responseMessage = {
          isSuccess: false,
          heading: 'Record kan niet worden verwijderd',
          message: '',
          popupState: setShowPopupState
        }  
        setShowPopupState(true)
        setPopupMessageState(responseMessage)   
      })
    // setFile(null)
  }

  useImperativeHandle(ref, () => ({
    getValue() {
      filesArray.map((item, index) => {
        item.effective_implementation=effectiveImplementationRef[index].current.getValue()
      })
      return filesArray
    }
  }))

  return (
    <Grid container>
      {filesArray !== undefined &&
        filesArray.length > 0 &&
        filesArray.map((item,index) => (
          <Grid container>
            <Grid container>
              <div className="margin-top-10" />
            </Grid>
            <Grid container xs={1} sm={1} md={1}></Grid>
            <Grid container xs={11} sm={11} md={11}>
              <Grid container xs={6} sm={6} md={1} justify="flex-start" alignItems="center" className='margin-top-10 margin-bottom-10'>
                <button
                  type="button"
                  className={'step-four-delete-image cursor-pointer '}
                  style = {{opacity: (disabled !== undefined && disabled) && 0.2}}
                  disabled = {disabled}
                  onClick={() => {
                    deleteFile(item.id,index)
                  }}
                >
                  <img
                    alt=""
                    src={require(`../../../../assets/svg/icon/trash.svg`)}
                    
                  />
                </button>
              </Grid>
              <Grid container xs={6} sm={6} md={2} lg={2} className='margin-top-10 margin-bottom-10'>
                <p className="step-four-header vietnam-regular">
                  Document naam
                </p>
                <p className="step-four-data vietnam-regular">
                  {item.name !== undefined && item.name !== null
                    ? item.name
                    : item.document_orignal_name !== null &&
                      item.document_orignal_name !== undefined
                    ? item.document_orignal_name
                    : '-'}
                </p>
              </Grid>
              <Grid container xs={6} sm={6} md={2} lg={2} className='margin-top-10 margin-bottom-10'>
                <p className="step-four-header  vietnam-regular">
                  Voorlopig ingegeven
                </p>
                <p className="step-four-data width-auto vietnam-regular">
                  {item.provisionally_entered === null ||
                  item.provisionally_entered === undefined
                    ? '-'
                    : item.provisionally_entered}
                </p>
              </Grid>
              <Grid container xs={6} sm={6} md={3} lg={2} className='margin-top-10 margin-bottom-10'>
                <p className="step-four-header vietnam-regular">
                  Effectieve uitvoering
                </p>
                <CustomInput
                  value={item.effective_implementation}
                  ref={effectiveImplementationRef[index]}
                  disabled = {disabled}
                />
              </Grid>
              <Grid container xs={6} sm={6} md={2} lg={1} className='margin-top-10 margin-bottom-10'>
                <p className="step-four-header vietnam-regular">Status</p>
                <p className="step-four-data vietnam-regular">
                  {item.status === null || item.status === undefined
                    ? '-'
                    : item.status}
                </p>
              </Grid>
              <Grid container xs={6} sm={6} md={2} lg={2} className='margin-top-10 margin-bottom-10'>
                <p className="step-four-header vietnam-regular">Opmerking</p>
                <p className="step-four-data vietnam-regular">
                  {item.note === null || item.note === undefined
                    ? '-'
                    : item.note}
                </p>
              </Grid>
            </Grid>
          </Grid>
        ))}
      <Grid container>
        <Grid container>
          <div className="margin-top-10" />
        </Grid>
        <Grid container xs={1} sm={1} md={1}>
          <input
            type="file"
            ref={fileRef}
            disabled = {(disabled !== undefined && disabled) && disabled}
            className="ventilation-service-file-input"
            onChange={(event) => handleFileChange(event)}
          />
          <Grid container xs={10} sm={10} md={10} justify="flex-end" alignItems="center">
            <button
              type="button"
              disabled = {(disabled !== undefined && disabled) && disabled}
              className={'step-four-add-image cursor-pointer center-content'}
              style = {{opacity: (disabled !== undefined && disabled) && 0.2}}
              onClick={() => fileRef.current.click()}
              // onClick={()=>{setFileDetailsState(true)}}
            >
              <img
                alt=""
                src={require('../../../../assets/svg/icon/Add.svg')}
                className="step-four-add-image-size"
              />
            </button>
          </Grid>
        </Grid>

        {stateFile!==null && stateFile.name !== undefined && stateFile.name !== null && fileUploadProgress === 0 && (
          <Grid container xs={11} sm={11} md={11}>
            <Grid container xs={6} sm={6} md={1} justify="flex-start" alignItems="center" className='margin-top-10 margin-bottom-10'> 
              <button
                type="button"
                className={'step-four-delete-image cursor-pointer'}
                disabled = {(disabled !== undefined && disabled) && disabled}
                onClick={() => {
                  handleUploadClick(filesArray.length)
                }}
                >              
                <img
                  alt=""
                  src={require(`../../../../assets/svg/icon/upload.svg`)}
                  
                />
              </button>
            </Grid>
            <Grid container xs={6} sm={6} md={2} className='margin-top-10 margin-bottom-10'>
              <p className="step-four-header vietnam-regular">Document naam</p>
              <p className="step-four-data vietnam-regular">{stateFile.name}</p>
            </Grid>
            <Grid container xs={6} sm={6} md={2} className='margin-top-10 margin-bottom-10'>
              <p className="step-four-header vietnam-regular">
                Voorlopig ingegeven
              </p>
              <p className="step-four-data vietnam-regular">-</p>
            </Grid>
            <Grid container xs={6} sm={6} md={2} className='margin-top-10 margin-bottom-10'>
              <p className="step-four-header vietnam-regular">
                Effectieve uitvoering
              </p>
              <CustomInput ref={effectiveImplementationRef} />
            </Grid>
            <Grid container xs={6} sm={6} md={1} className='margin-top-10 margin-bottom-10'>
              <p className="step-four-header vietnam-regular">File</p>
              <p className="step-four-data width-auto vietnam-regular">
                -
              </p>
            </Grid>
            <Grid container xs={6} sm={6} md={2} className='margin-top-10 margin-bottom-10'>
              <p className="step-four-header vietnam-regular">Opmerking</p>
              <p className="step-four-data width-auto vietnam-regular">
                -
              </p>
            </Grid>
          </Grid>
        )}
        {fileUploadProgress !== 0 && (
          <div className = 'flex-row vertical-center'>
            <p className = "main-font-semi-bold vietnam-bold font-size-12 font-color-sky-blue">Upload</p>
            <div className = 'slider'>
              <div className = 'slider-thumb' style = {{width: fileUploadProgress*2}} />
            </div>
            <p className = "main-font-semi-bold font-size-12 font-color-sky-blue">{fileUploadProgress}%</p>
          </div>
        )}
      </Grid>
      {
        showPopupState === true ?
            <Popup data={popupMessageState}/>
        : null
      }
    </Grid>
  )
})
